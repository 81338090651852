import React, {Fragment, useState,useRef,useEffect} from "react";
import {Modal as BootstrapModal} from 'bootstrap';
import { useStatePersist  } from 'use-state-persist';

export const CustomModalSegmentSelect =(props: any) => {
    const impactRef1:any = useRef();
    const {show,setShow,options,setValue,growerValue,label,closeModal,id,onOkay,clearCropSegmentData,SetClearCobSegmentData} = props;
    const [selected,setSelected]:any=useStatePersist('selectedCropSegment',[])
    const [storeStateSelected,setStoreStateSelected]:any=useStatePersist('storeStateSelectedCropSegment',[])
    const [initialValue,setInitialValue]:any=useStatePersist('initialValueCropSegment',[])
    const [cropSegmentQuery, setCropSegmentQuery] :any = useState(props?.options);
    const [cropSegmentSearchName, setCropSegmentSearchName] = useState('');
    let cropSegmentOptionsFiltered: any[] = []


    useEffect(() => {
        if ( show === true){
            setCropSegmentSearchName('')
            setCropSegmentQuery(options)
            const filterIfSelectedCropExist = selected.filter((c: any) => options.some((g: any) => g.cropCode === c.cropCode)) || [];
            const filterIfCropSegmentValueExist = growerValue.filter((c: any) => options.some((g: any) => g.cropCode === c.cropCode)) || [];
            setStoreStateSelected(filterIfSelectedCropExist.map((item:any) => ({
                ...item,
                selected: true
            })))
            setInitialValue(filterIfCropSegmentValueExist.map((item:any) => ({
                ...item,
                selected: true
            })))
        }

        return () => {
            if(clearCropSegmentData === true && show === true){
                setStoreStateSelected([])
                setInitialValue([])
                setSelected([])
            }
        };
    }, [show === true && options.length > 0 ]);


    useEffect(() => {
        document.addEventListener("click", handleClickOutside, false);
        return () => {
            document.removeEventListener("click", handleClickOutside, false);
        };
    }, [show == true && options.length > 0 ]);

    const handleClickOutside =  async (event:any) => {
        if (impactRef1.current.id && event.target.id && show === true){
            var myModalEl = document.getElementById(`${id}`)as HTMLElement
            var modal = BootstrapModal.getInstance(myModalEl)
            const here:any =await initialValue.filter((c: any) => options.some((g: any) => g.cropCode === c.cropCode)) || [];
            const here2:any = await initialValue.filter((c: any) => options.some((g: any) => g.cropCode === c.cropCode)) || [];
            if (clearCropSegmentData === false) {
                if (here.length > 0) {
                    setSelected(here);
                } else {
                    setSelected([]);
                }
                if (here.length > 0) {
                    setValue(here2);
                } else {
                    setValue([]);
                }
            }else{
                setSelected([]);
                setValue([]);
            }
            if (modal){
                setShow(false)
                modal.hide()
            }
        }



    };


    const selectedChanges =(data:any)=>{
        SetClearCobSegmentData(false)
        var checkIfDataExist :any = selected.filter((item:any, pos:any) => item.value === data?.value && item.selected === true)
        if (checkIfDataExist.length > 0){
            let dataFromClick:any = [data]
            var mergeValue :any = dataFromClick.concat(growerValue)
            var filterValue :any = mergeValue.filter((item:any, pos:any) => mergeValue.indexOf(item) === pos)

            var checkDuplicates :any = filterValue.filter((item:any, pos:any) => item.value.toString() !== checkIfDataExist?.[0]?.value.toString())
            var settingSelected = checkDuplicates.map((item:any) => ({
                ...item,
                selected:  true
            }));
            setSelected(settingSelected)
            setValue(checkDuplicates)
        }else{
            let dataFromClick:any = [data]
            var c :any = dataFromClick.concat(growerValue)
            var d :any = c.filter((item:any, pos:any) => c.indexOf(item) === pos)
            var settingSelected = d.map((item:any) => ({
                ...item,
                selected: true
            }));
            setSelected(settingSelected)
            setValue(d)

        }

    }

    const checkText =(items:any)=>{
        let value =false
        let checkedArray:any = []
        checkedArray = selected.length > 0 && selected.filter((item:any, pos:any) => item?.value == items &&  item?.selected === true)
        value = checkedArray.length > 0 ? true : false
        return value
    }


    const  onSearch=(event:any)=> {
        event.preventDefault();
        const  searchString =event.currentTarget.value.trim().toLowerCase();

        if (searchString.length > 0) {
            // We are searching. Filter the results.
            const optionsFiltered = options.filter((e:any) => e.label.toLowerCase().match(searchString));
            if(optionsFiltered.length > 0){
                cropSegmentOptionsFiltered = optionsFiltered
                setCropSegmentQuery(cropSegmentOptionsFiltered);

            }else{
                // if not found were sending empty
                cropSegmentOptionsFiltered = [];
                setCropSegmentQuery(cropSegmentOptionsFiltered);
            }
        }else{
            // if not found search key were reseting it
            cropSegmentOptionsFiltered= options
            setCropSegmentQuery(cropSegmentOptionsFiltered);
        }
    }


    return (

        <Fragment>
            <div className="modal overlay" id={id} tabIndex={2}   ref={impactRef1}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title"> {`Please Select ${label}`}</h5>
                            <button type="button" className="btn-close"  aria-label="Close"  onClick={(evt)=>{closeModal(id,setShow),setSelected(storeStateSelected), setValue(initialValue)}}></button>
                        </div>
                        <div className="modal-body">
                            <input    name="search"   placeholder={`Search`} onKeyUp={(evt)=>{
                                if (evt?.code !== "Enter"){
                                    onSearch(evt)
                                }
                            }}
                                      value={cropSegmentSearchName}

                                      onInput={(event) => {
                                          const element = event.currentTarget as HTMLInputElement;
                                          setCropSegmentSearchName(element.value)
                                      }}
                            />
                            {options.length > 0 ? (
                                <Fragment>
                                    {Array.isArray(cropSegmentQuery) && cropSegmentQuery.length > 0 && cropSegmentQuery.map((data:any,index:any) =>
                                        <li  key={data.value} onClick={()=>selectedChanges(data)} style={{ "listStyleType": "none"}} className="list-item">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" name={'cropSegmentCheck'}  id={index}
                                                       checked={checkText(data.value)} disabled/>
                                                <label className="form-check-label" htmlFor="cropSegmentCheck">
                                                    {data?.label}
                                                </label>
                                            </div>
                                        </li>
                                    )}
                                </Fragment>
                            ):(
                                <Fragment>
                                    <h3>No Data</h3>
                                </Fragment>
                            )}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={(evt)=>{closeModal(id,setShow),setSelected(storeStateSelected), setValue(initialValue)}}>Cancel</button>
                            <button type="button" className="btn btn-primary"  onClick={(evt)=>{onOkay(id,setShow),setInitialValue(growerValue.map((item:any) => ({
                                ...item,
                                selected:  true
                            }))), setValue(growerValue.map((item:any) => ({
                                ...item,
                                selected:  true
                            })))}}>Save changes</button>
                        </div>
                    </div>
                </div>
            </div>




        </Fragment>
    );
};

export default CustomModalSegmentSelect;
