import React from 'react';

// Import custom components
import * as dashboardSlice from '../slice';
import DashComponent from '../Dashboard';

import { useAppDispatch ,useAppSelector} from "../../../store/reduxHooks";
import {fetchNews} from "../slice";



class RejectWithValue<T, U> {
}

const DashBoardContainer = (props:any) => {
    const dispatch = useAppDispatch();

    const cropData = useAppSelector((state) => state?.cropReducer?.payload);
    const cropDataErrors = useAppSelector((state) => state?.cropReducer?.error);
    const cropDataLoading = useAppSelector((state) => state?.cropReducer?.loading);

    const countryData = useAppSelector((state) => state?.countriesReducer?.payload);
    const countryDataErrors = useAppSelector((state) => state?.countriesReducer?.error);
    const countryDataLoading = useAppSelector((state) => state?.countriesReducer?.loading);

    const areaData = useAppSelector((state) => state?.areasReducer?.payload);
    const areaDataErrors = useAppSelector((state) => state?.areasReducer?.error);
    const areaDataLoading = useAppSelector((state) => state?.areasReducer?.loading);


    const dealerData = useAppSelector((state) => state?.dealerReducer?.payload);
    const dealerDataErrors = useAppSelector((state) => state?.dealerReducer?.error);
    const dealerDataLoading = useAppSelector((state) => state?.dealerReducer?.loading);

    const assortmentData = useAppSelector((state) => state?.cropSubSegmentReducer?.payload);
    const assortmentErrors = useAppSelector((state) => state?.cropSubSegmentReducer?.error);
    const assortmentLoading = useAppSelector((state) => state?.cropSubSegmentReducer?.loading);


    const trialChartData = useAppSelector((state) => state?.trialReducer?.payload);
    const trialErrors = useAppSelector((state) => state?.trialReducer?.error);
    const trialLoading = useAppSelector((state) => state?.trialReducer?.loading);

    const trialPropertiesData = useAppSelector((state) => state?.trialPropertiesReducer?.payload);
    const news = useAppSelector((state) => state?.newsReducer?.payload);
    const growerData = useAppSelector((state) => state?.growerReducer?.payload);
    const growerObservationData = useAppSelector((state) => state?.growerFromObservationReducer?.payload);
    const cropObservationData = useAppSelector((state) => state?.cropSubFromObserverReducer?.payload);

    props = { ...props,news,cropObservationData,growerObservationData,growerData,trialPropertiesData,trialChartData, cropData, cropDataErrors, cropDataLoading,countryData,countryDataErrors,countryDataLoading,areaData,areaDataErrors,areaDataLoading,dealerData,assortmentData };


    // /**
    //  * get data for dropdown records.
    //  * @param {object} formData
    //  *
    //  */
    const getCropData = (formData:any) => {
        return dispatch(dashboardSlice.getCropData(formData));
    };

    // /**
    //  * get data for crops records.
    //  * @param {object} formData
    //  *
    //  */
    const fetchCountries = (formData:any) => {
        return  dispatch(dashboardSlice.fetchCountries(formData));
    };


    // /**
    //  * get data for areas records.
    //  * @param {object} formData
    //  *
    //  */
    const fetchAreas = (formData:any) => {
        return    dispatch(dashboardSlice.fetchAreas(formData));
    };

    // /**
    //  * get data for areas records.
    //  * @param {object} formData
    //  *
    //  */
    const fetchDealer = (formData:any) => {
        dispatch(dashboardSlice.fetchDealer(formData));
    };


    // /**
    //  * get data for assortment records.
    //  * @param {object} formData
    //  *
    //  */
    const fetchCropSubSegment = (formData:any) => {
        return  dispatch(dashboardSlice.fetchCropSubSegment(formData));
    };

    // /**
    //  * post data for trial records.
    //  * @param {object} formData
    //  *
    //  */
    const submitTrial = (formData:any) => {
        return dispatch(dashboardSlice.submitTrial(formData));
    };

    // /**
    //  * get trial properties for trial records.
    //  * @param {object} formData
    //  *
    //  */
    const fetchTrialProperties = (formData:any) => {
        return  dispatch(dashboardSlice.fetchTrialProperties(formData));
    };


    // /**
    //  * get grower properties for trial records.
    //  * @param {object} formData
    //  *
    //  */
    const fetchGrowerData = (formData:any) => {
        return   dispatch(dashboardSlice.fetchGrowerData(formData));
    };

    // /**
    //  * get grower Data from observation for trial records.
    //  * @param {object} formData
    //  *
    //  */
    const fetchGrowerDataFromObservation = (formData:any) => {
        return   dispatch(dashboardSlice.fetchGrowerDataFromObservation(formData));
    };


    // /**
    //  * get crop sub Data from observation for trial records.
    //  * @param {object} formData
    //  *
    //  */
    const fetchCropSubFromObservation = (formData:any) => {
        return   dispatch(dashboardSlice.fetchCropSubFromObservation(formData));
    };

    // /**
    //  * get news for trial records.
    //  * @param {object} formData
    //  *
    //  */
    const fetchNews = (formData:any) => {
        return  dispatch(dashboardSlice.fetchNews(formData));
    };


    return (
        <DashComponent
            getCropData={getCropData}
            fetchCountries={fetchCountries}
            fetchAreas={fetchAreas}
            fetchDealer={fetchDealer}
            fetchCropSubSegment={fetchCropSubSegment}
            submitTrial={submitTrial}
            fetchGrowerData={fetchGrowerData}
            fetchTrialProperties={fetchTrialProperties}
            fetchGrowerDataFromObservation={fetchGrowerDataFromObservation}
            fetchCropSubFromObservation={fetchCropSubFromObservation}
            fetchNews={fetchNews}
            {...props}
        />


    );
};
export default DashBoardContainer;
