import React, {Fragment} from "react";


const Footer = (props: any) => {
    return (

        <Fragment>
            <div className="footer mt-auto py-3 bg-light">
                <div className="container-md">
                    <h3 className="footer-brand d-inline">ENZA ZADEN</h3>
                    <div className="footer-navbar d-inline">
                        {/*<ul className="d-inline-flex justify-content-between align-items-center list-unstyled mb-0">*/}
                        {/*    <li><a href="/">Help</a></li>*/}
                        {/*    <li><a href="/">Privacy</a></li>*/}
                        {/*    <li><a href="/">Terms</a></li>*/}
                        {/*</ul>*/}
                    </div>

                </div>
            </div>
        </Fragment>
    );
};

export default Footer;
