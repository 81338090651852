import React from 'react';
// Import custom components
import AdminAddComponent from '../AdminAdd';

import { useAppDispatch ,useAppSelector} from "../../../store/reduxHooks";
import * as AddFormSlice from "../slice";





class RejectWithValue<T, U> {
}

const AdminAddContainer= (props:any) => {
    const dispatch = useAppDispatch();
    //formSettings
    const formSettings = useAppSelector((state) => state?.formSettingReducer?.payload);
    const formSettingsErrors = useAppSelector((state) => state?.formSettingReducer?.error);
    const formSettingsLoading = useAppSelector((state) => state?.formSettingReducer?.loading);
    
    // image data
    const formSettingImage = useAppSelector((state) => state?.formSettingImageReducer?.payload);
    const formSettingImageErrors = useAppSelector((state) => state?.formSettingImageReducer?.error);
    const formSettingImageLoading = useAppSelector((state) => state?.formSettingImageReducer?.loading);
    

    props = { ...props,formSettings,formSettingsErrors,formSettingsLoading, formSettingImage, formSettingImageErrors,formSettingImageLoading}

    const addFormSettings = (formData:any) => {
        return dispatch(AddFormSlice.addFormSettings(formData));
    };


    const fetchFormSettingsByID = (id:any) => {
        return dispatch(AddFormSlice.fetchFormSettingsByID(id));
    };

    //fetch Image by Id
    const fetchFormImageByID = (id:string) =>{
        return dispatch(AddFormSlice.fetchFormImageByID(id));
    }


    const cleanFormSettingData =()=>{
        return dispatch(AddFormSlice.cleanFormSettingData());
    }

  const cleanFormSettingImageByID =()=>{
        return dispatch(AddFormSlice.cleanFormSettingImageByID());
    }


    return (
        <AdminAddComponent
            addFormSettings={addFormSettings}
            fetchFormSettingsByID={fetchFormSettingsByID}
            fetchFormImageByID={fetchFormImageByID}
            cleanFormSettingImageByID={cleanFormSettingImageByID}
            cleanFormSettingData={cleanFormSettingData}
            {...props}
    />


);
};
export default AdminAddContainer;
