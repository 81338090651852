import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {v2Fetch, v2Fetch3001, v2Post, v3Delete, v3Fetch} from "../../util/httpUtil";

const initialState: any = {
    payload:[],
    error: undefined,
    loading: false,
};

export const fetchFormSettingsList = createAsyncThunk(
    'setting-list/fetch',
    (formData:any, { rejectWithValue }) => {
            return v3Fetch(`cms/tdmcms/tdm`, {})
            .then((response:any) => {
                if (response.status === 200) {
                    return Promise.resolve(response?.data);
                } else {
                    // TODO
                }
            })
            .catch((error) => {
                const errorThrown = JSON.parse(error)
                return rejectWithValue(errorThrown?.message)

            });
    }
);


export const deleteSettingsById = createAsyncThunk(
    'setting-list/delete',
    (id:any, { rejectWithValue }) => {
        return v3Delete(`cms/tdmcms/tdm/${id}`, {})
            .then((response:any) => {
                if (response.status === 200) {
                    return Promise.resolve(response?.data);
                } else {
                    // TODO
                }
            })
            .catch((error) => {
                const errorThrown = JSON.parse(error)
                return rejectWithValue(errorThrown?.message)

            });
    }
);


export const settingListSlice = createSlice({
    name: "setting-list",
    initialState,
    reducers: {
        cleanSettingListData(state) {
            state.loading = false;
            state.payload = [];
            state.error = {};
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchFormSettingsList.pending, (state, action) => {
            state.loading = true;
            state.error = {};
            state.payload = [];
        });


        builder.addCase(fetchFormSettingsList.fulfilled, (state, action) => {
            state.loading = false;
            state.payload = action.payload;
        });
        builder.addCase(fetchFormSettingsList.rejected, (state, action) => {
            state.loading = false;
            state.payload = [];
            state.error = action.payload;
        });
    },
});






// export const settingListSlice = createSlice({
//     name: "setting-list",
//     initialState,
//     reducers: {
//         cleanSettingListData(state) {
//             state.loading = false;
//             state.payload = [];
//             state.error = {};
//         },
//     },
//     extraReducers: (builder) => {
//         builder.addCase(fetchFormSettingsList.pending, (state, action) => {
//             state.loading = true;
//             state.error = {};
//             state.payload = [];
//         });
//
//
//         builder.addCase(fetchFormSettingsList.fulfilled, (state, action) => {
//             state.loading = false;
//             state.payload = action.payload;
//         });
//         builder.addCase(fetchFormSettingsList.rejected, (state, action) => {
//             state.loading = false;
//             state.payload = [];
//             state.error = action.payload;
//         });
//     },
// });







// export const {cleanTraitListData} = traitListSlice.actions;
export const {cleanSettingListData} = settingListSlice.actions;
// export const { cleanCropListData } = cropListSlice.actions;
