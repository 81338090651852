import React, { Suspense,useEffect } from 'react';
import {Provider} from "react-redux";
import {BrowserRouter} from  "react-router-dom";

import {store} from "./store/store";
import {ErrorBoundary} from "./hoc/ErrorBoundary";
import Routes from "./routes";



function App() {

  return (
      <Suspense fallback={<div className="loader-container">Error! Please refresh the page</div>}>
      <BrowserRouter forceRefresh={true} basename={process.env.PUBLIC_URL} >
       <ErrorBoundary>
       <Provider store={store}>
        <Routes />
      </Provider>
       </ErrorBoundary>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
