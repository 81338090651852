import { LocalStorageKeys } from "../enum/enum";

export const getUserData = (): string | null => {
  return localStorage.getItem(LocalStorageKeys.USER);
};

export const clearLocalStorage = () => {
  localStorage.removeItem(LocalStorageKeys.USER);
};

/**
 * Clear records from the storage using the key
 *
 * @param {string} key
 */
export let clearDynamicLocalStorage = (key:any) => localStorage.removeItem(key);


export const addAuthToLocalStorage = (user: string) => {
  localStorage.setItem(LocalStorageKeys.USER, user);
};


export let getLocalStorage = (key : string) => {
  const data = localStorage.getItem(key);
  try {
    return JSON.parse(<string>data); // converts a JSON string into a Javascript Object
  } catch (e) {
    return data;
  }
};


/**
 * Store string record in the storage
 *
 * @param {string} key
 * @param {string | array | object} value
 */
export let setLocalStorage = (key:any, value:any) => {
  if (value && typeof(value) === 'string') {
    localStorage.setItem(key, value)
  } else {
    localStorage.setItem(key, JSON.stringify(value)); // convert arrays or objects into strings
  }
};
