import React from 'react';
import {Switch,Route} from 'react-router-dom'
import Dashboard from '../pages/Dashboard/container/DashboardContainer';
import ElearningContainer from "../pages/E-learning/container/ElearningContainer";
import BulkInsertContainer from "../pages/Bulk-Insert/container/BulkInsertContainer";
import AdminContainer from "../pages/Admin/container/AdminContainer";
import AdminAddContainer from "../pages/Admin-Add-Form/container/AdminAddContainer";
import NotFound from "../pages/NotFound/NotFound";

import config from  "../config/config";

const Home = (props:any) => {
  return(
      <div  className='my-5 pt-3'>
        <Switch>
          <Route exact   path='/' component={Dashboard}/>
            <Route exact   path={`/moreInformation`}  component={ElearningContainer}/>
            <Route  exact  path='/dataimport' component={BulkInsertContainer}/>
            <Route  exact  path='/Admin' component={AdminContainer}/>
            <Route  exact  path='/Admin/edit/:id' component={AdminAddContainer}/>
            <Route exact   path='/Admin/add' component={AdminAddContainer}/>
            <Route component={NotFound} />
        </Switch>
      </div>
  )
}

export default Home;
