import {Fragment} from "react";
import { ConfirmDelete } from "../assets/svgs";


export const DeleteModal = (props: any) => {
    const {
        setShow,
        onSuccess,
        closeModal,
        id,
        confirmLabel,
    } = props;

    return (
        <Fragment>
            <div className={"modal overlay modal-delete"} id={id} >
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btn-close" aria-label="Close" onClick={(evt) => {
                                closeModal(id, setShow)
                            }}></button>
                        </div>
                        <div className="modal-body">
                            <Fragment>
                                <div className="d-flex align-items-center justify-content-center pb-2">
                                    <ConfirmDelete/>
                                </div>
                            </Fragment>
                            <Fragment>
                                <div className="pb-2 d-flex justify-content-center align-items-center ">
                                    <h3 className="fs-4 px-2">{`${confirmLabel}`}</h3>
                                </div>
                            </Fragment>
                            
                            <Fragment>
                                <div className="d-flex justify-content-end">
                                    <button type="button" className="btn btn-cancel_custom" onClick={(evt) => {
                                        closeModal(id, setShow)
                                    }}>
                                        {`No`}
                                    </button>
                                    <button type="button" className="btn btn-danger" onClick={(evt) => {
                                        onSuccess(id, setShow)
                                    }}>
                                        {`Yes`}
                                    </button>
                                </div>
                            </Fragment>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default DeleteModal;
