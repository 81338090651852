import React, {Fragment, useState, useRef, useEffect} from "react";
import {Modal as BootstrapModal} from "bootstrap";
import { useStatePersist  } from 'use-state-persist';

export const CustomModalSubSegmentSelect = (props: any) => {
    const impactRef2: any = useRef();
    const {
        show,
        setShow,
        options,
        setValue,
        SetCallApi,
        className,
        growerValue,
        label,
        closeModal,
        id,
        onOkay,
        SetClearSubCropSegmentData,
        clearCropSubSegmentData
    } = props;
    const [selected, setSelected]: any = useStatePersist('selectedCropSubSegment',[])
    const [storeStateSelected, setStoreStateSelected]: any =  useStatePersist('storeStateSelectedCropSubSegment',[])
    const [initialValueCropSubSegment, setInitialValueCropSubSegment]: any=  useStatePersist('initialValueCropSubSegment',[])
    const [cropSubSegmentQuery, setCropSubSegmentQuery]: any = useState(props?.options);
    const [cropSubSegmentSearchName, setCropSubSegmentSearchName] = useState('');
    let cropSubSegmentOptionsFiltered: any[] = []


    useEffect(() => {
        if ( show === true){
            setCropSubSegmentSearchName('')
            setCropSubSegmentQuery(options)
            const filterIfSelectedCropExist = selected.filter((c: any) => options.some((g: any) => g.cropCode === c.cropCode)) || [];
            const filterIfCropSegmentValueExist = growerValue.filter((c: any) => options.some((g: any) => g.cropCode === c.cropCode)) || [];
            setStoreStateSelected(filterIfSelectedCropExist.map((item:any) => ({
                ...item,
                selected: true
            })))
            setInitialValueCropSubSegment(filterIfCropSegmentValueExist.map((item:any) => ({
                ...item,
                selected: true
            })))
        }

        return () => {
            if(clearCropSubSegmentData === true && show === true){
                setStoreStateSelected([])
                setInitialValueCropSubSegment([])
                setSelected([])
            }
        };
    }, [show === true && options.length > 0 ]);



    useEffect(() => {
        document.addEventListener("click", handleClickOutside, false);
        return () => {
            document.removeEventListener("click", handleClickOutside, false);
        };
    }, [show == true && options.length > 0 ]);

    const handleClickOutside =  async (event:any) => {
        if (impactRef2.current.id && event.target.id && show === true){
            var myModalEl = document.getElementById(`${id}`)as HTMLElement
            var modal = BootstrapModal.getInstance(myModalEl)
            const here:any =await initialValueCropSubSegment.filter((c: any) => options.some((g: any) => g.cropCode === c.cropCode)) || [];
            const here2:any = await initialValueCropSubSegment.filter((c: any) => options.some((g: any) => g.cropCode === c.cropCode)) || [];
            if (clearCropSubSegmentData === false) {
                if (here.length > 0) {
                    setSelected(here);
                } else {
                    setSelected([]);
                }
                if (here.length > 0) {
                    setValue(here2);
                } else {
                    setValue([]);
                }
            }else{
                setSelected([]);
                setValue([]);
            }
            if (modal){
                setShow(false)
                modal.hide()
            }
        }



    };

    const selectedChanges = (data: any) => {
        // SetClearSubCropSegmentData(false)
        var checkIfDataExist: any = selected.filter((item: any, pos: any) => item.value === data?.value && item.selected === true)
        if (checkIfDataExist.length > 0) {
            let dataFromClick: any = [data]
            var mergeValue: any = dataFromClick.concat(growerValue)
            var filterValue: any = mergeValue.filter((item: any, pos: any) => mergeValue.indexOf(item) === pos)

            var checkDuplicates: any = filterValue.filter((item: any, pos: any) => item.value.toString() !== checkIfDataExist?.[0]?.value.toString())
            var settingSelected = checkDuplicates.map((item: any) => ({
                ...item,
                selected: true
            }));
            setSelected(settingSelected)
            setValue(checkDuplicates)
            // SetCallApi(true);
        } else {
            let dataFromClick: any = [data]
            var c: any = dataFromClick.concat(growerValue)
            var d: any = c.filter((item: any, pos: any) => c.indexOf(item) === pos)
            var settingSelected = d.map((item: any) => ({
                ...item,
                selected: true
            }));
            setSelected(settingSelected)
            setValue(d)
            // SetCallApi(true);
        }

    }

    const checkText = (items: any) => {
        let value = false
        let checkedArray: any = []
        checkedArray = selected.length > 0 && selected.filter((item: any, pos: any) => item?.value == items && item?.selected === true)
        value = checkedArray.length > 0 ? true : false
        return value
    }

    const onSearch = (event: any) => {
        event.preventDefault();
        const searchString = event.currentTarget.value.trim().toLowerCase();

        if (searchString.length > 0) {
            // We are searching. Filter the results.
            const optionsFiltered = options.filter((e: any) => e.label.toLowerCase().match(searchString));
            if (optionsFiltered.length > 0) {
                cropSubSegmentOptionsFiltered = optionsFiltered
                setCropSubSegmentQuery(cropSubSegmentOptionsFiltered);

            } else {
                // if not found were sending empty
                cropSubSegmentOptionsFiltered = [];
                setCropSubSegmentQuery(cropSubSegmentOptionsFiltered);
            }
        } else {
            // if not found search key were reseting it
            cropSubSegmentOptionsFiltered = options
            setCropSubSegmentQuery(cropSubSegmentOptionsFiltered);
        }
    }


    return (

        <Fragment>
            <div className="modal overlay" id={id} tabIndex={3} ref={impactRef2}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title"> {`Please Select ${label}`}</h5>
                            <button type="button" className="btn-close" aria-label="Close" onClick={(evt) => {
                                closeModal(id, setShow), setSelected(storeStateSelected), setValue(initialValueCropSubSegment)
                            }}></button>
                        </div>
                        <div className="modal-body">
                            {cropSubSegmentQuery.length > 0 && (
                                <input name="search" placeholder={`Search`} onKeyUp={(evt) => {
                                    if (evt?.code !== "Enter") {
                                        onSearch(evt)
                                    }
                                }}
                                       value={cropSubSegmentSearchName}
                                       onInput={(event) => {
                                           const element = event.currentTarget as HTMLInputElement;
                                           setCropSubSegmentSearchName(element.value)
                                       }}
                                />
                            )}
                            {cropSubSegmentQuery.length > 0 ? (
                                <Fragment>
                                    {Array.isArray(cropSubSegmentQuery) && cropSubSegmentQuery.length > 0 && cropSubSegmentQuery.map((data: any, index: any) =>
                                        <li  key={data.value} onClick={() => selectedChanges(data)}
                                             style={{"listStyleType": "none"}} className="list-item">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" id={index}
                                                       checked={checkText(data.value)} disabled/>
                                                <label className="form-check-label" htmlFor="flexCheckCheckedDisabled">
                                                    {data?.label}
                                                </label>
                                            </div>
                                        </li>
                                    )}
                                </Fragment>) : (
                                <Fragment>
                                    <h3>No Data</h3>
                                </Fragment>

                            )}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={(evt) => {
                                closeModal(id, setShow), setSelected(storeStateSelected), setValue(initialValueCropSubSegment)
                            }}>Cancel
                            </button>
                            <button type="button" className="btn btn-primary"  onClick={(evt)=>{onOkay(id,setShow),setInitialValueCropSubSegment(growerValue.map((item:any) => ({
                                ...item,
                                selected:  true
                            }))), setValue(growerValue.map((item:any) => ({
                                ...item,
                                selected:  true
                            })))}}>Save changes</button>
                        </div>
                    </div>
                </div>
            </div>


        </Fragment>
    );
};

export default CustomModalSubSegmentSelect;
