// @ts-ignore
import {httpBase} from "./httpBaseUtil";

export function store(endpoint :any, data : any) {

    return httpBase(false,false,false)
        .post(`${endpoint}`, data);
}

export function v2Fetch(endpoint :any, data : any) {

    return httpBase(true,false,false,false)
        .get(`${endpoint}`, data);
}

export function sharePointFetch(endpoint :any, data : any) {
    return httpBase(true,true,false,false)
        .get(`${endpoint}`, data);
}

export function v2Fetch3001(endpoint :any, data : any) {
    return httpBase(false,false,false,false)
        .get(`${endpoint}`, data);
}

export function v2Post(endpoint :any, data : any) {

    return httpBase(true,false,false,false)
        .post(`${endpoint}`, data);
}
export function v3Post(endpoint :any, data : any) {

    return httpBase(false,false,true,false)
        .post(`${endpoint}`, data);
}


export function v3Update(endpoint :any, data : any) {

    return httpBase(false,false,true,false)
        .put(`${endpoint}`, data);
}


export function v3Fetch(endpoint :any, data : any) {
    return httpBase(false,false,true,false)
        .get(`${endpoint}`, data);
}


export function v3Delete(endpoint :any, data : any) {
    return httpBase(false,false,true,false)
        .delete(`${endpoint}`, data);
}


export function v3FetchOctet(endpoint :any, data : any) {
    return httpBase(false,false,true,true)
        .get(`${endpoint}`, data);
}
