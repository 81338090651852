import React, { Fragment } from 'react';
import { Route, Link, useLocation } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { USER_KEY, ACCESS_KEY, USER_ROLES } from '../constants';
import { CheckRoles } from '../util/commonUtils';
import { clearDynamicLocalStorage } from '../util/localStorage.util';
import avatar from '../public/assets/images/avatar.png';
import vitalisImage from '../assets/images/vitalis.png';
import Logo from '../assets/images/logo.png';
import config from '../config/config';
import {
  MenuIcon,
  SettingIcon,
  LogOutIcon,
  CustomerService,
  EmailIcon,
  NotificationIcon
} from '../assets/svgs';

const Header1 = (props: any) => {
  const { graphData, isOk, image } = props;
  const { instance, accounts } = useMsal();
  const location = useLocation();

  const handleLogout = (logoutType: any) => {
    if (logoutType === 'redirect') {
      clearDynamicLocalStorage(USER_KEY);
      clearDynamicLocalStorage(ACCESS_KEY);
      clearDynamicLocalStorage(USER_ROLES);
      localStorage.clear();
      instance.logoutRedirect({
        postLogoutRedirectUri: `${config.REDIRECT_URL}`
      });
    }
  };

  return (
    <Fragment>
      <nav
        className="navbar navbar-expand-md navbar-default mb-5"
        aria-label="Second navbar example">
        <div className="container-fluid px-4 bd-gutter flex-wrap flex-lg-nowrap">
          <div className="navbar-brand primary">
            <img
              src={Logo}
              alt="logo"
              style={{
                height: '70px'
                //  ,background: '#fff'
              }}
            />{' '}
          </div>
          <div className="navbar-brand secondary">
            <img src={vitalisImage} alt="logo" style={{ height: '27px' }} />
          </div>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarsExample02"
            aria-controls="navbarsExample02"
            aria-expanded="false"
            aria-label="Toggle navigation">
            <MenuIcon />
          </button>

          <div className="navbar-collapse collapse" id="navbarsExample02">
            <ul className="navbar-nav me-auto mb-2 mb-md-0">
              <li className="nav-item">
                <a
                  href={config.BaseName}
                  className={location.pathname === '/' ? 'nav-link active' : 'nav-link'}
                  aria-current="page"
                  style={{ fontWeight: '500' }}>
                  Homepage
                </a>
              </li>
              {/*<li className="nav-item">*/}
              {/*    <Link to={`${config.ApplicationUrl}`} className={location.pathname === "/Application" ? "nav-link active" : "nav-link"} >Applications</Link>*/}
              {/*</li>*/}
              <li className="nav-item">
                <Link
                  to={`/moreInformation`}
                  className={
                    location.pathname === '/moreInformation' ? 'nav-link active' : 'nav-link'
                  }
                  style={{ fontWeight: '500' }}>
                  More Information
                </Link>
              </li>
            </ul>
          </div>

          <ul className="d-flex justify-content-between align-items-center list-unstyled float-end mb-0">
            <li>
              <a className="px-3" href={`${config.helpDeskUrl}`}>
                <CustomerService />
              </a>
            </li>
            {/*<li>*/}
            {/*    <a className="px-3" href="/">*/}
            {/*        <EmailIcon/>*/}
            {/*    </a>*/}
            {/*</li>*/}
            {/*<li>*/}
            {/*    <a className="px-3" href="/">*/}
            {/*        <NotificationIcon/>*/}
            {/*    </a>*/}
            {/*</li>*/}
            {/*<li>*/}
            {/*    <a className="px-3" href="/">*/}
            {/*        <SettingIcon/>*/}
            {/*    </a>*/}
            {/*</li>*/}
            <li>
              <button className="btn btn-link" onClick={() => handleLogout('redirect')}>
                <LogOutIcon />
              </button>
            </li>
            <li className="d-flex align-items-center">
              {/*<a className="px-3 nav-user" href="/">*/}
              <span className="d-xl-block pe-2">Welcome {graphData?.displayName} !</span>
              {isOk === true && image && (
                <div className="nav-user-img">
                  <img src={image} alt="test" />
                </div>
              )}
              {isOk === false && (
                <div className="nav-user-img">
                  <img src={avatar} alt="avatar" />
                </div>
              )}
              {CheckRoles(`${config.AdminRole}`) && (
                <ul className="dropdown-user">
                  <Link to={`/Admin`}>
                    <li>Admin Settings</li>
                  </Link>
                </ul>
              )}

              {/*</a>*/}
            </li>
          </ul>
        </div>
      </nav>
    </Fragment>
  );
};

export default Header1;
