import React, {Fragment, useEffect, useState,useCallback,useRef} from "react";
import {Modal as BootstrapModal} from 'bootstrap';
import { useStatePersist  } from 'use-state-persist';

export const CustomModalCropSelect =(props: any) => {
    const impactRef:any = useRef();
    const {show,setShow,options,SetCropsState,SetCallApi,callApi,growerValue,label,className,closeModal,id,onOkay} = props;
    const [storeStateSelected,setStoreStateSelected]:any=useStatePersist('storeStateSelectedCrop',[])
    const [selected,setSelected]:any=useStatePersist('selectedCrop',[])
    const [initalGrowerValue,setInitialGrowerValue]:any=useStatePersist('initialGrowerValueCrop',[])
    const [query, setQuery] :any = useState(props?.options);
    const [cropSearchName, setCropSearchName] = useState('');
    let cropOptionsFiltered: any[] = []

    useEffect(() => {
        setCropSearchName('')
        setQuery(options)
        setStoreStateSelected(selected)
        setInitialGrowerValue(growerValue)
        return () => {
            setStoreStateSelected(selected)
            setInitialGrowerValue(growerValue)
        };
    }, [show == true ]);





    useEffect(() => {
        document.addEventListener("click", handleClickOutside, false);
        return () => {
            document.removeEventListener("click", handleClickOutside, false);
        };
    }, [show == true]);

    const handleClickOutside =  async (event:any) => {
        if (impactRef.current.id && event.target.id  && show === true){
            var myModalEl = document.getElementById(`${id}`) as HTMLElement
            var modal = BootstrapModal.getInstance(myModalEl)
            const here:any = await storeStateSelected
            const here2:any = await initalGrowerValue
            if(here.length >0){
                setSelected(here);
            }else{
                setSelected([]);
            }
            if(here.length >0){
                SetCropsState(here2);
            }else{
                SetCropsState([]);
            }


            //   SetCropsState(here2);

            //   closeModal(id);
            // setSelected(storeStateSelected)
            // SetCropsState(initalGrowerValue)
            // }
            // setTimeout(function(){
            if (modal) {
                setShow(false)
                modal.hide()
            }
            // }, 2500);
            // if (modal){

            // }
        }



    };


    const selectedChanges =(data:any)=>{
        var checkIfDataExist :any = selected.filter((item:any, pos:any) => item.cropCode === data?.cropCode && item.selected === true)
        if (checkIfDataExist.length > 0){
            let dataFromClick:any = [data]
            var mergeValue :any = dataFromClick.concat(growerValue)
            var filterValue :any = mergeValue.filter((item:any, pos:any) => mergeValue.indexOf(item) === pos)

            var checkDuplicates :any = filterValue.filter((item:any, pos:any) => item.cropCode.toString() !== checkIfDataExist?.[0]?.cropCode.toString())
            var settingSelected = checkDuplicates.map((item:any) => ({
                ...item,
                selected:  true
            }));
            setSelected(settingSelected)
            SetCropsState(checkDuplicates)

        }else{
            let dataFromClick:any = [data]
            var c :any = dataFromClick.concat(growerValue)
            var d :any = c.filter((item:any, pos:any) => c.indexOf(item) === pos)
            var settingSelected = d.map((item:any) => ({
                ...item,
                selected: true
            }));
            setSelected(settingSelected)
            SetCropsState(d)
        }

    }

    const checkText =(items:any)=>{
        let value =false
        let checkedArray:any = []
        checkedArray = selected.length > 0 && selected.filter((item:any, pos:any) => item?.cropCode == items &&  item?.selected === true)
        value = checkedArray.length > 0 ? true : false
        return value
    }

    const  onSearch=(event:any)=> {
        event.preventDefault();
        const  searchString =event.currentTarget.value.trim().toLowerCase();

        if (searchString.length > 0) {
            // We are searching. Filter the results.
            const optionsFiltered = options.filter((e:any) => e.label.toLowerCase().match(searchString));
            if(optionsFiltered.length > 0){
                cropOptionsFiltered = optionsFiltered
                setQuery(cropOptionsFiltered);

            }else{
                // if not found were sending empty
                cropOptionsFiltered = [];
                setQuery(cropOptionsFiltered);
            }
        }else{
            // if not found search key were reseting it
            cropOptionsFiltered= options
            setQuery(cropOptionsFiltered);
        }
    }


    return (
        <div className="modal overlay" id={id}  ref={impactRef} >
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title"> {`Please Select ${label}`}</h5>
                        <button type="button" className="btn-close"  aria-label="Close"  onClick={(evt)=>{closeModal(id,setShow),setSelected(storeStateSelected), SetCropsState(initalGrowerValue)}}></button>
                    </div>
                    <div className="modal-body">
                        <input    name="search"   placeholder={`Search`} onKeyUp={(evt)=>{
                            if (evt?.code !== "Enter"){
                                onSearch(evt)
                            }
                        }}
                                  value={cropSearchName}

                                  onInput={(event) =>{
                                      const element = event.currentTarget as HTMLInputElement;
                                      setCropSearchName(element.value)}}
                        />
                        <Fragment>
                            {Array.isArray(query) && query.length > 0 && query?.map((data:any,index:any) =>
                                {
                                    return (
                                        <Fragment key={index}>
                                            <li  key={data.cropCode}  onClick={()=>selectedChanges(data)} style={{ "listStyleType": "none"}} className="list-item">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox"  name={'flexCheckCheckedDisabled'} id={index}
                                                           checked={checkText(data.cropCode)} disabled/>
                                                    <label className="form-check-label" htmlFor="flexCheckCheckedDisabled">
                                                        {data?.label}
                                                    </label>
                                                </div>
                                            </li>
                                        </Fragment>
                                    )}
                            )}
                        </Fragment>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={(evt)=>{closeModal(id,setShow),setSelected(storeStateSelected), SetCropsState(initalGrowerValue)}}>Cancel</button>
                        <button type="button" className="btn btn-primary"  onClick={(evt)=>{onOkay(id,setShow)}}>Save changes</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomModalCropSelect;
