export const DATE_FORMAT = 'YYYY/MM/DD';
export const USER_KEY ='user-key';
export const USER_ROLES ='user-roles';
export const ACCESS_KEY ='access-key';
export const GraphACCESS_KEY ='graph_access-key';
export const DATE_FORMAT_LIST = [
    {
        label: "dd-mm-yyyy",
        value: "d-m-yyyy",
    },
    {
        label: "mm/dd/yyyy",
        value: "m/d/yyyy",
    },

    {
        label: "dd/mm/yyyy",
        value: "d/m/yyyy",
    },
    {
        label: "yyyy/mm/dd",
        value: "yyyy/m/d",
    },
    {
        label: "dd.mm.yyyy",
        value: "d.m.yyyy",
    },
]

export const CSS_COLOR_NAMES = [
    "#261cba",
    "#af0d08",
    "#3c5705",
    "#3770ad",
    "#7b341f",
    "#110a04",
    "#f26f07",
    "#440c14",
    "#757575",
    "#79b6ac",
    "#e8d25f",
    "#df4412",
    "#c46672",
    "#5a57a5",
    "#84d474",
    "#a87c42",
    "#5D0F6A",
    "#e42215",
    "#f0c21d",
    "#380309",
    "#243424",
    "#ff1f66",
    "#4c57cf",
    "#831c15",
    "#b68877",
    "#1629f5",
    "#bc8f8f",
    "#DAA520",
    "#FF00FF",
    "#b22222",
    "#c0d44c",
    "#393144",
    "#98551e",
    "#243d4b",
    "#9c3a1a",
    "#ffff33",
    "#b62528",
    "#45304a",
    "#00553e",
    "#c91f37",

    // "Black",
    // "BlanchedAlmond",
    // "Blue",
    // "BlueViolet",
    // "Brown",
    // "BurlyWood",
    // "CadetBlue",
    // "Chartreuse",
    // "Chocolate",
    // "Coral",
    // "CornflowerBlue",
    // "Cornsilk",
    // "Crimson",
    // "Cyan",
    // "DarkBlue",
    // "DarkCyan",
    // "DarkGoldenRod",
    // "DarkGray",
    // "DarkGrey",
    // "DarkGreen",
    // "DarkKhaki",
    // "DarkMagenta",
    // "DarkOliveGreen",
    // "DarkOrange",
    // "DarkOrchid",
    // "DarkRed",
    // "DarkSalmon",
    // "DarkSeaGreen",
    // "DarkSlateBlue",
    // "DarkSlateGray",
    // "DarkSlateGrey",
    // "DarkTurquoise",
    // "DarkViolet",
    // "DeepPink",
    // "DeepSkyBlue",
    // "DimGray",
    // "DimGrey",
    // "DodgerBlue",
    // "FireBrick",
    // "ForestGreen",
    // "Fuchsia",
    // "Gainsboro",
    // "GhostWhite",
    // "Gold",
    // "GoldenRod",
    // "Gray",
    // "Grey",
    // "Green",
    // "GreenYellow",
    // "HoneyDew",
    // "HotPink",
    // "IndianRed",
    // "Indigo",
    // "Ivory",
    // "Khaki",
    // "Lavender",
    // "LavenderBlush",
    // "LawnGreen",
    // "LemonChiffon",
    // "Lime",
    // "LimeGreen",
    // "Linen",
    // "Magenta",
    // "Maroon",
    // "MediumAquaMarine",
    // "MediumBlue",
    // "MediumOrchid",
    // "MediumPurple",
    // "MediumSeaGreen",
    // "MediumSlateBlue",
    // "MediumSpringGreen",
    // "MediumTurquoise",
    // "MediumVioletRed",
    // "MidnightBlue",
    // "MintCream",
    // "MistyRose",
    // "Moccasin",
    // "NavajoWhite",
    // "Navy",
    // "OldLace",
    // "Olive",
    // "OliveDrab",
    // "Orange",
    // "OrangeRed",
    // "Orchid",
    // "PaleGoldenRod",
    // "PaleGreen",
    // "PaleTurquoise",
    // "PaleVioletRed",
    // "PapayaWhip",
    // "PeachPuff",
    // "Peru",
    // "Pink",
    // "Plum",
    // "PowderBlue",
    // "Purple",
    // "RebeccaPurple",
    // "Red",
    // "RosyBrown",
    // "RoyalBlue",
    // "SaddleBrown",
    // "Salmon",
    // "SandyBrown",
    // "SeaGreen",
    // "SeaShell",
    // "Sienna",
    // "Silver",
    // "SkyBlue",
    // "SlateBlue",
    // "SlateGray",
    // "SlateGrey",
    // "SpringGreen",
    // "SteelBlue",
    // "Violet",
    // "Yellow",
    // "YellowGreen",
];
