import { loginRequest } from '../authConfig';
import { useMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import { USER_ROLES } from '../constants';
import { getLocalStorage } from './localStorage.util';
// import {ACCESS_KEY, USER_KEY} from "../constants";

// import {
//     InteractionRequiredAuthError,
//     InteractionStatus,
// } from "@azure/msal-browser";
//

export const isEmpty = (obj: any) => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

export const isEmptyString = (str: any) => {
  if (str == '') {
    return true;
  } else {
    return false;
  }
};

export const filterByReference = (arr1: any, arr2: any) => {
  let res = [];
  res = arr1.filter((el: any) => {
    return arr2.find((element: any) => {
      return element.traitID === el.traitID;
    });
  });
  return res;
};

export const parseJwt = (token: any) => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};

export const refreshAccessToken = async () => {
  const { instance, accounts, logger, inProgress } = useMsal();

  const request = {
    ...loginRequest,
    account: accounts
  };
  instance
    .acquireTokenSilent(request)
    .then((response: any) => {
      console.log(response, 'insdieer');
    })
    .catch((error: any) => {
      console.log(error, 'error');
      if (error instanceof InteractionRequiredAuthError) {
        return instance
          .acquireTokenPopup(loginRequest)
          .then((resp: any) => {
            return resp.accessToken;
          })
          .catch((err: any) => {
            return null;
          });
      } else {
        console.error(error);
      }
    });
};

export const CheckRoles = (permission: string) => {
  const roles: Array<string> = getLocalStorage(USER_ROLES);
  if (roles?.length > 0) {
    let permissionGrant = roles?.includes(`${permission}`);
    return permissionGrant;
  } else {
    return false;
  }
};

export const getSecondPart = (str: string, seperateBy: string) => {
  return str.split(`${seperateBy}`)[1];
};
