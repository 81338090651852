import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useStatePersist } from 'use-state-persist';
import moment from 'moment';
// @ts-ignore
import Spreadsheet from 'react-spreadsheet';
import { ACCESS_KEY, DATE_FORMAT_LIST, USER_KEY } from '../../constants/index';
import { Modal as BootstrapModal } from 'bootstrap';
import { BulkTraitSelectionModal } from '../../components/bulkTraitSelectionModal';
import { BulkTrialSelectionModal } from '../../components/bulkTrialSelectionModal';
import { SortTableOrderModal } from '../../components/sortTableOrderModal';
import { LoaderModal } from '../../components/loader';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { loginRequest } from '../../authConfig';
import { isEmpty, isEmptyString } from '../../util/commonUtils';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useMsal } from '@azure/msal-react';
import {
  PlusIcon,
  CalendarIcon,
  ExcelSheetIcon,
  EditIcon,
  SearchIcon,
  InfoIcon
} from '../../assets/svgs';
import jspreadsheet, { getElement } from 'jspreadsheet-ce';
import 'jspreadsheet-ce/dist/jspreadsheet.css';
import { DatePicker, Input } from 'antd';

const BulkInsert = (props: any) => {
  const jRef: any = useRef(null);
  const { instance, accounts, logger, inProgress } = useMsal();
  const {
    cleanTraitListData,
    getCropList,
    getTraitList,
    getTrialList,
    cropListDataLoading,
    cropListData,
    trialListData,
    trailListDataLoading,
    getVarieties,
    varieties,
    varietyLoading,
    traitListDataLoading,
    traitListData,
    submitExcelData,
    excelSliceLoading,
    cleanTrialVarietiesData
  } = props;
  const [initialValueOfOrder, setInitialValueOfOrder]: any = useState([]);
  const [initialValueOfOrder1, setInitialValueOfOrder1]: any = useState([]);
  const [varietiesOrder, setVarietiesOrder]: any = useState([]);
  const [warning, setWarning] = useState(false);
  const [selectedTraits, setSelectedTraits]: any = useState([]);
  const [measurementType, setMeasurementType] = useState(false);
  const [order, setOrder] = useState([]);
  const [cropListDataBulk, setCropListDataBulk] = useState(cropListData);
  const [showBulkTrait, setBulkTraitShow] = useState(false);
  const [showVarietiesOrder, setShowVarietiesOrder] = useState(false);
  const [showLoaderModal, setShowLoaderModal] = useState(false);
  const [traitStateSelectedMain, setTraitStateSelectedMain] = useState([]);
  const [selectedTrials, setSelectedTrials]: any = useState({});
  const [selectedBulkCrops, setSelectedBulkCrops] = useState({});
  const [cropSelectionValue, setCropSelectionValue] = useState({});
  const [selectedDateFormat, setSelectedDateFormat] = useState('');
  const currentYear = moment().format('YYYY');
  const [selectedYear, setSelectedYear] = useState<any>(moment().startOf('year'));

  const [toggle, setToggle] = useState(false);
  const [dateWeekToggle, setDateWeekToggle] = useState(false);

  const [data, setData] = useState([]);
  const [orderOfTrait, setOrderOfTrait]: any = useState([]);
  const [redraw, setReDraw] = useState(false);
  const [reorder, setReOrder] = useState(false);
  const [trialMenu, setTrialMenu] = useState(0);
  const [prevTraits, setPrevTraits] = useState([]);
  const [dateValueValidation, setDateValueValidation] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [userTrialNameChecked, setUserTrialNameChecked] = useState(false);

  // console.log(
  //   'aaaaaaaaaaa',
  //   !dateValueValidation || !dateValueValidation.length || warning === true || dateWeekToggle
  //     ? isEmptyString(selectedYear)
  //     : isEmptyString(selectedDateFormat)
  // );
  // console.log(
  //   'bbbbb',
  //   !dateValueValidation || !(dateValueValidation.length > 0),
  //   warning === true,
  //   dateWeekToggle
  //     ? isEmptyString(selectedYear) || isEmptyString(selectedDateFormat)
  //     : isEmptyString(selectedDateFormat)
  // );

  // console.log(
  //   'isEmptyString(selectedYear)',
  //   isEmptyString(selectedYear),
  //   isEmptyString(selectedDateFormat),
  //   isEmptyString(selectedYear) || isEmptyString(selectedDateFormat)
  // );

  // console.log(
  //   'cccccccccc',
  //   !dateValueValidation ||
  //     !(dateValueValidation.length > 0) ||
  //     warning === true ||
  //     (dateWeekToggle
  //       ? isEmptyString(selectedYear) || isEmptyString(selectedDateFormat)
  //       : isEmptyString(selectedDateFormat))
  // );

  const onChange = (e: any) => {
    setSelectedYear(e);
    jRef?.current?.jexcel?.updateTable();
    jRef?.current?.jexcel?.onafterchanges();
  };

  const handleChange = (e: any) => {
    setSelectedDateFormat(e.target.value);
    jRef?.current?.jexcel?.updateTable();
    jRef?.current?.jexcel?.onafterchanges();
  };
  const handleToggleChange = (e: any) => {
    setToggle(e.target.checked);
    jRef?.current?.jexcel?.updateTable();
    jRef?.current?.jexcel?.onafterchanges();
  };
  const handleDateWeekToggleChange = (e: any) => {
    setDateValueValidation([]);
    setDateWeekToggle(e.target.checked);
    jRef?.current?.jexcel?.updateTable();
    jRef?.current?.jexcel?.onafterchanges();
  };

  const handleToggleChangeMeasurement = (e: any) => {
    setMeasurementType(e.target.checked);
    jRef?.current?.jexcel?.updateTable();
    jRef?.current?.jexcel?.onafterchanges();
  };

  const onEditionend = () =>
    // instance: any,
    // cell: any,
    // col: any,
    // row: any,
    // val: any,
    // label: any,
    // cellName: any
    {
      // cell.innerHTML = '';
    };

  const onAfterchanges = () =>
    // instance: any,
    // cell: any,
    // col: any,
    // row: any,
    // val: any,
    // label: any,
    // cellName: any
    {
      if (jRef?.current?.jexcel?.getSelectedRows(true)) {
        let tt: any = [];
        const dateTable: any = jRef?.current?.jexcel?.rows;
        for (let i = 0; i < dateTable.length; i++) {
          let currRow = jRef?.current?.jexcel?.getRowData(i);
          if (currRow) {
            if (currRow.filter((x: any) => x !== '').length <= 0) {
              continue;
            }
            //    if(currRow.length>1){
            //     let [, ...rest] = currRow;
            //     if((rest.filter((x:any)=>x!=='')).length<=0){
            //         tt.push(currRow[0]);
            //         continue;
            //     }
            //    }
            //    if(currRow.length>1 && currRow[0].toString()===''){
            //         continue;
            //    }

            tt.push(currRow[0]);
          }
        }
        if (tt.some((x: any) => x == '')) {
          setDateValueValidation((currentState) => {
            return [];
          });
        } else {
          setDateValueValidation((currentState) => {
            return tt;
          });
        }
      }
      // if (jRef?.current?.jexcel?.getColumnData(0)) {
      //     const dateTable:any = jRef?.current?.jexcel?.getColumnData(0)
      //     const newDate = dateTable.filter((item:any)=>{
      //         return item !== '';
      //     })
      //     console.log(newDate);
      //     setDateValueValidation((currentState) => {
      //         return newDate;
      //     });

      // }
      const objectValues = Object.values(jRef?.current?.jexcel?.getStyle(0));
      const CheckIfErrorExist = objectValues.filter((element: any) =>
        element.includes('text-align: center; background-color: rgb(217, 1, 1);')
      );
      // const v=  a.filter((data:any)=>{
      //     data.includes("text-align: center; background-color: rgb(217, 1, 1);")
      // });
      // const found = a.find((element:any) => element.match("text-align: center; background-color: rgb(217, 1, 1);"));
      if (CheckIfErrorExist.length > 0) {
        setWarning(true);
      } else {
        setWarning(false);
      }
    };

  useEffect(() => {
    if (varieties?.Varieties && varieties?.Varieties?.length > 0 && orderOfTrait.length > 0) {
      // if (jRef?.current?.jexcel) {
      jRef?.current?.jexcel?.destroy();
      // }

      //if varietyis reordered, use the variety ordered array if not the original variety array to use. 
      const toUseVarietyArray = varietiesOrder.length>0?varietiesOrder:varieties?.Varieties
      const DateColumn = [
        dateWeekToggle ? { title: 'Week', colspan: '1' } : { title: 'Date', colspan: '1' },

        ...toUseVarietyArray?.map((varietyItem: any) => ({
          ...varietyItem,
          title: `[F.Nr: ${varietyItem?.FieldNumber}] ${varietyItem?.VarietyName}` ,
          colspan: traitStateSelectedMain.length
        }))
      ];
      const getDate = jRef?.current?.jexcel?.getColumnData(0);
      console.log(varietiesOrder,orderOfTrait,initialValueOfOrder);

      const columnsDateAdded = [
        dateWeekToggle
          ? {
              title: '  ',
              type: 'number',
              colspan: '1',
              width: 200,
              columnPos: 0,
              listOfValues: false,
              dataType: 'week',
              values: getDate
            }
          : {
              title: '  ',
              type: 'text',
              colspan: '1',
              width: 200,
              columnPos: 0,
              listOfValues: false,
              dataType: 'date',
              values: getDate
          },

            
        ...orderOfTrait?.map((orderOfTraitItem: any) => ({
          ...orderOfTraitItem,
          type: 'text',
          title:
            toggle && orderOfTraitItem?.uoMCodeImperial !== null
              ? `${orderOfTraitItem?.traitName} (${orderOfTraitItem?.uoMCodeImperial})`
              : toggle === false && orderOfTraitItem?.uomCode !== null
              ? `${orderOfTraitItem?.traitName} (${orderOfTraitItem?.uomCode})`
              : `${orderOfTraitItem?.traitName}`,
          colspan: 1,
          width: '200'
        }))
      ];

      let uniqueColumnsDateAdded = [...new Set(columnsDateAdded)];

      //copy and paste for jspreadsheet table
      // var jexcel_contextmenu = document?.getElementsByClassName("jcontextmenu").item(0) as any;
      //     if (jexcel_contextmenu){
      //         jexcel_contextmenu.style.display = "none"
      //     }
      jspreadsheet(jRef.current, {
        allowDeleteColumn: false,
        //contextMenu: function() { return false; },
        data: [],
        columns: uniqueColumnsDateAdded.length > 0 ? uniqueColumnsDateAdded : [],
        columnSorting: false,
        nestedHeaders: DateColumn?.length > 0 ? [DateColumn] : [],
        oneditionend: onEditionend,
        onafterchanges: onAfterchanges,
        // oneditionend: function (instance: any, cell: any, col: any, row: any, val: any, label: any, cellName: any) {
        //     cell.innerHTML = '';
        // },
        onpaste: function (instance: any, cell: any) {},
        minDimensions: [columnsDateAdded.length, 10],
        updateTable: function (
          instance: any,
          cell: any,
          col: any,
          row: any,
          val: any,
          label: any,
          cellName: any
        ) {
          // setWarning(false)

          // console.log(col,'outsdie',orderOfTrait[col])
          // if (col > 0 && (col == orderOfTrait[col]?.columnPos)){
          // }

          // if (col !== 0){
          // setWarning(true)

          if (col == columnsDateAdded[col]?.columnPos && val) {
            if (columnsDateAdded[col]?.listOfValues === true && val) {
              const listOfValues = columnsDateAdded[col]?.values;
              if (listOfValues?.includes(`${val}`) === false) {
                // setWarning(true)
                cell.style.backgroundColor = '#d90101';
              } else {
                cell.style.backgroundColor = '#ffffff';
              }
            } else {
              if (columnsDateAdded[col]?.dataType.toLowerCase() === 'date' && val) {
                var e = document?.getElementById('selectDate') as any;
                var value = e?.value?.toUpperCase()?.trim();
                var text = e?.options[e?.selectedIndex]?.text;
                if (value) {
                  let filteredLabels: any = DATE_FORMAT_LIST.filter(
                    (e: any) => e?.value?.toUpperCase() === value
                  );
                  var dateFormatCheck = moment(`${val?.trim()}`, `${value}`, true).isValid();
                  var dateFormatCheckLabels = moment(
                    `${val?.trim()}`,
                    `${filteredLabels[0]?.label}`,
                    true
                  ).isValid();

                  if (dateFormatCheck || dateFormatCheckLabels) {
                    // let todaysDate = moment()?.format("YYYY-MM-DD");
                    const a = val?.trim();
                    let userInputDate = moment(
                      a,
                      `${filteredLabels[0]?.label?.toUpperCase()}`
                    )?.format(`MM/DD/YYYY`);
                    const value = moment(new Date(userInputDate), 'MM/DD/YYYY', true);
                    let isDateComparisonFuture = value.isSameOrAfter(
                      moment(new Date()).add(1, 'days'),
                      'day'
                    );
                    //console.log('isDateComparisonFuture', isDateComparisonFuture);
                    if (isDateComparisonFuture === false) {
                      cell.style.backgroundColor = '#ffffff';
                    } else {
                      cell.style.backgroundColor = '#d90101';
                    }
                  } else {
                    cell.style.backgroundColor = '#d90101';
                  }
                } else {
                  cell.style.backgroundColor = '#d90101';
                }
              }
              if (columnsDateAdded[col]?.dataType.toLowerCase() === 'week' && val) {
                var e = document?.getElementById('yearPicker') as any;
                const year = e?.value;
                if (!/^[0-9]*$/?.test(val)) {
                  cell.style.backgroundColor = '#d90101';
                } else if (
                  Number(year) < moment().year() &&
                  !(Number(val) >= 1 && Number(val) <= 53)
                ) {
                  cell.style.backgroundColor = '#d90101';
                } else if (
                  Number(year) === moment().year() &&
                  !(Number(val) >= 1 && Number(val) <= moment().week())
                ) {
                  cell.style.backgroundColor = '#d90101';
                } else {
                  cell.style.backgroundColor = '#ffffff';
                }
              }
              //handleCharacter
              if (columnsDateAdded[col]?.dataType.toLowerCase() === 'c' && val)
                if (
                  !(
                    Number(val.length) <=
                    Number(columnsDateAdded[col]?.displayFormat?.replace(/\D/g, ''))
                  )
                ) {
                  // if ( !/([A-Z-a-z])/.test(val)) {
                  //
                  //     cell.style.backgroundColor = '#f46e42';
                  // }
                  // else
                  // setWarning(true)
                  cell.style.backgroundColor = '#d90101';
                } else {
                  cell.style.backgroundColor = '#ffffff';
                }
            }
            //numberhandle
            if (columnsDateAdded[col]?.dataType.toLowerCase() === 'i' && val) {
              // const e = document.getElementById("switchMeasurement") as any;
              // const value2 = e?.value?.toUpperCase()?.trim();
              if (!/^[0-9]*$/?.test(val)) {
                // setWarning(true)
                cell.style.backgroundColor = '#d90101';
              } else if (
                !(
                  Number(val?.length) <=
                  Number(columnsDateAdded[col]?.displayFormat?.replace(/,/g, '')?.trim()?.length)
                )
              ) {
                // setWarning(true)
                cell.style.backgroundColor = '#d90101';
              } else {
                cell.style.backgroundColor = '#ffffff';
              }
            }
            //decimalhandle
            if (columnsDateAdded[col]?.dataType.toLowerCase() === 'a' && val) {
              // const e = document.getElementById("switchMeasurement") as any;
              const valueFromToggle = document?.getElementById('switchMeasurement') as any;

              const toggledOptions = valueFromToggle?.checked ? 'decimal' : 'comma';
              // if (!/^[0-9]*$/?.test(val) && val) {
              //     // setWarning(true)
              //     cell.style.backgroundColor = '#d90101';
              // }else
              if (val && /[a-zA-Z]/.test(val)) {
                cell.style.backgroundColor = '#d90101';
              } else if (val && /^\d+$/.test(val)) {
                cell.style.backgroundColor = '#fffff';
              } else if (val && toggledOptions === 'comma') {
                if (val?.lastIndexOf(',') !== -1) {
                  if (
                    Number(val?.slice(val?.lastIndexOf(',') + 1)?.length) <=
                    Number(columnsDateAdded[col]?.displayFormat?.split('.')?.[1]?.length)
                  ) {
                    cell.style.backgroundColor = '#ffffff';
                    if (
                      Number(
                        val?.slice(0, val?.lastIndexOf(','))?.replace(/[^0-9]/g, '')?.length
                      ) <=
                      Number(
                        columnsDateAdded[col]?.displayFormat
                          ?.split('.')?.[0]
                          ?.replace(/,/g, '')
                          ?.trim()?.length
                      )
                    ) {
                      cell.style.backgroundColor = '#ffffff';
                    } else {
                      cell.style.backgroundColor = '#d90101';
                    }
                  } else {
                    cell.style.backgroundColor = '#d90101';
                  }
                } else {
                  cell.style.backgroundColor = '#d90101';
                }
              } else if (val && toggledOptions === 'decimal') {
                if (val?.lastIndexOf('.') !== -1) {
                  if (
                    Number(val?.slice(val?.lastIndexOf('.') + 1)?.length) <=
                    Number(columnsDateAdded[col]?.displayFormat?.split('.')?.[1]?.length)
                  ) {
                    cell.style.backgroundColor = '#ffffff';
                    if (
                      Number(
                        val?.slice(0, val?.lastIndexOf('.'))?.replace(/[^0-9]/g, '')?.length
                      ) <=
                      Number(
                        columnsDateAdded[col]?.displayFormat
                          ?.split('.')?.[0]
                          ?.replace(/,/g, '')
                          ?.trim()?.length
                      )
                    ) {
                      cell.style.backgroundColor = '#ffffff';
                    } else {
                      cell.style.backgroundColor = '#d90101';
                    }
                  } else {
                    cell.style.backgroundColor = '#d90101';
                  }
                } else {
                  cell.style.backgroundColor = '#d90101';
                }
              }

              // else if (!(Number(val?.length) <= Number(columnsDateAdded[col]?.displayFormat?.split(".")?.[0]?.replace(/,/g, '')?.trim()?.length))) {
              //     // setWarning(true)
              //     cell.style.backgroundColor = '#d90101';
              // } else if ((measurementType === true) && val?.indexOf(".") === -1) {
              //     // setWarning(true)
              //     cell.style.backgroundColor = '#d90101';
              // } else if ((measurementType === false) && val?.indexOf(",") === -1) {
              //     // setWarning(true)
              //     cell.style.backgroundColor = '#d90101';
              // }

              // else {
              //     cell.style.backgroundColor = '#ffffff';
              // }
            }
          } else {
            cell.style.backgroundColor = '#ffffff';
          }
        }
      });
      if (redraw === true) {
        setReDraw(false);
      }
    }
  }, [redraw === true, dateWeekToggle]);

  useEffect(() => {
    if (
      varietiesOrder &&
      varietiesOrder?.length > 0 &&
      orderOfTrait.length > 0 &&
      initialValueOfOrder.length > 0 &&
      reorder === true
    ) {
      const getDate = jRef?.current?.jexcel?.getColumnData(0);
      const DateColumn = [
        dateWeekToggle ? { title: 'Week', colspan: '1' } : { title: 'Date', colspan: '1' },
        ...varietiesOrder?.map((varietyItem: any) => ({
          ...varietyItem,
          title: `[F.Nr: ${varietyItem?.FieldNumber}] ${varietyItem?.VarietyName}` ,
          colspan: traitStateSelectedMain.length
        }))
      ];

      const c = orderOfTrait?.map((orderOfTraitItem: any) => ({
        ...orderOfTraitItem,
        type: 'text',
        title:
          toggle && orderOfTraitItem?.uoMCodeImperial !== null
            ? `${orderOfTraitItem?.traitName} (${orderOfTraitItem?.uoMCodeImperial})`
            : toggle === false && orderOfTraitItem?.uomCode !== null
            ? `${orderOfTraitItem?.traitName} (${orderOfTraitItem?.uomCode})`
            : `${orderOfTraitItem?.traitName}`,
        colspan: 1,
        width: '100'
      }));

      let orderedSubHeader: any = [];
      initialValueOfOrder?.flat()?.forEach((orderOfTraitItem: any, index: any) => ({
        ...orderOfTraitItem?.traits?.forEach((here: any) => orderedSubHeader.push({ ...here }))
      }));
      const columnsDateAdded = [
        dateWeekToggle
          ? {
              title: '  ',
              type: 'number',
              colspan: '1',
              width: 200,
              columnPos: 0,
              listOfValues: false,
              dataType: 'week',
              values: getDate
            }
          : {
              title: '  ',
              type: 'text',
              colspan: '1',
              width: 200,
              columnPos: 0,
              listOfValues: false,
              dataType: 'date',
              values: getDate
            },
        ...orderedSubHeader?.map((orderedSubHeaderData: any) => ({
          ...orderedSubHeaderData,
          type: 'text',
          title:
            toggle && orderedSubHeaderData?.uoMCodeImperial !== null
              ? `${orderedSubHeaderData?.traitName} (${orderedSubHeaderData?.uoMCodeImperial})`
              : toggle === false && orderedSubHeaderData?.uomCode !== null
              ? `${orderedSubHeaderData?.traitName} (${orderedSubHeaderData?.uomCode})`
              : `${orderedSubHeaderData?.traitName}`,
          colspan: 1,
          width: 200
        }))
      ];
      const dataByColumn: any = columnsDateAdded
        ?.flat()
        ?.forEach((columnsDateAddedData: any, indexOfData: any) =>{
          jRef?.current?.jexcel?.setColumnData(indexOfData, columnsDateAddedData?.values??[])
        }
        );
      const previousData = jRef?.current?.jexcel.getData();
      setInitialValueOfOrder1(orderedSubHeader);
      SpreadInstatiate(columnsDateAdded, DateColumn, previousData);
    }
  }, [reorder === true, selectedYear]);

  const SpreadInstatiate = async (columnsDateAdded: any, DateColumn: any, previousData: any) => {
    jRef?.current?.jexcel.setData([]);

    // if (jRef?.current?.jexcel) {
    await jRef?.current?.jexcel?.destroy();
    // }

    const here = await columnsDateAdded;
    jspreadsheet(jRef?.current, {
      allowDeleteColumn: false,
      data: previousData,
      minDimensions: [columnsDateAdded.length, 5],
      columns: here?.length > 0 ? here : [],
      nestedHeaders: DateColumn?.length > 0 ? [DateColumn] : [],
      oneditionend: onAfterchanges,
      // oneditionend: function (instance: any, cell: any, col: any, row: any, val: any, label: any, cellName: any) {
      //     cell.innerHTML = '';
      // },
      onpaste: function (instance: any, cell: any) {},
      updateTable: function (
        instance: any,
        cell: any,
        col: any,
        row: any,
        val: any,
        label: any,
        cellName: any
      ) {
        if (columnsDateAdded[col] && val) {
          if (columnsDateAdded[col]?.listOfValues === true && val) {
            const listOfValues = columnsDateAdded[col]?.listOfValuesData;
            if (listOfValues?.includes(`${val}`) === false) {
              // setWarning(true)
              cell.style.backgroundColor = '#d90101';
            } else {
              cell.style.backgroundColor = '#ffffff';
            }
          } else {
            if (columnsDateAdded[col]?.dataType.toLowerCase() === 'date' && val) {
              var e = document?.getElementById('selectDate') as any;
              var value = e?.value?.toUpperCase()?.trim();
              var text = e?.options[e?.selectedIndex]?.text;
              if (value) {
                let filteredLabels: any = DATE_FORMAT_LIST.filter(
                  (e: any) => e?.value?.toUpperCase() === value
                );
                var dateFormatCheck = moment(`${val?.trim()}`, `${value}`, true).isValid();
                var dateFormatCheckLabels = moment(
                  `${val?.trim()}`,
                  `${filteredLabels[0]?.label}`,
                  true
                ).isValid();
                if (dateFormatCheck || dateFormatCheckLabels) {
                  const a = val?.trim();
                  let userInputDate = moment(
                    a,
                    `${filteredLabels[0]?.label?.toUpperCase()}`
                  )?.format(`MM/DD/YYYY`);
                  const value = moment(new Date(userInputDate), 'MM/DD/YYYY', true);
                  let isDateComparisonFuture = value.isSameOrAfter(
                    moment(new Date()).add(1, 'days'),
                    'day'
                  );
                  if (isDateComparisonFuture === false) {
                    cell.style.backgroundColor = '#ffffff';
                  } else {
                    cell.style.backgroundColor = '#d90101';
                  }
                } else {
                  cell.style.backgroundColor = '#d90101';
                }
              } else {
                cell.style.backgroundColor = '#d90101';
              }
            }
            if (columnsDateAdded[col]?.dataType.toLowerCase() === 'week' && val) {
              if (!/^[0-9]*$/?.test(val)) {
                cell.style.backgroundColor = '#d90101';
              } else if (!(Number(val) >= 1 && Number(val) <= moment().week())) {
                cell.style.backgroundColor = '#d90101';
              } else {
                cell.style.backgroundColor = '#ffffff';
              }
            }
            //handleCharacter
            if (columnsDateAdded[col]?.dataType.toLowerCase() === 'c' && val)
              if (
                !(
                  Number(val.length) <=
                  Number(columnsDateAdded[col]?.displayFormat?.replace(/\D/g, ''))
                )
              ) {
                //console.log('validations');
                // if ( !/([A-Z-a-z])/.test(val)) {
                //
                //     cell.style.backgroundColor = '#f46e42';
                // }
                // else
                // setWarning(true)
                cell.style.backgroundColor = '#d90101';
              } else {
                cell.style.backgroundColor = '#ffffff';
              }
          }
          //numberhandle
          if (columnsDateAdded[col]?.dataType.toLowerCase() === 'i' && val) {
            // const e = document?.getElementById("switchMeasurement") as any;
            // const value2 = e?.value?.toUpperCase()?.trim();
            if (!/^[0-9]*$/?.test(val)) {
              // setWarning(true)
              cell.style.backgroundColor = '#d90101';
            } else if (
              !(
                Number(val?.length) <=
                Number(columnsDateAdded[col]?.displayFormat.replace(/,/g, '')?.trim()?.length)
              )
            ) {
              // setWarning(true)
              cell.style.backgroundColor = '#d90101';
            } else {
              cell.style.backgroundColor = '#ffffff';
            }
          }
          //decimalhandle

          if (columnsDateAdded[col]?.dataType.toLowerCase() === 'a' && val) {
            // const e = document.getElementById("switchMeasurement") as any;
            const valueFromToggle = document?.getElementById('switchMeasurement') as any;

            const toggledOptions = valueFromToggle?.checked ? 'decimal' : 'comma';
            // if (!/^[0-9]*$/?.test(val) && val) {
            //     // setWarning(true)
            //     cell.style.backgroundColor = '#d90101';
            // }else

            if (val && /[a-zA-Z]/.test(val)) {
              cell.style.backgroundColor = '#d90101';
            } else if (val && /^\d+$/.test(val)) {
              cell.style.backgroundColor = '#fffff';
            } else if (val && toggledOptions === 'comma') {
              if (val?.lastIndexOf(',') !== -1) {
                if (
                  Number(val?.slice(val?.lastIndexOf(',') + 1)?.length) <=
                  Number(columnsDateAdded[col]?.displayFormat?.split('.')?.[1]?.length)
                ) {
                  cell.style.backgroundColor = '#ffffff';
                  if (
                    Number(val?.slice(0, val?.lastIndexOf(','))?.replace(/[^0-9]/g, '')?.length) <=
                    Number(
                      columnsDateAdded[col]?.displayFormat
                        ?.split('.')?.[0]
                        ?.replace(/,/g, '')
                        ?.trim()?.length
                    )
                  ) {
                    cell.style.backgroundColor = '#ffffff';
                  } else {
                    cell.style.backgroundColor = '#d90101';
                  }
                } else {
                  cell.style.backgroundColor = '#d90101';
                }
              } else {
                cell.style.backgroundColor = '#d90101';
              }
            } else if (val && toggledOptions === 'decimal') {
              if (val?.lastIndexOf('.') !== -1) {
                if (
                  Number(val?.slice(val?.lastIndexOf('.') + 1)?.length) <=
                  Number(columnsDateAdded[col]?.displayFormat?.split('.')?.[1]?.length)
                ) {
                  cell.style.backgroundColor = '#ffffff';
                  if (
                    Number(val?.slice(0, val?.lastIndexOf('.'))?.replace(/[^0-9]/g, '')?.length) <=
                    Number(
                      columnsDateAdded[col]?.displayFormat
                        ?.split('.')?.[0]
                        ?.replace(/,/g, '')
                        ?.trim()?.length
                    )
                  ) {
                    cell.style.backgroundColor = '#ffffff';
                  } else {
                    cell.style.backgroundColor = '#d90101';
                  }
                } else {
                  cell.style.backgroundColor = '#d90101';
                }
              } else {
                cell.style.backgroundColor = '#d90101';
              }
            }
          }
        } else {
          cell.style.backgroundColor = '#ffffff';
        }
      }
    });

    if (reorder === true) {
      setReOrder(false);
    }
  };

  useEffect(() => {
    const request = {
      ...loginRequest,
      account: accounts[0]
    };
    instance.acquireTokenSilent(request).then((response: any) => {
      let token = response.accessToken;
      localStorage.setItem(USER_KEY, token);
      localStorage.setItem(ACCESS_KEY, response.accessToken);
      getCropList().then((dataFromCropList: any) => {
        if (dataFromCropList?.error?.message) {
          if (dataFromCropList.payload === 'Request failed with status code 401') {
            let currentRequest = {
              ...loginRequest,
              account: accounts[0]
            };

            instance.acquireTokenRedirect(currentRequest).then((response) => {});
          }
        }
      });
    });
  }, []);

  const showModal = (props: any, setShow: any) => {
    setShow(true);
    const element = document.getElementById(`${props}`) as HTMLElement;
    const myModal: any = new BootstrapModal(element, { backdrop: false });
    myModal.show();
  };

  const closeModal = (modalName: any, setShow: any) => {
    setShow(false);
    const element = document.getElementById(`${modalName}`) as HTMLElement;
    if (element) {
      var modal = BootstrapModal.getOrCreateInstance(element);
      modal.hide();
    }
  };

  const onSubmit = (evt: any) => {
    showModal('loaderModal', setShowLoaderModal);
    const header = varietiesOrder?.length > 0 ? varietiesOrder : varieties?.Varieties;
    const subHeader = varietiesOrder?.length > 0 ? initialValueOfOrder1 : orderOfTrait;

    var e = document?.getElementById('selectDate') as any;
    var value = selectedDateFormat.toUpperCase()?.trim();
    let filteredLabels: any = DATE_FORMAT_LIST.filter(
      (e: any) => e?.value?.toUpperCase() === value
    );
    const valueFromToggle = document?.getElementById('switchMeasurement') as any;
    const toggledOptions = valueFromToggle?.checked ? '.' : ',';

    const year: any = new Date(selectedYear).getFullYear();
    const formData: any = {
      dateFormat: filteredLabels[0]?.label ? filteredLabels[0]?.label?.replace('mm', 'MM') : '',
      year: year,
      dateType: dateWeekToggle ? 'week' : 'date',
      separator: toggledOptions,
      uom: toggle ? 'imperial' : 'metric',
      observations: header?.map((varietyItem: any) => ({
        ezid: varietyItem?.TrialLineId,
        traits: subHeader
          .map((orderedData: any, indexOfData: any) => {
            if (orderedData?.groupOfVaritey === varietyItem?.TrialLineId) {
              return {
                traitId: orderedData?.traitID,
                dataType: orderedData?.dataType,
                uom:
                  toggle && orderedData?.uoMCodeImperial !== null
                    ? orderedData?.uoMCodeImperial
                    : toggle === false && orderedData?.uomCode !== null
                    ? orderedData?.uomCode
                    : null,
                // values: Orderof
                values: jRef?.current?.jexcel
                  ?.getColumnData(
                    varietiesOrder?.length > 0 ? indexOfData + 1 : orderedData?.columnPos
                  )
                  ?.filter((ColumnData: any, indexOfColumnData: any) => {
                    return ColumnData !== '';
                  })
                  ?.map((ColumnData: any, indexOfColumnData: any) => {
                    return {
                      obsDate:
                        ColumnData && !dateWeekToggle
                          ? jRef?.current?.jexcel?.getCellFromCoords(0, indexOfColumnData).innerText
                          : ``,
                      obsWeek:
                        ColumnData && dateWeekToggle
                          ? jRef?.current?.jexcel
                              ?.getCellFromCoords(0, indexOfColumnData)
                              .innerText.trim()
                          : '',
                      obsVal: ColumnData
                    };
                  })
              };
            }
          })
          .filter((isArrayUndefined: any) => isArrayUndefined !== undefined)
      }))
    };
    const request = {
      ...loginRequest,
      account: accounts[0]
    };
    instance.acquireTokenSilent(request).then((response: any) => {
      let token = response.accessToken;
      localStorage.setItem(USER_KEY, token);
      localStorage.setItem(ACCESS_KEY, response.accessToken);
      submitExcelData(formData).then((dataFromCropList: any) => {
        if (dataFromCropList?.payload === 'Success') {
          toast.success(`${dataFromCropList?.payload}`, {});
          jRef.current.jexcel.destroy();
          cleanTrialVarietiesData();
          cleanTraitListData();
          setSelectedTrials({});
          setSelectedTraits([]);
          setTraitStateSelectedMain([]);
        }
        if (dataFromCropList?.error?.message) {
          toast.error(`${dataFromCropList?.payload}`, {});
          if (dataFromCropList.payload === 'Request failed with status code 401') {
            let currentRequest = {
              ...loginRequest,
              account: accounts[0]
            };

            instance.acquireTokenRedirect(currentRequest).then((response) => {});
          }
        }
      });
    });
  };
  const handleOrdering = (modalName: any, setShow: any, orderedData: any) => {
    if (varietiesOrder?.length > 0) {
      setReOrder(true);
      setShow(false);
      const element = document.getElementById(`${modalName}`) as HTMLElement;
      if (element) {
        var modal = BootstrapModal.getOrCreateInstance(element);
        modal.hide();
      }
    }
  };

  const ifErrorinModal = (toastMessage: any) => {
    toast.error('No Traits in the Selected Trial');
  };
  const setMenu = (selectedTrials: any) => {
    if (selectedTrials?.TrialName) {
      setTrialMenu(0);
    } else {
      const selectedBulkCropsEmptyCheck = Object.keys(selectedBulkCrops).length === 0;
      if (selectedBulkCropsEmptyCheck) {
        setTrialMenu(1);
      } else {
        setTrialMenu(2);
      }
    }
  };

  function disabledDate(current: any) {
    // Disable dates after the current year
    return current && current.year() > new Date().getFullYear();
  }

  const handleTrialNameToggleChange = (e: any) => {
    setUserTrialNameChecked(e.target.checked);
  };

  const GetTrialName = (data: any) => {
    if (!userTrialNameChecked) {
      return data?.TrialName;
    } else {
      if (data?.UserTrialName) {
        if (data?.UserTrialName != '' || data?.UserTrialName != null) {
          return data?.UserTrialName;
        } else {
          return data?.TrialName;
        }
      } else {
        return data?.TrialName;
      }
    }
  };

  // @ts-ignore
  return (
    <Fragment>
      <main className="container-fluid px-4">
        {cropListDataLoading || varietyLoading ? (
          <div className="body_loading" role="status">
            <div className="spinner"></div>
          </div>
        ) : (
          <Fragment>
            <div className="row mb-3">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-8" id="card-left">
                        <button
                          type="button"
                          className="btn btn-success btn-success_custom me-2"
                          onClick={() => {
                            showModal('TrialModalBulkSelect', setBulkTraitShow);
                            setMenu(selectedTrials);
                          }}>
                          {selectedTrials?.TrialName ? <EditIcon /> : <PlusIcon />}
                          {selectedTrials?.TrialName ? GetTrialName(selectedTrials) : 'Trial'}
                          {userTrialNameChecked && selectedTrials?.UserTrialName && (
                            <span
                              className="p-2"
                              data-toggle="tooltip"
                              data-placement="top"
                              title={selectedTrials?.TrialName}>
                              <InfoIcon color='#ffff'/>
                            </span>
                          )}
                        </button>
                        <button
                          type="button"
                          className="btn btn-success btn-success_custom btn-outline  me-2"
                          onClick={() => showModal('CropModalBulkSelect', setBulkTraitShow)}
                          disabled={
                            selectedTrials?.TrialName && traitListData?.length > 0 ? false : true
                          }>
                          {' '}
                          Trait(s)
                        </button>
                        {jRef?.current?.jexcel && (
                          <button
                            type="button"
                            className="btn btn-success btn-success_custom btn-outline"
                            disabled={warning === true}
                            onClick={() => showModal('orderData', setShowVarietiesOrder)}>
                            {' '}
                            Order Varieties
                          </button>
                        )}
                      </div>
                      <div className="col-md-4 d-flex align-items-center" id="card-right">
                        <select
                          name="selectDate"
                          id="selectDate"
                          className="form-select form-select-date me-3"
                          value={selectedDateFormat}
                          style={{ fontSize: '14px' }}
                          onChange={handleChange}
                          placeholder={'check'}>
                          <option value="" disabled>
                            Select a Date Format
                          </option>
                          {DATE_FORMAT_LIST.map((option) => {
                            return (
                              <option value={option.value} key={option.value}>
                                {option.label}
                              </option>
                            );
                          })}
                        </select>
                        {dateWeekToggle && (
                          <DatePicker
                            id="yearPicker"
                            name="yearPicker"
                            onChange={onChange}
                            picker="year"
                            className="me-3"
                            value={selectedYear}
                            style={{ borderRadius: '5px' }}
                            disabledDate={disabledDate}
                          />
                        )}

                        <div className="form-check-switch me-3  d-flex gap-1">
                          <span style={{ fontSize: '14px' }}>Trial Name:</span>
                          <label className="switch">
                            <input
                              type="checkbox"
                              id="dateWeektogBtn"
                              checked={userTrialNameChecked}
                              onChange={(e: any) => {
                                handleTrialNameToggleChange(e);
                              }}
                            />
                            <div className="slider">
                              <span>Standard</span>
                              <span>Reference</span>
                            </div>
                            <div className="slider">
                              <span>Standard</span>
                              <span>Reference</span>
                            </div>
                          </label>
                        </div>

                        <div className="form-check-switch" style={{ paddingRight: '16px' }}>
                          <label className="switch">
                            <input
                              type="checkbox"
                              id="dateWeektogBtn"
                              checked={dateWeekToggle ? true : false}
                              onChange={handleDateWeekToggleChange}
                              //   disabled={orderOfTrait.length > 0 ? true : false}
                            />
                            <div className="slider">
                              <span>Date</span>
                              <span>Week</span>
                            </div>
                            <div className="slider">
                              <span>Date</span>
                              <span>Week</span>
                            </div>
                          </label>
                        </div>

                        <div className="form-check-switch" style={{ paddingRight: '16px' }}>
                          <label className="switch">
                            <input
                              type="checkbox"
                              id="togBtn"
                              onChange={handleToggleChange}
                              checked={toggle ? true : false}
                              disabled={orderOfTrait.length > 0 ? true : false}
                            />
                            <div className="slider">
                              <span>Metric</span>
                              <span>Imperial</span>
                            </div>
                            <div className="slider">
                              <span>Metric</span>
                              <span>Imperial</span>
                            </div>
                          </label>
                        </div>

                        <div className="form-check-switch">
                          <label className="switch">
                            <input
                              type="checkbox"
                              id="switchMeasurement"
                              onChange={handleToggleChangeMeasurement}
                              checked={measurementType ? true : false}
                            />
                            <div className="slider">
                              <span>Comma</span>
                              <span>Point</span>
                            </div>
                            <div className="slider">
                              <span>Comma</span>
                              <span>Point</span>
                            </div>
                          </label>
                        </div>

                        {/* <div className='form-check-switch'>
                                                        <label className='switch'>
                                                            <input type="checkbox" id="togBtn" onChange={handleToggleChange} checked={toggle ? true : false}/>
                                                            <div className="slider">
                                                                <span>Public</span>
                                                                <span>Private</span>
                                                            </div>
                                                            <div className="slider">
                                                                <span>Public</span>
                                                                <span>Private</span>
                                                            </div>
                                                        </label>
                                                    </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card h-100 widget-spreadsheet">
                  {isEmpty(traitStateSelectedMain) && (
                    <div className="py-4">
                      <div className="paste-data-here align-items-center">
                        <ExcelSheetIcon />
                        <span>Paste your data here</span>
                      </div>
                    </div>
                  )}

                  <Fragment>
                    {(varieties?.Varieties && varieties?.Varieties.length) > 0 &&
                      orderOfTrait.length > 0 && (
                        <div className="table-reponsive-lg table-responsive-xl ">
                          <div
                            id="spreadsheet"
                            className="table table-bordered overflow-x-scroll"
                            ref={jRef}
                          />
                        </div>
                      )}
                  </Fragment>

                  {jRef?.current && (
                    <div className="card-footer">
                      <button
                        className="btn btn-success btn-success_custom float-end"
                        disabled={
                          !dateValueValidation ||
                          !(dateValueValidation.length > 0) ||
                          warning === true ||
                          (dateWeekToggle
                            ? isEmptyString(selectedYear) || isEmptyString(selectedDateFormat)
                            : isEmptyString(selectedDateFormat)) ||
                          (varieties?.Varieties && varieties?.Varieties.length === 0) ||
                          orderOfTrait.length === 0 ||
                          excelSliceLoading ||
                          isEmpty(traitStateSelectedMain)
                        }
                        onClick={(evt: any) => onSubmit(evt)}>
                        {'Submit'}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Fragment>
        )}
      </main>
      <BulkTraitSelectionModal
        prevTraits={prevTraits}
        setPrevTraits={setPrevTraits}
        measurementType={measurementType}
        handleToggleChangeMeasurement={handleToggleChangeMeasurement}
        setInitialValueOfOrder1={setInitialValueOfOrder1}
        setInitialValueOfOrder={setInitialValueOfOrder}
        setVarietiesOrder={setVarietiesOrder}
        setSelected={setSelectedTraits}
        selected={selectedTraits}
        jRef={jRef}
        setOrder={setOrder}
        order={order}
        toggle={toggle}
        setReDraw={setReDraw}
        handleChangeMain={handleChange}
        onChangeYear={onChange}
        selectedYear={selectedYear}
        dateWeekToggle={dateWeekToggle}
        selectedDateFormat={selectedDateFormat}
        handleDateWeekToggleChange={handleDateWeekToggleChange}
        traitStateSelectedMain={traitStateSelectedMain}
        selectedTrials={selectedTrials?.TrialName}
        varieties={varieties}
        setOrderOfTrait={setOrderOfTrait}
        handleToggleChange={handleToggleChange}
        show={showBulkTrait}
        setShow={setBulkTraitShow}
        setValue={setTraitStateSelectedMain}
        growerValue={traitStateSelectedMain}
        options={traitListData}
        header={'Select Traits'}
        className={'ml-1'}
        id="CropModalBulkSelect"
        SetCropsState={setTraitStateSelectedMain}
        closeModal={closeModal}
      />

      <BulkTrialSelectionModal
        ifErrorinModal={ifErrorinModal}
        trialMenu={trialMenu}
        setInitialValueOfOrder={setInitialValueOfOrder}
        setInitialValueOfOrder1={setInitialValueOfOrder1}
        setVarietiesOrder={setVarietiesOrder}
        setTraitStateSelectedMain={setTraitStateSelectedMain}
        setSelectedTraits={setSelectedTraits}
        setOrder={setOrder}
        varietyLoading={varietyLoading}
        cleanTraitListData={cleanTraitListData}
        jRef={jRef}
        setReDraw={setReDraw}
        setOrderOfTrait={setOrderOfTrait}
        selectedTrialsMain={selectedTrials}
        setSelectedTrialsMain={setSelectedTrials}
        traitListDataLoading={traitListDataLoading}
        getVarieties={getVarieties}
        trialListData={trialListData}
        trailListDataLoading={trailListDataLoading}
        show={showBulkTrait}
        setShow={setBulkTraitShow}
        selectedBulkCropsMain={selectedBulkCrops}
        setValue={setSelectedBulkCrops}
        value={cropSelectionValue}
        header={'Select Trails'}
        className={'ml-1'}
        id="TrialModalBulkSelect"
        cropListData={cropListData}
        getTrialList={getTrialList}
        getTraitList={getTraitList}
        // SetCropsState={SetCropsState}
        // SetCallApi={SetCallApi} callApi={callApi} label={"Crop"}
        closeModal={closeModal}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        // onOkay={onOkay}
        userTrialNameChecked={userTrialNameChecked}
        GetTrialName={GetTrialName}
      />
      <SortTableOrderModal
        id="orderData"
        initialValueOfOrder1={initialValueOfOrder1}
        setInitialValueOfOrder1={setInitialValueOfOrder1}
        varietiesOrder={varietiesOrder}
        setVarietiesOrder={setVarietiesOrder}
        handleOrdering={handleOrdering}
        setShow={setShowVarietiesOrder}
        closeModal={closeModal}
        varieties={varieties?.Varieties}
        show={showVarietiesOrder}
        orderOfTrait={orderOfTrait}
        jRef={jRef}
        initialValueOfOrder={initialValueOfOrder}
        setInitialValueOfOrder={setInitialValueOfOrder}
      />
      <LoaderModal
        closeModal={closeModal}
        id="loaderModal"
        excelSliceLoading={excelSliceLoading}
        show={showLoaderModal}
        setShow={setShowLoaderModal}
      />
      <ToastContainer position="top-center" autoClose={2000} closeOnClick />
    </Fragment>
  );
};

export default BulkInsert;
