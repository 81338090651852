import React, {useEffect, Fragment, useState} from "react";
import Navbar from "react-bootstrap/Navbar";
import Card from "react-bootstrap/Card";
import {
    InteractionRequiredAuthError,
    InteractionStatus,
} from "@azure/msal-browser";
import {NavLink, withRouter, useHistory} from 'react-router-dom'
import {useIsAuthenticated, useMsal} from "@azure/msal-react";
import {graphloginRequest, loginRequest} from "../authConfig";
import {callMsGraph, callMsGraphUserPhoto} from "../graph";
import {ACCESS_KEY, GraphACCESS_KEY, USER_KEY,USER_ROLES} from "../constants";
import {Link} from 'react-router-dom';
import Logo from "../assets/images/logo.png";
import MessageIcon from "../assets/images/customer-service.png";
import NotificationIcon from "../assets/images/email-outline-badged.png";
import SettingIcon from "../assets/images/bytesize_settings.png";
import vitalisImage from "../assets/images/vitalis.png"
import Footer from "../components/footer";
import Header1  from "../components/header";
import Button from "react-bootstrap/Button";
import {clearDynamicLocalStorage, getLocalStorage} from "../util/localStorage.util";
import {isEmpty} from "../util/commonUtils";

/**
 * Renders the navbar component with a sign-in or sign-out button depending on whether or not a user is authenticated
 * @param props
 */
export const PageLayout = (props: any) => {
    const {instance, accounts,logger,inProgress} = useMsal();
    const [graphData, setGraphData]: any = useState({});
    const [image, setImage]:any = useState()
    const [isOk, setResponse]:any = useState()
    const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        RequestProfileData()
    }, [instance, accounts, inProgress,graphData]);


    function RequestProfileData() {
        const request = {
            ...graphloginRequest,
            account: accounts[0]
        };
        if (isEmpty(graphData) &&  (inProgress === InteractionStatus.None)) {
            instance.acquireTokenSilent(request).then((response:any) => {
                // localStorage.setItem(USER_ROLES, JSON.stringify(response?.account?.idTokenClaims?.roles))
                // let token = response.accessToken? response.accessToken: response?.id_Token
                // // console.log(response,'response')
                // localStorage.setItem(USER_KEY, token)
                // localStorage.setItem(ACCESS_KEY, response.accessToken)
                localStorage.setItem(GraphACCESS_KEY,response.accessToken);
                callMsGraph(response.accessToken).then(response =>
                    setGraphData(response));
                callMsGraphUserPhoto(response.accessToken).then((response:any) => {
                    if(response.status === 200){
                        response.blob().then((data:any) => {
                            const reader = new FileReader()
                            reader.readAsDataURL(data)
                            reader.onload = () => {
                                const base64data = reader.result;
    
                                setImage(base64data)
                                setResponse(true)
                            }
    
                        })
                    }else{
                        setResponse(false);
                    }
                    
                });
                // const token = response?.idToken
                // axios.get(`https://graph.microsoft.com/v1.0/me/photo/$value`,{ headers: {"Authorization" : `Bearer ${token}`} }).then((res:any) => {
                //         console.log(res,'res')
                //         const persons = res.data;
                //     })
            }).catch((err:any) =>{
                if (err instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect(request).then((response) => {
                        // console.log(response,'responses')
                    })
                }else{
                    instance.acquireTokenPopup(request).then((response) => {
                        // const token = response?.idToken

                        // localStorage.setItem(USER_KEY, token)
                        // localStorage.setItem(ACCESS_KEY, response.accessToken)
                        callMsGraph(response.accessToken).then(response => setGraphData(response));
                    });
                }

            });
        }
    }

    return (
        <Fragment>
            {(
                <Fragment>
                    <Header1 graphData={graphData} isOk={isOk} image={image}/>
                    {props.children}
                    <Footer/>
                </Fragment>
            )}
        </Fragment>
    );
};
