import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {v2Fetch, v2Fetch3001, v2Post, v3Fetch, v3Post} from "../../util/httpUtil";

const initialState: any = {
    payload:[],
    error: undefined,
    loading: false,
};

export const fetchFormSettingsList = createAsyncThunk(
    'formSettings/fetch',
    (id:any, { rejectWithValue }) => {
        return v3Fetch(`cms/tdmcms/tdm`, {})
            .then((response:any) => {
                if (response.status === 200) {
                    response?.data?.sort((a:any, b:any) => a?.order - b.order)
                    return Promise.resolve(response?.data);
                } else {
                    // TODO
                }
            })
            .catch((error) => {
                const errorThrown = JSON.parse(error)
                return rejectWithValue(errorThrown?.message)

            });
    }
);


export const fetchFormSettingsListSlice = createSlice({
    name: "fetchFormSettingsList",
    initialState,
    reducers: {
        cleanFormSettingListData(state) {
            state.loading = false;
            state.payload = [];
            state.error = {};
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchFormSettingsList.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(fetchFormSettingsList.fulfilled, (state, action) => {
            state.loading = false;
            state.payload = action.payload;
        });
        builder.addCase(fetchFormSettingsList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
        });
    },
});





export const {cleanFormSettingListData} = fetchFormSettingsListSlice.actions;
// export const {cleanFormSettingIDData} = formSettingById.actions;
// export const {cleanTrialVarietiesData} = varietiesSlice.actions;
// export const { cleanCropListData } = cropListSlice.actions;
