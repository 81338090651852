import { configureStore } from "@reduxjs/toolkit";
import {
  countriesSlice,
  cropSlice,
  areasSlice,
  cropSubSegmentSlice,
  trialSlice,
  trialPropertiesSlice,
  growerDataSlice,
  growerDataFromObserverSlice,
  cropSubFromObserverSlice, newsSlice
} from '../pages/Dashboard/slice';



import {
  cropListSlice, getTraitList, trailListSlice, traitListSlice, varietiesSlice,excelSlice
} from '../pages/Bulk-Insert/slice';

import {
  formSettingImageSlice,
  formSettingSlice
} from '../pages/Admin-Add-Form/slice';
import {settingListSlice} from "../pages/Admin/slice";
import {fetchFormSettingsListSlice} from "../pages/E-learning/slice";

export const store = configureStore({
  reducer: {
    formSettingImageReducer: formSettingImageSlice.reducer,
    FormSettingsList :fetchFormSettingsListSlice.reducer,
    settingListReducer:settingListSlice.reducer,
    formSettingReducer: formSettingSlice.reducer,
    varietiesReducer:varietiesSlice.reducer,
    traitListReducer:traitListSlice.reducer,
    trialListReducer:trailListSlice.reducer,
    cropListReducer:cropListSlice.reducer,
    cropReducer:cropSlice.reducer,
    countriesReducer:countriesSlice.reducer,
    areasReducer:areasSlice.reducer,
    cropSubSegmentReducer :cropSubSegmentSlice.reducer,
    trialReducer:trialSlice.reducer,
    trialPropertiesReducer:trialPropertiesSlice.reducer,
    growerReducer:growerDataSlice.reducer,
    growerFromObservationReducer:growerDataFromObserverSlice.reducer,
    cropSubFromObserverReducer:cropSubFromObserverSlice.reducer,
    newsReducer:newsSlice.reducer,
    excelDataReducer:excelSlice.reducer
  },
  middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          // Ignore these action types
          ignoredActions: ['formSettingFetchImageByID','formSettingFetchImageByID/fetch'],
          // Ignore these field paths in all actions
          ignoredActionPaths: [ 'payload.fileData','meta.arg.img'],
          // Ignore these paths in the state
          ignoredPaths: ['formSettingImageReducer.payload.fileData'],
        },
      }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
