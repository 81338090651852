import React, {Fragment, useEffect, useState, useRef} from 'react';
import _ from 'lodash';
import moment from 'moment';
import {Chart as ChartJS, ArcElement, Tooltip, Legend, BarElement, LinearScale, CategoryScale, Title} from 'chart.js';
import {Doughnut, Bar} from 'react-chartjs-2';
import {useStatePersist} from 'use-state-persist';
import {Modal as BootstrapModal} from 'bootstrap';
// @ts-ignore
import {MenuSelection} from '../../components/MenuSelection'
import {CustomModalGrowerSelect} from '../../components/commonModal';
import {CustomModalAreaSelect} from '../../components/customModalAreaSelect';
import {CustomModalCountrySelect} from '../../components/customModalCountrySelect';
import {CustomModalCropSelect} from '../../components/cropModal';
import {CustomModalSubSegmentSelect} from '../../components/cropSubsegmentModal';
import {CustomModalSegmentSelect} from '../../components/cropSegmentModal';
import {TDMProgrammes} from '../../components/TDMProgrammes';
import {NewsFeed} from '../../components/NewsFeed';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {ACCESS_KEY, CSS_COLOR_NAMES, USER_KEY} from "../../constants/index";
import {useMsal} from "@azure/msal-react";
import {loginRequest} from "../../authConfig";
import {
    ArrowRightIcon,
    DateIcon,
    CropIcon,
    CropSegementIcon,
    CropSubSegementIcon,
    CountryIcon,
    AreaIcon,
    GrowerIcon,
    NoDataIcon
} from "../../assets/svgs";
import {isEmpty} from "../../util/commonUtils";
import {InteractionStatus} from "@azure/msal-browser";
import {callMsGraph, callMsGraphUserPhoto} from "../../graph"

const Dashboard = (props: any) => {
    const {
        fetchTrialProperties,
        submitTrial,
        fetchAreas,
        fetchCountries,
        getCropData,
        fetchGrowerDataFromObservation,
        fetchNews,
        news,
    } = props;
    const {instance, accounts,logger,inProgress} = useMsal();
    ChartJS.register(ArcElement, Tooltip, BarElement, LinearScale, CategoryScale, Title, Legend);
    const [cropArray, setCropArray] = useStatePersist('cropArrayOptions', []);
    const [countriesOption, setCountriesOption] = useStatePersist('countriesOptions', []);
    const [areasOptions, setAreasOptions] = useStatePersist('areasOptions', [])
    const [show, setShow] = useStatePersist('showGrower', false);
    const [showArea, setAreaShow] = useStatePersist('showArea', false);
    const [showCountry, setCountryShow] = useStatePersist('showCountry', false);
    const [showCrop, setCropShow] = useStatePersist('showCrop', false);
    const [showCropSegment, setCropSegmentShow] = useStatePersist('showCropSegment', false);
    const [showCropSubSegment, setCropSubSegmentShow] = useStatePersist('showCropSubSegment', false);
    const chartRef: any = useRef(null);
    const [cropsState, SetCropsState] = useStatePersist('cropsState', []);
    const [cropsSegmentSate, SetCropSegmentSate] = useStatePersist('cropsSegmentSateValueShown', []);
    const [cropSubSegmentState, setCropSubSegmentState] = useStatePersist('cropSubSegmentStateOptions', []);
    const [showBarChartData, SetShowBarChartData] = useStatePersist('showBarChartData', false);
    const [showDoughnutChartData, SetShowDoughnutChartData] = useStatePersist('useStatePersist', false);
    let [cropArea, SetCropAreaSate] = useStatePersist('areaValueShown', []);
    const [growerValue, setGrowerValue]: any = useStatePersist('growerValueShown', []);
    const [callApi, SetCallApi] = useState(false);
    let statusCodes: any = []
    const [countryValue, setCountryValue]: any = useStatePersist('countryValueShown', []);
    const [cropSegmentData, setCropSegmentData] = useStatePersist('cropSegmentDataOptions', []);
    let [cropSubSegmentData, setCropSubSegmentData] = useStatePersist('cropSubSegmentValueShown', []);
    let [clearCropSegmentData, SetClearCobSegmentData] = useState(false);
    let [clearCropSubSegmentData, SetClearSubCropSegmentData] = useState(false);
    let [clearGrowerData, SetClearGrowerData] = useState(false);
    let [growerOptions, setGrowerOptions] = useStatePersist('growerOptions', []);

    let data1: any = [];
    let [dataChart, setDataChart]: any = useStatePersist('dataChart', {
        labels: [],
        datasets: [{data: [], backgroundColor: [], borderColor: [], borderWidth: 1,},],
    });
    let [barChartData, setBarChartData]: any = useStatePersist('barChartData', {
        labels: [],
        datasets: []
    });

    let colorMap: any = []
    let [calculateTotal, setCalculateTotal]: any = useStatePersist('calculateTotal', null);


    let [optionsBar, setOptionsBar]: any = useStatePersist('optionsBar', {
        responsive: true,
        plugins: {
            legend: {
                position: "top" as const,
                labels: {
                    usePointStyle: true
                }
            },
            title: {
                display: true,
                text: 'Trial per status per Crop',
            },

        },
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
                ticks: {
                    beginAtZero: true
                }
            },
        },
    });
    let optionsDoughnout = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
                position: "top" as const
            },
            title: {
                display: false,
                text: 'Number of trials open, Number of trials closed and Number of trials by Crop name',
            },

        },
    };

    useEffect(() => {
        if (showCrop) {
            showModal('CropModal', setCropShow)
        } else {
            closeModal('CropModal', setCropShow)
        }
        if (show) {
            showModal('growerModal', setShow)
        } else {
            closeModal('growerModal', setShow)
        }
        if (showArea) {
            showModal('areaModal', setAreaShow)
        } else {
            closeModal('areaModal', setAreaShow)
        }
        if (showCountry) {
            showModal('countryModal', setCountryShow)
        } else {
            closeModal('countryModal', setCountryShow)
        }
        if (showCropSegment) {
            showModal('CropSegmentModal', setCropSegmentShow)
        } else {
            closeModal('CropSegmentModal', setCropSegmentShow)
        }

        if (showCropSubSegment) {
            showModal('CropSubSegmentModal', setCropSubSegmentShow)
        } else {
            closeModal('CropSubSegmentModal', setCropSubSegmentShow)
        }

        const request = {
            ...loginRequest,
            account: accounts[0]
        };

        instance.acquireTokenSilent(request).then((response:any) => {
            let token = response.accessToken;
            localStorage.setItem(USER_KEY, token)
            localStorage.setItem(ACCESS_KEY, response.accessToken)
            fetchNews();
            getCropData().then((data: any) => {
                if (data?.payload && isEmpty(data?.error)) {
                    const cropArrayFromResponse: any =
                        (Array.isArray(data?.payload) && data?.payload.length > 0) ? Array.isArray(data?.payload) && data?.payload?.map((cropData: any) => {
                            return {
                                value: cropData?.cropGroupID,
                                cropCode: cropData?.cropCode,
                                label: cropData?.cropName
                            };
                        }).sort((compareCropA: any, compareCropB: any) => compareCropA.label.localeCompare(compareCropB.label)) : [];
                    setCropArray(cropArrayFromResponse)

                }
                if (data?.error?.message) {
                    toast.error(`${data?.payload}`, {});
                    if (data.payload === 'Request failed with status code 401'){
                        localStorage.removeItem(USER_KEY)
                        localStorage.removeItem(ACCESS_KEY)
                        const request = {
                            ...loginRequest,
                            account: accounts[0]
                        };

                        let  currentRequest = {
                            ...loginRequest,
                            account: accounts[0]
                        };

                        instance.acquireTokenRedirect(currentRequest).then((response) => {
                            getCropData()
                        })
                    }
                }


            }).catch((err:any)=>console.log(err,'err'))
            ;
            fetchCountries().then((data: any) => {
                    if (data?.payload) {
                        const countries = data?.payload.map((country: any) => {
                            return {
                                value: country.countryCode,
                                label: country.name
                            };
                        }).sort((a: any, b: any) => a.label.localeCompare(b.label));
                        setCountriesOption(countries)
                    }
                    if (data?.error?.message) {
                        toast.error(`${data?.payload}`, {});

                    }
                }
            );
            fetchAreas().then((data: any) => {
                    if (data?.payload) {
                        const areas = data?.payload.map((areaData: any) => {
                            return {
                                value: areaData?.id,
                                label: areaData?.name,
                                displayName: areaData?.code
                            };
                        }).sort((compareAreaA: any, compareAreaB: any) => compareAreaA.label.localeCompare(compareAreaB.label))
                        setAreasOptions(areas)
                    }
                    if (data?.error?.message) {
                        toast.error(`${data?.payload}`, {});

                    }
                }
            );
        })

    }, []);

    const fetchData = () => {
        SetClearCobSegmentData(false);
        SetClearSubCropSegmentData(false);
        SetClearGrowerData(false);
        SetShowBarChartData(false);
        SetShowDoughnutChartData(false);
        setCropSubSegmentState([])
        setCropSegmentData([])
        // setGrowerOptions([])
        // setGrowerOptions([])
        if (growerValue.length > 0 || cropArea.length > 0 || countryValue.length > 0 || cropSubSegmentData.length > 0 || cropsSegmentSate.length > 0 || cropsState.length > 0) {
            if (cropsState.length > 0) {
                let dataArgumentsForTrialFetch: any = cropsState.map((cropCode: any) => {
                    return cropCode.cropCode
                })
                const fetchTrialQuery = `?cropCode=${dataArgumentsForTrialFetch.join(",").toString()}`
                const request2 = {
                    ...loginRequest,
                    account: accounts[0]
                };
                instance.acquireTokenSilent(request2).then((response:any) => {
                    let token = response.accessToken;
                    localStorage.setItem(USER_KEY, token)
                    localStorage.setItem(ACCESS_KEY, response.accessToken)
                    fetchTrialProperties(fetchTrialQuery).then(async (res: any) => {
                        if (res?.payload) {
                            Promise.all(
                                [getFilter(res?.payload, 'Grower', dataArgumentsForTrialFetch), getFilter(res?.payload, 'CropSubSgm', dataArgumentsForTrialFetch), getFilter(res?.payload, 'CropSegmnt', dataArgumentsForTrialFetch)
                                ])
                                .then(results => {
                                    submitData(dataArgumentsForTrialFetch, results[0]);
                                });
                        }
                        if (res?.error?.message) {
                            toast.error(`${res?.payload}`, {});

                            toast.error(`${res?.payload}`, {});
                            if (res.payload === 'Request failed with status code 401'){
                                localStorage.removeItem(USER_KEY)
                                localStorage.removeItem(ACCESS_KEY)
                                const request = {
                                    ...loginRequest,
                                    account: accounts[0]
                                };

                                let  currentRequest2 = {
                                    ...loginRequest,
                                    account: accounts[0]
                                };

                                instance.acquireTokenRedirect(currentRequest2).then((response) => {
                                    getCropData()
                                })
                            }
                        }
                    }).catch((err: any) => console.log(err));
                })

            }

        } else {

            setCropSegmentData([])
        }
    }


    const getFilter = async (payload: any, type: any, cropCodeValue: any) => {
        let finalGrower: any;
        if (type === 'Grower') {
            const filterGrower: any = await payload.filter((columnLabel2: any) => {
                return columnLabel2?.columnLabel === 'Grower'
            });

            if (filterGrower.length > 0) {
                const growerID = filterGrower?.[0]?.propertyID
                fetchGrowerDataFromObservation(growerID).then(async (growerFetchedData: any) => {
                    if (growerFetchedData?.payload) {
                        setGrowerOptions(growerFetchedData?.payload)
                        finalGrower = {...growerFetchedData?.payload}
                        if (growerValue.length > 0 && growerOptions.length > 0) {
                            const filteredGrowerArray = growerValue.filter((c: any) => growerFetchedData?.payload.some((g: any) => g.propertyID === c.propertyID));
                            if (filteredGrowerArray.length > 0) {
                                setGrowerValue(filteredGrowerArray)
                            } else {
                                setGrowerValue([])
                            }
                        } else {

                            return {finalGrower};

                        }


                    }

                })

            }
            return [filterGrower?.[0]?.propertyID]
        } else if (type === 'CropSubSgm') {
            const filterCropSubSegment: any = await payload.filter((columnLabel1: any) => {
                return columnLabel1?.columnLabel === 'CropSubSgm'
            });
            let uniqueChars: any = [...new Set(filterCropSubSegment)];
            if (uniqueChars.length > 0) {
                const cropSubSegmentID = uniqueChars[0]?.propertyValues
                const filteredArrayCropSubSegment = cropSubSegmentData.filter((c: any) => cropCodeValue.some((g: any) => g === c.cropCode));
                if (cropSubSegmentData.length > 0) {
                    if (filteredArrayCropSubSegment.length > 0) {
                        setCropSubSegmentData(filteredArrayCropSubSegment)
                        SetClearSubCropSegmentData(false)

                    } else {
                        setCropSubSegmentData([])
                        SetClearSubCropSegmentData(true)
                    }
                } else {
                    SetClearSubCropSegmentData(false)

                }
                const cropSubSegmentedData1 = cropSubSegmentID.map((flattened: any) => {
                    return {
                        value: flattened.traitValueCode,
                        label: flattened.traitValueName,
                        cropCode: flattened.cropCode,
                    };
                }).sort((compareCropSubSegmentA: any, compareCropSubSegmentB: any) => compareCropSubSegmentA.label.localeCompare(compareCropSubSegmentB.label))

                const uniqueCropSubSegmentedData1: any = [...new Map(cropSubSegmentedData1.map(item =>
                    [item['value'], item])).values()];

                setCropSubSegmentState(uniqueCropSubSegmentedData1)
            }

        } else {
            const filterCropSegment: any = await payload.filter((cropSegementData: any) => {
                return cropSegementData?.columnLabel === 'CropSegmnt'
            });

            if (filterCropSegment.length > 0) {
                const filteredArray = cropsSegmentSate.filter((c: any) => cropCodeValue.some((g: any) => g === c.cropCode));
                if (cropsSegmentSate.length > 0) {
                    if (filteredArray.length > 0) {
                        SetCropSegmentSate(filteredArray)
                        SetClearCobSegmentData(false)

                    } else {
                        SetCropSegmentSate([])
                        SetClearCobSegmentData(true)
                    }
                } else {
                    SetClearCobSegmentData(false)
                }

                const cropSegmentedData1 = filterCropSegment?.[0]?.propertyValues.map((flattened: any) => {
                    return {
                        value: flattened.traitValueCode,
                        label: flattened.traitValueName,
                        cropCode: flattened.cropCode,
                    };
                }).sort((compareCropSegmentA: any, compareCropSegmentB: any) => compareCropSegmentA.label.localeCompare(compareCropSegmentB.label))

                // @ts-ignore
                const uniqueCropSegmentedData1: any = [...new Map(cropSegmentedData1.map(item =>
                    [item['value'], item])).values()];

                setCropSegmentData(uniqueCropSegmentedData1)
            }
        }
    }
    const getStatusCodeData = (data: any) => {
        let obj: any = []
        statusCodes.forEach((statistic: any) => {
            const element = data.find((e: any) => e.status === statistic)
            if (element) {
                obj.push(element?.count);
            } else {
                obj.push(0);
            }
        })
        return obj;
    }

    const fetchDiagramData = (trialData: any) => {
        const filteredDataSets = [];
        const getLabel = []
        const getLabelHeader: any = []
        const barForChartData: any = {}
        const barData = trialData?.results
        if (isEmpty(barData)) {
            SetShowBarChartData(false)
            SetShowDoughnutChartData(false)
            return
        }
        if (barData) {
            for (var key in barData) {
                getLabelHeader.push(key);
                // skip loop if the property is from prototype
                if (!barData.hasOwnProperty(key)) continue;


                var obj = barData[key];

                for (var prop in obj) {
                    // skip loop if the property is from prototype
                    if (!obj.hasOwnProperty(prop)) continue;
                    // your code
                    if (prop === 'totalCount') {
                        data1.push(obj?.totalCount);
                    } else if (prop === 'trials') {
                        filteredDataSets.push(obj?.trials);
                        getLabel.push({trial1: obj?.trials, label: key})
                    }


                }
            }

        }

        if (getLabel.length > 0) {
            let getLabelFlatten = _.flatten(getLabel)
            const getLabelSort: any = _.chain(getLabelFlatten).groupBy("label").map((value: any, key: any) => (
                {
                    label: key,
                    data: value?.[0]?.trial1
                })).value()
            let getStatusCode: any = getLabelSort.length > 0 && getLabelSort.map((getStatus1: any) =>
                getStatus1?.data?.map((getStatus12: any) =>
                    getStatus12.status
                )
            )

            statusCodes = [...new Set(getStatusCode.flat())]
            let getLabelSortMap: any = statusCodes.length > 0 && getLabelSort.length > 0 && getLabelSort.map((getLabelSort1: any, getLabelSortIndex: any) => {
                    return {
                        label: getLabelSort1.label.toUpperCase(),
                        data: getStatusCodeData(getLabelSort1.data),
                        backgroundColor: CSS_COLOR_NAMES[getLabelSortIndex],
                        // backgroundColor: CSS_COLOR_NAMES[Math.floor(Math.random() * CSS_COLOR_NAMES.length)],
                    };
                }
            )

            if (getLabelSortMap.length > 0) {
                colorMap = getLabelSortMap.map((arrayData: any) => {
                    return arrayData?.backgroundColor
                })
                if (colorMap.length > 0) {
                    let DataForDoughnoutChart: any = {
                        labels: getLabelHeader,
                        datasets: [{data: [], backgroundColor: [], borderColor: [], borderWidth: 1,},],
                    }
                    DataForDoughnoutChart.labels = getLabelHeader.map((dataChartLabelData: any) => {
                        return dataChartLabelData.toUpperCase()
                    })
                    DataForDoughnoutChart.datasets = [{data: data1, backgroundColor: colorMap, borerWidth: 1,}]
                    setDataChart(DataForDoughnoutChart)
                    SetShowDoughnutChartData(true)
                }


                barForChartData.labels = statusCodes.map((statusData: any) => {
                    return statusData.toUpperCase()
                })
                barForChartData.datasets = getLabelSortMap
                if (barForChartData.datasets && barForChartData.labels) {
                    setBarChartData(barForChartData)
                    SetShowBarChartData(true);
                }
            }
        }

        if (data1.length > 0) {
            const calculcatedStatistic = data1.reduce(function (accumulator: any, currentValue: any) {
                // setShowData(true)
                const here = accumulator + currentValue
                return here;
            }, 0);

            const calculateTrialCrop = calculcatedStatistic ? calculcatedStatistic.toString() : '';
            setCalculateTotal(calculateTrialCrop)
        } else {
            setCalculateTotal('')
        }
    }


    const submitData = (cropCodeArrayValue: any, propertyIDGrower: any) => {
        let growers: any = {  key: "",
            value: []}
        if (growerValue.length > 0){
            const filteredGrowerArray = growerValue.filter((c: any) => propertyIDGrower.some((growerID: any) => growerID === c.propertyID));
            if (filteredGrowerArray.length > 0) {
                growers = {
                    key: growerValue[0]?.propertyID,
                    value: growerValue?.map((growerValue1: any) => {
                        return growerValue1?.value
                    })
                }

            } else {
                growers = {
                    key: "",
                    value: []
                }
            }
        }

        let cropSubSegments: any = []
        const filteredArrayForCropSubSegments = cropSubSegmentData.filter((c: any) => cropCodeArrayValue.some((g: any) => g === c.cropCode));
        if (filteredArrayForCropSubSegments.length > 0) {
            cropSubSegments = filteredArrayForCropSubSegments?.map((value2: any) => {
                return value2?.value
            })
        } else {
            cropSubSegments = []
        }


        let cropCodes: any = cropsState?.map((value: any) => {
            return value?.cropCode
        })

        let cropSegments: any = []
        const filteredArray = cropsSegmentSate.filter((c: any) => cropCodeArrayValue.some((g: any) => g === c.cropCode));
        if (filteredArray.length > 0) {
            cropSegments = filteredArray?.map((value: any) => {
                return value?.value
            })

        } else {
            cropSegments = []
        }


        let countries: any = countryValue?.map((value: any) => {
            return value?.value
        })

        let areas: any = cropArea?.map((value: any) => {
            return value?.value
        })

        let formData: any = {
            cropCodes,
            cropSegments,
            countries,
            areas,
            cropSubSegments,
            growers
        }
        const request = {
            ...loginRequest,
            account: accounts[0]
        };
        instance.acquireTokenSilent(request).then((response:any) => {
            let token = response.accessToken;
            localStorage.setItem(USER_KEY, token)
            localStorage.setItem(ACCESS_KEY, response.accessToken)
            submitTrial(formData).then((res: any) => {
                const trialDataFromApi: any = res?.payload
                if (res?.payload)  {
                    fetchDiagramData(trialDataFromApi);
                }


                if (res?.error?.message) {
                    toast.error(`${res?.payload}`, {});

                    if (res.payload === 'Request failed with status code 401') {


                        let currentRequest = {
                            ...loginRequest,
                            account: accounts[0]
                        };


                        instance.acquireTokenRedirect(currentRequest).then((response) => {
                        })
                    }

                }
            })
        })

    }

    const showModal = (props: any, setShow: any) => {
        setShow(true)
        const element = document.getElementById(`${props}`) as HTMLElement
        const myMidal: any = new BootstrapModal((element), {backdrop: false})
        myMidal.show()
    }

    const closeModal = (modalName: any, setShow: any) => {
        setShow(false)
        const element = document.getElementById(`${modalName}`) as HTMLElement
        if (element) {
            var modal = BootstrapModal.getOrCreateInstance(element)
            modal.hide()
        }
    }

    const onOkay = (modalName: any, setShow: any) => {
        const filteredArray = cropsSegmentSate.filter((c: any) => cropsState.some((g: any) => g?.cropCode === c?.cropCode));
        if (filteredArray.length > 0) {
            SetCropSegmentSate(filteredArray)
        } else {
            SetCropSegmentSate([])
        }

        const filteredArrayForCropSubSegments = cropSubSegmentData.filter((c: any) => cropsState.some((g: any) => g?.cropCode === c.cropCode));
        if (filteredArrayForCropSubSegments.length > 0) {
            setCropSubSegmentData(filteredArrayForCropSubSegments)

        } else {
            setCropSubSegmentData([])
        }
        if (growerValue.length > 0) {
            const filteredGrowerArray = growerValue.filter((c: any) => growerOptions?.some((g: any) => g.propertyID === c.propertyID));
            if (filteredGrowerArray.length > 0) {
                setGrowerValue(filteredGrowerArray)

            } else {
                setGrowerValue([])
                setGrowerOptions(growerOptions)
            }
        }



        setShow(false)
        const element = document.getElementById(`${modalName}`) as HTMLElement
        if (element) {
            var modal = BootstrapModal.getOrCreateInstance(element)
            modal.hide()
        }
        fetchData()
    }


    return (
        <Fragment>
            <main className='container-sm container-md container-xxl'>
                <MenuSelection showModal={showModal} cropsState={cropsState} setCropShow={setCropShow}
                               setCropSegmentShow={setCropSegmentShow} cropsSegmentSate={cropsSegmentSate}
                               setCropSubSegmentShow={setCropSubSegmentShow} cropSubSegmentData={cropSubSegmentData}
                               setCountryShow={setCountryShow} countryValue={countryValue} setAreaShow={setAreaShow}
                               cropArea={cropArea} setShow={setShow} growerValue={growerValue}
                />


                <div className="row mb-4">
                    <div className="p-2 col-xl-3">
                        <div className='w-auto card h-100'>
                            <div className='card-body'>
                                <div className="pie-chart">
                                    {showDoughnutChartData === true && (
                                        <Fragment>
                                            <h3>Total amount trials on Crop</h3>
                                            <span>{calculateTotal}</span>
                                            <div className="pie-chart-div">
                                                <Doughnut data={dataChart} options={optionsDoughnout}
                                                          redraw={true}
                                                          width={'100%'} height={'180px'}/>
                                            </div>
                                        </Fragment>
                                    )}
                                </div>
                                {showDoughnutChartData === false && (
                                    <Fragment>
                                        <div className='no-result'>
                                            <NoDataIcon/>
                                            <h3 className='text-muted title'>Selected filter results no data</h3>
                                        </div>
                                    </Fragment>
                                )}
                            </div>
                        </div>
                    </div>


                    <div className="p-2 col-xl-6">
                        <div className="w-auto card h-100">
                            <div className="card-body">
                                <div className="">
                                    {showBarChartData === true && (
                                        <div className="w-auto">
                                            <Bar id="var1" ref={chartRef} data={barChartData} options={optionsBar}
                                                 redraw={true}/>
                                        </div>
                                    )}
                                    {showBarChartData === false && (
                                        <Fragment>
                                            <div className='no-result'>
                                                <NoDataIcon/>
                                                <h3 className='title text-muted'>Selected filter results no data</h3>
                                            </div>
                                        </Fragment>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>


                    <NewsFeed news={news}/>

                </div>


                <TDMProgrammes/>

                <CustomModalGrowerSelect show={show} setShow={setShow} growerValue={growerValue}
                                         className={"ml-1"}
                                         id="growerModal"
                                         options={growerOptions} setValue={setGrowerValue}
                                         SetCallApi={SetCallApi} callApi={callApi} label={"Grower"}
                                         closeModal={closeModal}
                                         onOkay={onOkay}
                                         SetClearGrowerData={SetClearGrowerData}
                                         clearGrowerData={clearGrowerData}
                />


                <CustomModalAreaSelect show={showArea} setShow={setAreaShow} growerValue={cropArea}
                                       className={"ml-2"}
                                       id="areaModal"
                                       options={areasOptions} setValue={SetCropAreaSate}
                                       SetCallApi={SetCallApi} callApi={callApi} label={"Trial Area"}
                                       closeModal={closeModal}
                                       onOkay={onOkay}
                />


                <CustomModalCountrySelect show={showCountry} setShow={setCountryShow} countryValue={countryValue}
                                          className={"ml-1"}
                                          id="countryModal"
                                          options={countriesOption} setValue={setCountryValue}
                                          SetCallApi={SetCallApi} callApi={callApi} label={"Country"}
                                          closeModal={closeModal}
                                          onOkay={onOkay}
                />


                <CustomModalSubSegmentSelect show={showCropSubSegment} setShow={setCropSubSegmentShow}
                                             growerValue={cropSubSegmentData}
                                             id="CropSubSegmentModal"
                                             className={"ml-1"}
                                             options={cropSubSegmentState} setValue={setCropSubSegmentData}
                                             SetCallApi={SetCallApi} callApi={callApi} label={"Crop Sub Segment"}
                                             closeModal={closeModal}
                                             onOkay={onOkay}
                                             SetClearSubCropSegmentData={SetClearSubCropSegmentData}
                                             clearCropSubSegmentData={clearCropSubSegmentData}
                />


                <CustomModalSegmentSelect show={showCropSegment} setShow={setCropSegmentShow}
                                          growerValue={cropsSegmentSate}
                                          className={"ml-1"}
                                          id="CropSegmentModal"
                                          options={cropSegmentData} setValue={SetCropSegmentSate}
                                          SetCallApi={SetCallApi} callApi={callApi} label={"Crop Segment"}
                                          closeModal={closeModal}
                                          onOkay={onOkay}
                                          clearCropSegmentData={clearCropSegmentData}
                                          SetClearCobSegmentData={SetClearCobSegmentData}
                />

                <CustomModalCropSelect
                    show={showCrop}
                    setShow={setCropShow}
                    setValue={SetCropsState}
                    growerValue={cropsState}
                    className={"ml-1"}
                    id="CropModal"
                    options={cropArray} SetCropsState={SetCropsState}
                    SetCallApi={SetCallApi} callApi={callApi} label={"Crop"}
                    closeModal={closeModal}
                    onOkay={onOkay}
                />

            </main>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                closeOnClick
            />
        </Fragment>
    )
}

export default Dashboard;
