import React, {Fragment, useEffect, useState, useRef} from 'react';
import {useStatePersist} from "use-state-persist";
import {Route, Link, useLocation} from 'react-router-dom';
// @ts-ignore
import 'react-loading-skeleton/dist/skeleton.css'
import 'react-toastify/dist/ReactToastify.css';
import {useMsal} from "@azure/msal-react";
import { EditOutlined,DeleteOutlined, AddNewSettings } from "../../assets/svgs";
import {loginRequest} from "../../authConfig";
import {ACCESS_KEY, USER_KEY} from "../../constants";
import {Modal as BootstrapModal} from 'bootstrap';
import {DeleteModal} from '../../components/deleteModal';

import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AdminComponent = (props: any) => {

    const[settingID, setSettingID] = useState()

    const[confirmDeleteSettingsListData, setConfirmDeleteSettingsListData] :any= useState(false);

    const showModal = (props: any) => {
        setConfirmDeleteSettingsListData(true)
        const element = document.getElementById(`${props}`) as HTMLElement
        const myModal: any = new BootstrapModal((element), {backdrop: false})
        myModal.show()
    }

    const {fetchFormSettingsList,deleteSettingsById,cleanSettingListData,settingList,settingListErrors,settingListLoading} = props
    const {instance, accounts, logger, inProgress} = useMsal();
    useEffect(() => {
        const request = {
            ...loginRequest,
            account: accounts[0]
        };
        instance.acquireTokenSilent(request).then((response: any) => {
            let token = response.accessToken;
            localStorage.setItem(USER_KEY, token)
            localStorage.setItem(ACCESS_KEY, response.accessToken)
            fetchFormSettingsList().then((dataFromSettingList: any) => {
                if (dataFromSettingList?.error?.message) {
                    toast.error(`${dataFromSettingList?.payload}`, {});
                    if (dataFromSettingList.payload === 'Request failed with status code 401') {
                        let currentRequest = {
                            ...loginRequest,
                            account: accounts[0]
                        };

                        instance.acquireTokenRedirect(currentRequest).then((response) => {
                            fetchFormSettingsList();
                        })
                    }
                    else{
                        toast.error(`${dataFromSettingList?.payload}`, {});
                    }

                }
            })
        })
        return () => { cleanSettingListData() };
    }, []);


    const closeModal = (modalName: any, setShow: any) => {
        setShow(false)
        const element = document.getElementById(`${modalName}`) as HTMLElement
        if (element) {
            var modal = BootstrapModal.getOrCreateInstance(element)
            modal.hide()
        }
    }

    const onSuccess =(id:any,e:any)=>{
        deleteSettingsById(settingID).then((res:any)=> {
            closeModal("deleteModal",setConfirmDeleteSettingsListData)
            fetchFormSettingsList()
        }
       )
    }

    return (
        <Fragment>
            <section className="ftco-section">
                {(settingListLoading   ) ? (
                    <div className="body_loading" role="status">
                        <div className="spinner"></div>
                    </div>
                ) : (
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 text-left mb-3">
                            <h2 className="heading-section">Admin Settings</h2>
                        </div>
                        <div className="col-md-6 text-end mb-3">
                            <Link to="/Admin/add">
                            <button   className='btn btn-success btn-success_custom'><span><AddNewSettings />Add</span></button>
                            </Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="table-wrap">
                                <table className="table-admin">
                                    <thead className="thead-primary">
                                    <tr>
                                        <th>Header</th>
                                        <th>Paragraph</th>
                                        <th>Button Name</th>
                                        <th>URL</th>
                                        <th>Order</th>
                                        <th className='dataAlign'>Image Align</th>
                                        <th>Image</th>
                                        <th>Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <Fragment>
                                    {Array.isArray(settingList) && settingList.length > 0 && settingList.map((data: any, index: any) =>
                                        <tr key={index}>
                                            <td><span className={!data?.header ? "admin-noData" : ""}>{data?.header ? data?.header : "No data"}</span></td>
                                            <td><span className='dataParagraph'>{data?.paragraph}</span></td>
                                            <td><span>{data?.buttonName}</span></td>
                                            <td className='settingsData-url'><span>{data?.urlButton}</span></td>
                                            <td><span className='dataOrder'>{data?.order}</span></td>
                                            <td><span className='dataAlign'>{data?.imageAlignment}</span></td>
                                            <td className='data-image-list'><img src={data?.imageUrl}  className="img-fluid moreInformation-image"/></td>
                                            <td>
                                                <Fragment>
                                                    <div className={"d-flex row align-items-center justify-content-center"}>
                                                        <div className={"col p-0"}>
                                                            <Link to={`/Admin/edit/${data?.id}`}>
                                                                <button type="button" className='btn btn-success_custom' >
                                                                    <EditOutlined/>
                                                                </button>
                                                            </Link>
                                                        </div>
                                                        <div className={"col p-0"}>
                                                            <button type="button" className='btn btn-success btn-success_custom' 
                                                            onClick={() => {showModal('deleteModal');setSettingID(data?.id)}}>
                                                                <DeleteOutlined/>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            </td>
                                        </tr>
                                    )}

                                    {settingList.length === 0 && (
                                        // <div className={"card"} style={{height:"200px !important"}}>
                                        <Fragment>
                                            <tr>
                                                <th scope="row">{"No Data"}</th>

                                            </tr>
                                        </Fragment>
                                        // </div>
                                    )}
                                    </Fragment>
                                    </tbody>
                                </table>

                                <DeleteModal 
                                    onSuccess={onSuccess} 
                                    confirmLabel={`Do you really want to delete the item?`} 
                                    closeModal={closeModal} 
                                    id={'deleteModal'}
                                    setShow={setConfirmDeleteSettingsListData}
                                    show={confirmDeleteSettingsListData}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                )}
            </section>
        </Fragment>
    )
}

export default AdminComponent;
