import React, {Fragment, useEffect, useState, useCallback, useRef} from "react";
import {Modal as BootstrapModal} from 'bootstrap';
import {useStatePersist} from 'use-state-persist';
import SortableList from './SortableList';
import {arrayMoveImmutable} from 'array-move';
import {ACCESS_KEY, DATE_FORMAT_LIST, USER_KEY} from "../constants/index"
import {isEmpty} from "../util/commonUtils";

export const LoaderModal = (props: any) => {
    const impactRef10: any = useRef();
    const {
        handleToggleChangeMeasurement,
        show,
        setShow,

        label,
        className,
        closeModal,
        id,
        excelSliceLoading,
        selected,
    } = props;



    useEffect(() => {
        if (excelSliceLoading === false){
            closeModal(id, setShow)
        }

    }, [excelSliceLoading]);




    return (
        <div className={"modal overlay"} id={id} ref={impactRef10}>
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content" style={excelSliceLoading? {background: "transparent"} : {background: ""}}>
                    {excelSliceLoading && (
                            <div className="inner_body_loading loading_body" role="status">
                                <div className="spinner"></div>
                            </div>
                        )}
                </div>
            </div>
        </div>
    );
};

export default LoaderModal;
