import React, { Fragment, useEffect, useState, useCallback, useRef } from 'react';
import { Modal as BootstrapModal } from 'bootstrap';
import { useStatePersist } from 'use-state-persist';
import SortableList from './SortableList';
import { arrayMoveImmutable } from 'array-move';
import { ACCESS_KEY, DATE_FORMAT_LIST, USER_KEY } from '../constants/index';
import { isEmpty, filterByReference } from '../util/commonUtils';
import { DatePicker } from 'antd';

export const BulkTraitSelectionModal = (props: any) => {
  const impactRef21: any = useRef();
  const {
    handleToggleChangeMeasurement,
    show,
    setShow,
    options,
    SetCropsState,
    setValue,
    SetCallApi,
    growerValue,
    label,
    className,
    closeModal,
    id,
    onOkay,
    handleToggleChange,
    setOrderOfTrait,
    varieties,
    selectedTrials,
    traitStateSelectedMain,
    selectedYear,
    handleChangeMain,
    onChangeYear,
    dateWeekToggle,
    handleDateWeekToggleChange,
    selectedDateFormat,
    toggle,
    setReDraw,
    jRef,
    order,
    setOrder,
    selected,
    setSelected,
    setVarietiesOrder,
    setInitialValueOfOrder,
    setInitialValueOfOrder1,
    measurementType,
    setPrevTraits,
    prevTraits
  } = props;
  const [storeStateSelected, setStoreStateSelected]: any = useState([]);
  // const [selected, setSelected]: any = useState([])
  const [initalGrowerValue, setInitialGrowerValue]: any = useState([]);
  const [query, setQuery]: any = useState();
  const [cropSearchName, setCropSearchName] = useState('');
  let cropOptionsFiltered: any[] = [];
  const [currentStep, setCurrentStep] = useState(1);

  function disabledDate(current: any) {
    // Disable dates after the current year
    return current && current.year() > new Date().getFullYear();
  }

  useEffect(() => {
    const selectedArrayFiltered =
      prevTraits?.length > 0 ? filterByReference(options, prevTraits) : [];
    setCurrentStep(1);
    setCropSearchName('');
    setQuery(options);
    if (selectedArrayFiltered.length > 0) {
      let checked = selectedArrayFiltered.map((selectedArrayFilteredItem: any) => ({
        ...selectedArrayFilteredItem,
        selected: true
      }));
      setStoreStateSelected(checked);
      setSelected(checked);
      setValue(prevTraits);
    } else {
      setSelected([]);
      setStoreStateSelected([]);
      setValue(prevTraits);
    }

    // setInitialGrowerValue(growerValue)
    // return () => {
    //     setStoreStateSelected(selected)
    //     setInitialGrowerValue(growerValue)
    // };
  }, [show == true]);

  const selectedChanges = (data: any) => {
    var checkIfDataExist: any = selected.filter(
      (item: any, pos: any) => item.traitID === data?.traitID && item.selected === true
    );
    if (checkIfDataExist.length > 0) {
      let dataFromClick: any = [data];
      var mergeValue: any = dataFromClick.concat(growerValue);
      var filterValue: any = mergeValue.filter(
        (item: any, pos: any) => mergeValue.indexOf(item) === pos
      );

      var checkDuplicates: any = filterValue.filter(
        (item: any, pos: any) =>
          item.traitID.toString() !== checkIfDataExist?.[0]?.traitID.toString()
      );
      var settingSelected = checkDuplicates.map((item: any) => ({
        ...item,
        selected: true
      }));
      setSelected(settingSelected);
      SetCropsState(checkDuplicates);
    } else {
      let dataFromClick: any = [data];
      var c: any = dataFromClick.concat(growerValue);
      var d: any = c.filter((item: any, pos: any) => c.indexOf(item) === pos);
      var settingSelected = d.map((item: any) => ({
        ...item,
        selected: true
      }));
      setSelected(settingSelected);
      SetCropsState(d);
    }
  };

  const checkText = (items: any) => {
    let value = false;
    let checkedArray: any = [];
    checkedArray =
      selected.length > 0 &&
      selected.filter((item: any, pos: any) => item?.traitID == items && item?.selected === true);
    value = checkedArray.length > 0 ? true : false;
    return value;
  };

  const onSearch = (event: any) => {
    event.preventDefault();
    const searchString = event.currentTarget.value.trim().toLowerCase();
    if (searchString.length > 0) {
      // We are searching. Filter the results.
      const optionsFiltered = options.filter((e: any) =>
        e.traitName.toLowerCase().match(searchString)
      );
      if (optionsFiltered.length > 0) {
        cropOptionsFiltered = optionsFiltered;
        setQuery(cropOptionsFiltered);
      } else {
        // if not found were sending empty
        cropOptionsFiltered = [];
        setQuery(cropOptionsFiltered);
      }
    } else {
      // if not found search key were reseting it
      cropOptionsFiltered = options;
      setQuery(cropOptionsFiltered);
    }
  };

  const handleNext = async (id: any, setShow: any, selected: any, currentStep: any) => {
    if (currentStep === 1) {
      const addOrder = await filterByReference(options, selected)?.map(
        (selections: any, index: any) => ({
          ...selections,
          traitID: selections?.traitID,
          columnLabel: selections?.columnLabel,
          selected: true,
          sortOrder: index
        })
      );
      setPrevTraits(addOrder);
      setOrderOfTrait([]);
      setOrder(addOrder);

      setCurrentStep(currentStep + 1);
    } else if (currentStep === 2) {
      const getVarieties = varieties?.Varieties;
      const TableID =
        (await getVarieties.length) > 0 &&
        getVarieties
          .map((getVarietiesGroup: any, getVarietiesIndex: any) =>
            order.map((nef: any, indexOfData: any) => {
              return {
                ...nef,
                groupOfVaritey: getVarietiesGroup?.TrialLineId
              };
            })
          )
          .flat()
          .map((flattenedData: any, indexOfFlattenedData: any) => {
            return {
              ...flattenedData,
              columnPos: indexOfFlattenedData + 1
            };
          });
      // if (jRef?.current?.jexcel) {
      //     jRef?.current?.jexcel?.destroy()
      // }

      setInitialValueOfOrder([]);
      setVarietiesOrder([]);
      setInitialValueOfOrder1([]);
      await setOrderOfTrait(TableID);
      setReDraw(true);
      closeModal(id, setShow);
    }
  };

  const handlePrevious = (currentStep: number) => {
    setCurrentStep(currentStep - 1);
  };

  const onSortEnd = async ({ oldIndex, newIndex }: any) => {
    await setOrder((prevItem: any) => arrayMoveImmutable(prevItem, oldIndex, newIndex));
  };

  return (
    <div
      className={currentStep === 1 ? 'modal overlay' : 'orderData  modal overlay'}
      id={id}
      ref={impactRef21}>
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title"> {currentStep === 1 ? `Select Trait(s)` : `Re Order`}</h5>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={(evt) => {
                if (currentStep === 1) {
                  setSelected(prevTraits);
                  setValue(prevTraits);
                }
                closeModal(id, setShow);
              }}></button>
          </div>
          <div className="modal-body">
            {currentStep === 1 && (
              <Fragment>
                <div className="measurement-standards mb-4">
                  <div className="row">
                    <div className="col-md-12 d-flex align-items-center justify-content-start">
                      {/*<select name="selectCrops" id="selectCrops"*/}
                      {/*        className='form-select form-select-date me-3'*/}
                      {/*        value={selectedDateFormat} onChange={handleChangeMain}*/}
                      {/*        disabled={isEmpty(selectedTrials) || selected.length === 0 ? true : false}*/}
                      {/*>*/}

                      <div className="form-check-switch float-end" style={{ paddingRight: '16px' }}>
                        <label className="switch">
                          <input
                            type="checkbox"
                            id="togBtn"
                            onChange={handleToggleChange}
                            checked={toggle ? true : false}
                          />
                          <div className="slider round">
                            <span>Metric</span>
                            <span>Imperial</span>
                          </div>
                          <div className="slider round">
                            <span>Metric</span>
                            <span>Imperial</span>
                          </div>
                        </label>
                      </div>

                      <div className="form-check-switch">
                        <label className="switch">
                          <input
                            type="checkbox"
                            id="switchMeasurement"
                            onChange={handleToggleChangeMeasurement}
                            checked={measurementType ? true : false}
                          />
                          <div className="slider">
                            <span>Comma</span>
                            <span>Point</span>
                          </div>
                          <div className="slider">
                            <span>Comma</span>
                            <span>Point</span>
                          </div>
                        </label>
                      </div>

                      <div className="form-check-switch mx-3">
                        <label className="switch">
                          <input
                            type="checkbox"
                            id="dateWeektogBtn"
                            checked={dateWeekToggle ? true : false}
                            onChange={handleDateWeekToggleChange}
                            //   disabled={orderOfTrait.length > 0 ? true : false}
                          />
                          <div className="slider">
                            <span>Date</span>
                            <span>Week</span>
                          </div>
                          <div className="slider">
                            <span>Date</span>
                            <span>Week</span>
                          </div>
                        </label>
                      </div>

                      <select
                        name="selectCrops"
                        id="selectCrops"
                        className="form-select form-select-date"
                        style={{ fontSize: '14px' }}
                        value={selectedDateFormat}
                        onChange={handleChangeMain}>
                        <option value="" disabled>
                          Select DateFormat
                        </option>
                        {DATE_FORMAT_LIST.map((option) => {
                          return (
                            <option value={option.value} key={option.value}>
                              {option.label}
                            </option>
                          );
                        })}
                      </select>
                      {dateWeekToggle && (
                        <DatePicker
                          onChange={onChangeYear}
                          picker="year"
                          className=" mx-3"
                          placeholder="Select Year"
                          style={{ borderRadius: '5px' }}
                          value={selectedYear}
                          disabledDate={disabledDate}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </Fragment>
            )}

            <div className="col-md-6 d-flex align-items-center">
              <form className="nosubmit">
                <input
                  name="search"
                  placeholder={`Search`}
                  onKeyUp={(evt) => {
                    if (evt?.code !== 'Enter') {
                      onSearch(evt);
                    }
                  }}
                  value={cropSearchName}
                  onInput={(event) => {
                    const element = event.currentTarget as HTMLInputElement;
                    setCropSearchName(element.value);
                  }}
                />
              </form>
            </div>

            {currentStep === 1 ? (
              <Fragment>
                <div className="row">
                  <Fragment>
                    {varieties?.Varieties?.length === 0 && (
                      <div className="col-md-24">
                        <p className="alert alert-danger alert-text">
                          The selected Trial has no variety assigned to it
                        </p>
                      </div>
                    )}
                    {Array.isArray(query) &&
                      query.length > 0 &&
                      query?.map((data: any, index: any) => {
                        return (
                          <Fragment key={index}>
                            <div key={data.traitID} className="col-md-4">
                              <div
                                className="form-check trait-item"
                                onClick={() => selectedChanges(data)}
                                style={{
                                  listStyleType: 'none',
                                  visibility: 'inherit',
                                  opacity: '1'
                                }}>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name={'flexCheckCheckedDisabled'}
                                  id={index}
                                  checked={checkText(data.traitID)}
                                  disabled
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheckCheckedDisabled">
                                  {data?.traitName}
                                  {toggle &&
                                    data?.uoMCodeImperial !== null &&
                                    `(${data?.uoMCodeImperial})`}
                                  {toggle === false &&
                                    data?.uomCode !== null &&
                                    `(${data?.uomCode})`}
                                </label>
                              </div>
                            </div>
                          </Fragment>
                        );
                      })}
                  </Fragment>
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <div id="container" className="sorting-items">
                  <SortableList
                    items={order}
                    toggle={toggle}
                    onSortEnd={onSortEnd}
                    lockAxis={'y'}
                    helperClass={'ca'}
                  />
                </div>
              </Fragment>
            )}
          </div>
          <div className="modal-footer">
            {currentStep !== 1 && (
              <button
                type="button"
                className="btn btn-success btn-back btn-success_custom btn-outline"
                onClick={(e) => handlePrevious(currentStep)}>
                Back
              </button>
            )}
            <button
              type="button"
              className="btn btn-success btn-success_custom float-end"
              onClick={(evt) => {
                handleNext(id, setShow, selected, currentStep);
              }}
              disabled={
                currentStep === 1 &&
                (filterByReference(options, selected).length === 0 ||
                  varieties?.Varieties?.length === 0)
                  ? true
                  : false
              }>
              {currentStep === 2 ? `Submit` : `Proceed`}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BulkTraitSelectionModal;
