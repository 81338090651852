import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {v2Fetch, v2Fetch3001, v2Post} from "../../util/httpUtil";


const initialState: any = {
    payload: [],
    error: undefined,
    loading: false,
};

export const getVarieties = createAsyncThunk(
    'varieties/fetch',
    (formData: any, {rejectWithValue}) => {
        return v2Fetch(`v1/trials/trialVarieties?ezid=${formData?.ezid}&cropCode=${formData?.cropCode}`, {})
            .then((response: any) => {
                if (response.status === 200) {
                    return Promise.resolve(response?.data);
                } else {
                    // TODO
                }
            })
            .catch((error) => {
                const errorThrown = JSON.parse(error)
                return rejectWithValue(errorThrown?.message)

            });
    }
);


export const varietiesSlice = createSlice({
    name: "varieties/fetch",
    initialState,
    reducers: {
        cleanTrialVarietiesData(state) {
            state.loading = false;
            state.payload = [];
            state.error = {};
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getVarieties.pending, (state, action) => {
            state.loading = true;
            state.error = {};
            state.payload = [];
        });


        builder.addCase(getVarieties.fulfilled, (state, action) => {
            state.loading = false;
            state.payload = action.payload;
        });
        builder.addCase(getVarieties.rejected, (state, action) => {
            state.loading = false;
            state.payload = [];
            state.error = action.payload;
        });
    },
});


export const getTraitList = createAsyncThunk(
    'traitListSlice/fetch',
    (formData: any, {rejectWithValue}) => {
        return v2Fetch3001(`v1/traitsbyfieldset?fieldsetId=${formData?.DefaultTraitSetID}&cropCode=${formData?.cropCode}`, {})

            .then((response: any) => {
                if (response.status === 200) {
                    return Promise.resolve(response?.data);
                } else {
                    // TODO
                }
            })
            .catch((error) => {
                const errorThrown = JSON.parse(error)
                return rejectWithValue(errorThrown?.message)

            });
    }
);

export const traitListSlice = createSlice({
    name: "traitListSlice",
    initialState,
    reducers: {
        cleanTraitListData(state) {
            state.loading = false;
            state.payload = [];
            state.error = {};
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getTraitList.pending, (state, action) => {
            state.loading = true;
            state.error = {};
            state.payload = [];
        });


        builder.addCase(getTraitList.fulfilled, (state, action) => {
            state.loading = false;
            state.payload = action.payload;
        });
        builder.addCase(getTraitList.rejected, (state, action) => {
            state.loading = false;
            state.payload = [];
            state.error = action.payload;
        });
    },
});


export const getTrialList = createAsyncThunk(
    'trialListSlice/fetch',
    (formData: any, {rejectWithValue}) => {
        return v2Fetch(`v1/trialsByStatus/${formData?.cropCode}`, {})
            .then((response: any) => {
                if (response.status === 200) {
                    return Promise.resolve(response?.data);
                } else {
                    // TODO
                }
            })
            .catch((error) => {
                const errorThrown = JSON.parse(error)
                return rejectWithValue(errorThrown?.message)

            });
    }
);


export const trailListSlice = createSlice({
    name: "trialListSlice",
    initialState,
    reducers: {
        cleanTrialListData(state) {
            state.loading = false;
            state.payload = [];
            state.error = {};
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getTrialList.pending, (state, action) => {
            state.loading = true;
            state.error = {};
            state.payload = [];
        });


        builder.addCase(getTrialList.fulfilled, (state, action) => {
            state.loading = false;
            state.payload = action.payload;
        });
        builder.addCase(getTrialList.rejected, (state, action) => {
            state.loading = false;
            state.payload = [];
            state.error = action.payload;
        });
    },
});


export const getCropList = createAsyncThunk(
    'cropListSlice/fetch',
    (formData: any, {rejectWithValue}) => {
        return v2Fetch("v1/crops", formData)
            .then((response: any) => {
                if (response.status === 200) {
                    return Promise.resolve(response?.data);
                } else {
                    // TODO
                }
            })
            .catch((error) => {
                const errorThrown = JSON.parse(error)
                return rejectWithValue(errorThrown?.message)

            });
    }
);


export const cropListSlice = createSlice({
    name: "cropListSlice",
    initialState,
    reducers: {
        cleanCropListData(state) {
            state.loading = false;
            state.payload = [];
            state.error = {};
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCropList.pending, (state, action) => {
            state.loading = true;
            state.error = {};
            state.payload = [];
        });


        builder.addCase(getCropList.fulfilled, (state, action) => {
            state.loading = false;
            state.payload = action.payload;
        });
        builder.addCase(getCropList.rejected, (state, action) => {
            state.loading = false;
            state.payload = [];
            state.error = action.payload;
        });
    },
});


export const submitExcelData = createAsyncThunk(
    'excel/post',
    (formData: any, {rejectWithValue}) => {
        return v2Post("v1/bulkobservations", formData)
            .then((response: any) => {
                if (response.status === 200) {
                    return Promise.resolve(response?.data);
                } else {
                    // TODO
                }
            })
            .catch((error) => {
                const errorThrown = JSON.parse(error)
                return rejectWithValue(errorThrown?.message)
            });
    }
);


export const excelSlice = createSlice({
    name: "excel",
    initialState,
    reducers: {
        cleanTrialData(state) {
            state.loading = false;
            state.payload = [];
            state.error = {};
        },
    },
    extraReducers: (builder) => {
        builder.addCase(submitExcelData.pending, (state, action) => {
            state.loading = true;
        });


        builder.addCase(submitExcelData.fulfilled, (state, action) => {
            state.loading = false;
            state.payload = action.payload;
        });
        builder.addCase(submitExcelData.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
        });
    },
});


export const {cleanTraitListData} = traitListSlice.actions;
export const {cleanTrialVarietiesData} = varietiesSlice.actions;
export const {cleanCropListData} = cropListSlice.actions;
