import React, {Fragment, useState, useRef, useEffect} from "react";
import { useStatePersist  } from 'use-state-persist';

import {Modal as BootstrapModal} from "bootstrap";

export const CustomModalCountrySelect = (props: any) => {
    const impactRef3: any = useRef();
    const {show, setShow, options, setValue, SetCallApi, className, countryValue, label, closeModal, id, onOkay} = props;
    const [selected, setSelected]: any = useStatePersist('selectedCountry',[])
    const [storeStateSelected, setStoreStateSelected]: any = useStatePersist('storeStateSelectedCountry',[])
    const [initalValue, setInitialValue]: any = useStatePersist('initialValueCountry',[])
    const [cropCountryQuery, setCropCountryQuery]: any = useState(props?.options);
    const [cropCountrySearchName, setCropCountrySearchName] = useState('');
    let cropCountryOptionsFiltered: any[] = []
    useEffect(() => {
        setCropCountrySearchName('')
        setCropCountryQuery(options)
        setStoreStateSelected(selected)
        setInitialValue(countryValue)
        return () => {
            setStoreStateSelected(selected)
            setInitialValue(countryValue)
        };
    }, [show == true]);


    useEffect(() => {
        document.addEventListener("click", handleClickOutside, false);
        return () => {
            document.removeEventListener("click", handleClickOutside, false);
        };
    }, [show == true]);

    const handleClickOutside = async (event: any) => {

        if (impactRef3.current.id && event.target.id && show == true) {
            var myModalEl = document.getElementById(`${id}`) as HTMLElement
            var modal = BootstrapModal.getInstance(myModalEl)
            const here: any = await storeStateSelected
            const here2: any = await initalValue
            if (here.length > 0) {
                setSelected(here);
            } else {
                setSelected([]);
            }
            if (here.length > 0) {
                setValue(here2);
            } else {
                setValue([]);
            }
            if (modal) {
                setShow(false)
                modal.hide()
            }
        }


    };


    const selectedChanges = (data: any) => {
        var checkIfDataExist: any = selected.filter((item: any, pos: any) => item.value === data?.value && item.selected === true)
        if (checkIfDataExist.length > 0) {
            let dataFromClick: any = [data]
            var mergeValue: any = dataFromClick.concat(countryValue)
            var filterValue: any = mergeValue.filter((item: any, pos: any) => mergeValue.indexOf(item) === pos)

            var checkDuplicates: any = filterValue.filter((item: any, pos: any) => item.value.toString() !== checkIfDataExist?.[0]?.value.toString())
            var settingSelected = checkDuplicates.map((item: any) => ({
                ...item,
                selected: true
            }));
            setSelected(settingSelected)
            setValue(checkDuplicates)
            // SetCallApi(true);
        } else {
            let dataFromClick: any = [data]
            var c: any = dataFromClick.concat(countryValue)
            var d: any = c.filter((item: any, pos: any) => c.indexOf(item) === pos)
            var settingSelected = d.map((item: any) => ({
                ...item,
                selected: true
            }));
            setSelected(settingSelected)
            setValue(d)
            // SetCallApi(true);
        }

    }

    const checkText = (items: any) => {
        let value = false
        let checkedArray: any = []
        checkedArray = selected.length > 0 && selected.filter((item: any, pos: any) => item?.value == items && item?.selected === true)
        value = checkedArray.length > 0 ? true : false
        return value
    }


    const onSearch = (event: any) => {
        event.preventDefault();
        const searchString = event.currentTarget.value.trim().toLowerCase();

        if (searchString.length > 0) {
            // We are searching. Filter the results.
            const optionsFiltered = options.filter((e: any) => e.label.toLowerCase().match(searchString));
            if (optionsFiltered.length > 0) {
                cropCountryOptionsFiltered = optionsFiltered
                setCropCountryQuery(cropCountryOptionsFiltered);

            } else {
                // if not found were sending empty
                cropCountryOptionsFiltered = [];
                setCropCountryQuery(cropCountryOptionsFiltered);
            }
        } else {
            // if not found search key were reseting it
            cropCountryOptionsFiltered = options
            setCropCountryQuery(cropCountryOptionsFiltered);
        }
    }

    return (

        <Fragment>
            <div className="modal overlay" id={id} tabIndex={3} ref={impactRef3}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title"> {`Please Select ${label}`}</h5>
                            <button type="button" className="btn-close" aria-label="Close" onClick={(evt) => {
                                closeModal(id, setShow), setSelected(storeStateSelected), setValue(initalValue)
                            }}></button>
                        </div>
                        <div className="modal-body">
                            <input name="search" placeholder={`Search`} onKeyUp={(evt) => {
                                if (evt?.code !== "Enter") {
                                    onSearch(evt)
                                }
                            }}
                                   value={cropCountrySearchName}

                                   onInput={(event) =>{
                                       const element = event.currentTarget as HTMLInputElement;
                                       setCropCountrySearchName(element.value)}}
                            />
                            {Array.isArray(cropCountryQuery) && cropCountryQuery.length > 0 && cropCountryQuery.map((data: any, index: any) =>
                                <li  key={data.value} onClick={() => selectedChanges(data)}
                                    style={{"listStyleType": "none"}} className="list-item">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="countryCheck" id={index}
                                               checked={checkText(data.value)} disabled/>
                                        <label className="form-check-label" htmlFor="countryCheck">
                                            {data?.label}
                                        </label>
                                    </div>
                                </li>
                            )}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={(evt) => {
                                closeModal(id, setShow), setSelected(storeStateSelected), setValue(initalValue)
                            }}>Cancel
                            </button>
                            <button type="button" className="btn btn-primary" onClick={(evt) => {
                                onOkay(id, setShow)
                            }}>Save changes
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default CustomModalCountrySelect;
