import React, { Fragment, useEffect, useState, useCallback, useRef } from 'react';
import SortableList from './SortableList';
import { arrayMoveImmutable } from 'array-move';
import { useStatePersist } from 'use-state-persist';

export const SortTableOrderModal = (props: any) => {
  const {
    id,
    closeModal,
    setShow,
    varieties,
    show,
    handleOrdering,
    varietiesOrder,
    setVarietiesOrder,
    orderOfTrait,
    jRef,
    initialValueOfOrder,
    initialValueOfOrder1,
    setInitialValueOfOrder,
    setInitialValueOfOrder1
  } = props;

  useEffect(() => {}, []);

  useEffect(() => {
    if ((varietiesOrder?.length === 0 || varietiesOrder === undefined) && show === true) {
      setVarietiesOrder(varieties);
      const observations: any = varieties?.map((varietyItem: any) => ({
        ezid: varietyItem?.TrialLineId,
        traits: orderOfTrait
          .map((orderedData: any, indexOfData: any) => {
            if (orderedData?.groupOfVaritey === varietyItem?.TrialLineId) {
              return {
                ...orderedData,
                traitId: orderedData?.traitID,
                listOfValuesData: orderedData?.values,
                // values: Orderof
                values: jRef?.current?.jexcel?.getColumnData(orderedData?.columnPos)
              };
            }
          })
          .filter((isArrayUndefined: any) => isArrayUndefined !== undefined)
      }));
      if (Array.isArray(observations) && observations.length > 0) {
        setInitialValueOfOrder(observations);
      }
    }
    if (varietiesOrder?.length > 0 && show === true) {
      if (varietiesOrder?.length > 0) {
        const observations: any = varietiesOrder?.map((varietyItem: any) => ({
          ezid: varietyItem?.TrialLineId,
          traits: initialValueOfOrder1
            .map((orderedData: any, indexOfData: any) => {
              if (orderedData?.groupOfVaritey === varietyItem?.TrialLineId) {
                return {
                  ...orderedData,
                  traitId: orderedData?.traitID,
                  // values: Orderof
                  values: jRef?.current?.jexcel?.getColumnData(indexOfData + 1)
                };
              }
            })
            .filter((isArrayUndefined: any) => isArrayUndefined !== undefined)
        }));
        if (Array.isArray(observations) && observations.length > 0) {
          setInitialValueOfOrder(observations);
        }
      }
    }

    // if(varietiesOrder.length > 0 && show === true){
    //     const observations :any =  varietiesOrder?.map((varietyItem: any) => (
    //         console.log(varietyItem,'cccc'),
    //         {
    //             ezid: varietyItem?.EZID,
    //             traits: orderOfTrait.map((orderedData: any, indexOfData: any) => {
    //                 if (orderedData?.groupOfVaritey === varietyItem?.EZID) {
    //                     return {
    //                         ...orderedData,
    //                         traitId: orderedData?.traitID,
    //                         // values: Orderof
    //                         values: jRef?.current?.jexcel?.getColumnData(orderedData?.columnPos)
    //                     }
    //                 }
    //             }).filter((isArrayUndefined: any) => isArrayUndefined !== undefined),
    //         }));
    //     if (Array.isArray(observations) && observations.length > 0){
    //         setInitialValueOfOrder(observations)
    //     }
    //
    // }
  }, [show === true]);

  const onSortEnd = async ({ oldIndex, newIndex }: any) => {
    await setVarietiesOrder((prevItem: any) => arrayMoveImmutable(prevItem, oldIndex, newIndex));
  };
  const handleNext = async (id: any, setShow: any) => {
    let returnedValue = varietiesOrder.map((varietiesOrderedData: any, index: number) => {
      return initialValueOfOrder
        .map((initialValueOfOrder: any, initialValueIndex: number) => {
          if (varietiesOrderedData?.TrialLineId === initialValueOfOrder?.ezid) {
            return {
              ...initialValueOfOrder
            };

            // returnedHim = person;
          }
        })
        .filter((isArrayUndefined: any) => isArrayUndefined !== undefined);
    });
    await setInitialValueOfOrder(returnedValue);
    handleOrdering(id, setShow, returnedValue);
  };
  return (
    <div className="modal overlay" id={id}>
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title"> {`Re Order`}</h5>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={(evt) => {
                closeModal(id, setShow);
              }}></button>
          </div>
          <div className="modal-body">
            <Fragment>
              <div id="container" className="sorting-items">
                <SortableList
                  items={varietiesOrder}
                  onSortEnd={onSortEnd}
                  lockAxis={'y'}
                  helperClass={'ca'}
                />
              </div>
            </Fragment>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-success btn-success_custom float-end"
              onClick={(evt) => {
                handleNext(id, setShow);
              }}>
              {`Submit`}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SortTableOrderModal;
