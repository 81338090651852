import React from 'react';
import { SortableElement } from 'react-sortable-hoc';

const SortableItem = (props:any) => {
    return <li style={{ "listStyleType": "none"}} className="list-item">{props.value}{props?.toggle && props?.metric
        ?.uoMCodeImperial !== null &&  `(${props?.metric?.uoMCodeImperial})`}{props?.toggle === false && props?.metric
        ?.uomCode !== null &&  `(${props?.metric
        ?.uomCode})`}</li>;
};
export default SortableElement(SortableItem);
