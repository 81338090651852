import React from 'react';
// Import custom components
import ElearningComponent from '../Elearning';

import { useAppDispatch ,useAppSelector} from "../../../store/reduxHooks";
import * as eLearningSlice from "../slice";



class RejectWithValue<T, U> {
}

const AdminContainer= (props:any) => {
    const dispatch = useAppDispatch();
    //settingList
    const settingList = useAppSelector((state) => state?.FormSettingsList?.payload);
    const settingListErrors = useAppSelector((state) => state?.FormSettingsList?.error);
    const settingListLoading = useAppSelector((state) => state?.FormSettingsList?.loading);





    props = { ...props,settingList,settingListErrors,settingListLoading}
    // /**
    //  * get data for dropdown crops records.
    //  * @param {object} formData
    //  *
    //  */

    const fetchFormSettingsList = (formData:any) => {
        return dispatch(eLearningSlice.fetchFormSettingsList(formData));
    };




    const cleanFormSettingListData = () => {
        return dispatch(eLearningSlice.cleanFormSettingListData());
    };


    return (
        <ElearningComponent
            cleanFormSettingListData={cleanFormSettingListData}
            fetchFormSettingsList={fetchFormSettingsList}
            {...props}
        />


    );
};
export default AdminContainer;
