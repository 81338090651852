import React from 'react';
// Import custom components
import BulkInsertComponent from '../BulkInsert';
import {useAppDispatch, useAppSelector} from "../../../store/reduxHooks";
import * as bulkInsertSlice from "../slice";


class RejectWithValue<T, U> {
}

const BulkInsertContainer = (props: any) => {
    const dispatch = useAppDispatch();
    //cropList
    const cropListData = useAppSelector((state) => state?.cropListReducer?.payload);
    const cropListDataErrors = useAppSelector((state) => state?.cropListReducer?.error);
    const cropListDataLoading = useAppSelector((state) => state?.cropListReducer?.loading);


    //trialsList
    const trialListData = useAppSelector((state) => state?.trialListReducer?.payload);
    const trialListDataErrors = useAppSelector((state) => state?.trialListReducer?.error);
    const trailListDataLoading = useAppSelector((state) => state?.trialListReducer?.loading);

    //varieties
    const varieties = useAppSelector((state) => state?.varietiesReducer?.payload);
    const varietyError = useAppSelector((state) => state?.varietiesReducer?.error);
    const varietyLoading = useAppSelector((state) => state?.varietiesReducer?.loading);

    //traitsList
    const traitListData = useAppSelector((state) => state?.traitListReducer?.payload);
    const traitListDataErrors = useAppSelector((state) => state?.traitListReducer?.error);
    const traitListDataLoading = useAppSelector((state) => state?.traitListReducer?.loading);

    const excelSliceLoading = useAppSelector((state) => state?.excelDataReducer?.loading);

    props = {
        ...props,
        excelSliceLoading,
        varieties,
        varietyError,
        varietyLoading,
        cropListData,
        cropListDataErrors,
        cropListDataLoading,
        trialListData,
        trialListDataErrors,
        trailListDataLoading,
        traitListData,
        traitListDataErrors,
        traitListDataLoading
    }
    // /**
    //  * get data for dropdown crops records.
    //  * @param {object} formData
    //  *
    //  */
    const getCropList = (formData: any) => {
        return dispatch(bulkInsertSlice.getCropList(formData));
    };


    const getTrialList = (formData: any) => {
        return dispatch(bulkInsertSlice.getTrialList(formData));
    };


    const getTraitList = (formData: any) => {
        return dispatch(bulkInsertSlice.getTraitList(formData));
    };

    const getVarieties = (formData: any) => {
        return dispatch(bulkInsertSlice.getVarieties(formData));
    };
    const submitExcelData = (formData: any) => {
        return dispatch(bulkInsertSlice.submitExcelData(formData));
    };

    const cleanTraitListData = () => {
        return dispatch(bulkInsertSlice.cleanTraitListData());
    };

    const cleanTrialVarietiesData = () => {
        return dispatch(bulkInsertSlice.cleanTrialVarietiesData());
    };

    return (
        <BulkInsertComponent
            cleanTrialVarietiesData={cleanTrialVarietiesData}
            submitExcelData={submitExcelData}
            cleanTraitListData={cleanTraitListData}
            getCropList={getCropList}
            getTrialList={getTrialList}
            getTraitList={getTraitList}
            getVarieties={getVarieties}
            {...props}
        />


    );
};
export default BulkInsertContainer;
