import React from 'react';
// Import custom components
import AdminComponent from '../Admin';

import { useAppDispatch ,useAppSelector} from "../../../store/reduxHooks";
import * as adminSlice from "../slice";



class RejectWithValue<T, U> {
}

const AdminContainer= (props:any) => {
    const dispatch = useAppDispatch();
    //settingList
    const settingList = useAppSelector((state) => state?.settingListReducer?.payload);
    const settingListErrors = useAppSelector((state) => state?.settingListReducer?.error);
    const settingListLoading = useAppSelector((state) => state?.settingListReducer?.loading);





    props = { ...props,settingList,settingListErrors,settingListLoading}
    // /**
    //  * get data for dropdown crops records.
    //  * @param {object} formData
    //  *
    //  */

    const fetchFormSettingsList = (formData:any) => {
        return dispatch(adminSlice.fetchFormSettingsList(formData));
    };


  const deleteSettingsById = (formData:any) => {
        return dispatch(adminSlice.deleteSettingsById(formData));
    };


    const cleanSettingListData = () => {
        return dispatch(adminSlice.cleanSettingListData());
    };


    return (
        <AdminComponent
            deleteSettingsById={deleteSettingsById}
            cleanSettingListData={cleanSettingListData}
            fetchFormSettingsList={fetchFormSettingsList}
            {...props}
    />


);
};
export default AdminContainer;
