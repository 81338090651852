import React, {Fragment, useState, useRef, useEffect} from "react";
import {Modal as BootstrapModal} from "bootstrap";
import { useStatePersist  } from 'use-state-persist';

export const CustomModalGrowerSelect = (props: any) => {
    const impactRef5: any = useRef();
    const {
        show,
        setShow,
        options,
        setValue,
        growerValue,
        label,
        closeModal,
        id,
        onOkay,
        SetClearGrowerData,
        clearGrowerData
    } = props;
    const [selectedGrower, setSelectedGrower]: any = useStatePersist('selectedGrower',[])
    const [storeStateSelected, setStoreStateSelected]: any = useStatePersist('storeStateSelectedGrower',[])
    const [initialValue, setInitialValue]: any = useStatePersist('initialValueForGrower',[])
    const [cropGrowerQuery, setCropGrowerQuery]: any = useState(props?.options);
    const [cropGrowerSearchName, setCropGrowerSearchName] = useState('');
    let cropGrowerOptionsFiltered: any[] = []
    useEffect(() => {
        setCropGrowerSearchName('')
        setCropGrowerQuery(options)
        if (clearGrowerData === true)  {
            setSelectedGrower([])
            setStoreStateSelected([])
            setInitialValue([])
        } else {
            setStoreStateSelected(selectedGrower)
            setInitialValue(growerValue)
        }


        return () => {
            if (clearGrowerData === true) {
                setSelectedGrower([])
                setStoreStateSelected([])
                setInitialValue([])
            } else {
                setStoreStateSelected(selectedGrower)
                setInitialValue(growerValue)
            }
        };
    }, [show == true]);


    useEffect(() => {
        document.addEventListener("click", handleClickOutside, false);
        return () => {
            document.removeEventListener("click", handleClickOutside, false);
        };
    }, [show == true]);

    const handleClickOutside = async (event: any) => {

        if (impactRef5.current.id && event.target.id && show == true) {
            var myModalEl = document.getElementById(`${id}`) as HTMLElement
            var modal = BootstrapModal.getInstance(myModalEl)
            const here: any = await  storeStateSelected.filter((c: any) => options.some((g: any) => g.value === c.value && g.propertyID === c.propertyID)) || [];
            const here2: any = await initialValue.filter((c: any) => options.some((g: any) => g.value === c.value && g.propertyID === c.propertyID)) || [];
            if (clearGrowerData === false) {
                if (here.length > 0) {
                    setSelectedGrower(here);
                } else {
                    setSelectedGrower([]);
                }
                if (here.length > 0) {
                    setValue(here2);
                } else {
                    setValue([]);
                }
            } else {
                setSelectedGrower([]);
                setValue([]);
            }
            if (modal) {
                setShow(false)
                modal.hide()
            }
        }


    };


    const selectedChanges = (data: any) => {
        SetClearGrowerData(false)
        var checkIfDataExist: any = selectedGrower.filter((item: any, pos: any) => item.value === data?.value && item.selected === true)
        if (checkIfDataExist.length > 0) {
            let dataFromClick: any = [data]
            var mergeValue: any = dataFromClick.concat(growerValue)
            var filterValue: any = mergeValue.filter((item: any, pos: any) => mergeValue.indexOf(item) === pos)

            var checkDuplicates: any = filterValue.filter((item: any, pos: any) => item.value.toString() !== checkIfDataExist?.[0]?.value.toString())
            var settingSelected = checkDuplicates.map((item: any) => ({
                ...item,
                selected: true
            }));
            setSelectedGrower(settingSelected)
            setValue(checkDuplicates)
        } else {
            let dataFromClick: any = [data]
            var c: any = dataFromClick.concat(growerValue)
            var d: any = c.filter((item: any, pos: any) => c.indexOf(item) === pos)
            var settingSelected = d.map((item: any) => ({
                ...item,
                selected: true
            }));
            setSelectedGrower(settingSelected)
            setValue(d)
        }

    }

    const checkText = (items: any) => {
        let value = false
        let checkedArray: any = []
        checkedArray = selectedGrower.length > 0 && selectedGrower.filter((item: any, pos: any) => item?.value == items && item?.selected === true)
        value = checkedArray.length > 0 ? true : false
        return value
    }


    const onSearch = (event: any) => {
        event.preventDefault();
        const searchString = event.currentTarget.value.trim().toLowerCase();
        if (searchString.length > 0) {
            // We are searching. Filter the results.
            const optionsFiltered = options.filter((e: any) => e.label.toLowerCase().match(searchString));
            if (optionsFiltered.length > 0) {
                cropGrowerOptionsFiltered = optionsFiltered
                setCropGrowerQuery(cropGrowerOptionsFiltered);

            } else {
                // if not found were sending empty
                cropGrowerOptionsFiltered = [];
                setCropGrowerQuery(cropGrowerOptionsFiltered);
            }
        } else {
            // if not found search key were reseting it
            cropGrowerOptionsFiltered = options
            setCropGrowerQuery(cropGrowerOptionsFiltered);
        }
    }
    return (
        <Fragment>
            <div className="modal overlay" id={id} tabIndex={3} ref={impactRef5}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title"> {`Please Select ${label}`}</h5>
                            <button type="button" className="btn-close" aria-label="Close" onClick={(evt) => {
                                closeModal(id, setShow), setSelectedGrower(storeStateSelected), setValue(initialValue)
                            }}></button>
                        </div>
                        <div className="modal-body">
                            <input name="search" placeholder={`Search`} onKeyUp={(evt) => {
                                if (evt?.code !== "Enter") {
                                    onSearch(evt)
                                }
                            }}
                                   value={cropGrowerSearchName}
                                   onInput={(event) =>{
                                       const element = event.currentTarget as HTMLInputElement
                                       setCropGrowerSearchName(element?.value)}}
                            />
                            {Array.isArray(cropGrowerQuery) && cropGrowerQuery.length > 0 && cropGrowerQuery.map((data: any, index: any) =>
                                <li key={data.value} onClick={() => selectedChanges(data)}
                                    style={{"listStyleType": "none"}} className="list-item">
                                    <div className="form-check">
                                        <input className="form-check-input" name="checkBoxGrower" type="checkbox"
                                               id={index}
                                               checked={checkText(data.value)} disabled/>
                                        <label className="form-check-label" htmlFor="flexCheckCheckedDisabled">
                                            {data?.label}
                                        </label>
                                    </div>
                                </li>
                            )}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={(evt) => {
                                closeModal(id, setShow), setSelectedGrower(storeStateSelected), setValue(initialValue)
                            }}>Cancel
                            </button>
                            <button type="button" className="btn btn-primary" onClick={(evt) => {
                                onOkay(id, setShow)
                            }}>Save changes
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default CustomModalGrowerSelect;
