import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {v2Fetch, v2Fetch3001, v2Post, v3Fetch, v3FetchOctet, v3Post, v3Update} from "../../util/httpUtil";

const initialState: any = {
    payload:[],
    error: undefined,
    loading: false,
};

export const fetchFormSettingsByID = createAsyncThunk(
    'formSettings/fetch',
    (id:any, { rejectWithValue }) => {
        return v3Fetch(`cms/tdmcms/tdm/${id}`, {})
            .then((response:any) => {
                if (response.status === 200) {
                    return Promise.resolve(response?.data);
                } else {
                    // TODO
                }
            })
            .catch((error) => {
                const errorThrown = JSON.parse(error)
                return rejectWithValue(errorThrown?.message)

            });
    }
);

export const fetchFormImageByID = createAsyncThunk(
    'formSettingFetchImageByID/fetch',
    (id:any, { rejectWithValue }) => {
        return v3FetchOctet(`cms/tdmcms/tdm/${id}/image`, {})
            .then((response:any) => {
                if (response.status === 200) {
                    const imageData :any = {
                        fileName : response.request.getResponseHeader('content-disposition').split('filename=')[1].split(';')[0],
                        fileData: response?.data
                    }
                    return Promise.resolve(imageData);
                } else {
                    // TODO
                }
            })
            .catch((error) => {
                const errorThrown = JSON.parse(error)
                return rejectWithValue(errorThrown?.message)

            });
    }
);

export const formSettingImageSlice = createSlice({
    name: "formSettingFetchImageByID",
    initialState,
    reducers: {
        cleanFormSettingImageByID(state) {
            state.loading = false;
            state.payload = [];
            state.error = {};
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchFormImageByID.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(fetchFormImageByID.fulfilled, (state, action) => {
            state.loading = false;
            state.payload = action.payload;
        });
        builder.addCase(fetchFormImageByID.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
        });
    },
});


export const addFormSettings = createAsyncThunk(
    'addForm/post',
    (data:any, { rejectWithValue }) => {
        const formData:any = new FormData();
        formData.append(`data`,JSON.stringify(data?.data))
        formData.append(`img`,data?.img)

        if (data?.id){
            return v3Update(`cms/tdmcms/tdm/${data?.id}`, formData)
                .then((response:any) => {
                    if (response.status === 200) {
                        return Promise.resolve(response?.data);
                    } else {
                        // TODO
                    }
                })
                .catch((error) => {
                    const errorThrown = JSON.parse(error)
                    return rejectWithValue(errorThrown?.message)
                });

        }else{
            return v3Post("cms/tdmcms/tdm", formData)
                .then((response:any) => {
                    if (response.status === 200) {
                        return Promise.resolve(response?.data);
                    } else {
                        // TODO
                    }
                })
                .catch((error) => {
                    const errorThrown = JSON.parse(error)
                    // return rejectWithValue(errorThrown?.message)
                });
        }


    }
);


export const formSettingSlice = createSlice({
    name: "formSettings",
    initialState,
    reducers: {
        cleanFormSettingData(state) {
            state.loading = false;
            state.payload = [];
            state.error = {};
        },
    },
    extraReducers: (builder) => {
        builder.addCase(addFormSettings.pending, (state, action) => {
            state.loading = true;
            state.error={};
        });
        builder.addCase(addFormSettings.fulfilled, (state, action) => {
            state.loading = false;
            state.payload = action.payload;
            state.error={};
        });
        builder.addCase(addFormSettings.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        builder.addCase(fetchFormSettingsByID.pending, (state, action) => {
            state.loading = true;
            state.error={};
        });


        builder.addCase(fetchFormSettingsByID.fulfilled, (state, action) => {
            state.loading = false;
            state.payload = action.payload;
            state.error={};
        });
        builder.addCase(fetchFormSettingsByID.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
        });
    },
});




export const {cleanFormSettingData} = formSettingSlice.actions;
export const {cleanFormSettingImageByID} = formSettingImageSlice.actions;
// export const {cleanFormSettingIDData} = formSettingById.actions;
// export const {cleanTrialVarietiesData} = varietiesSlice.actions;
// export const { cleanCropListData } = cropListSlice.actions;
