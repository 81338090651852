import React, {Fragment, useEffect, useLayoutEffect, useState} from 'react';
import image1 from '../../assets/images/mock/Group58.png';
import image2 from '../../assets/images/mock/daniel-oberg-sEApBUS4fIk-unsplash 1.png';
import image3 from '../../assets/images/mock/maddi-bazzocco-UhrHTmVBzzE-unsplash 1.png';
import image4 from '../../assets/images/mock/markus-spiske-sFydXGrt5OA-unsplash 1.png';
import image5 from '../../assets/images/mock/balazs-ketyi-sScmok4Iq1o-unsplash 1.png';
import image6 from '../../assets/images/mock/www-zanda-photography-RBdE3jv5y68-unsplash 1.png';
import image7 from '../../assets/images/mock/luke-chesser-JKUTrJ4vK00-unsplash 1.png';

import {useLocation} from 'react-router';
import {loginRequest} from "../../authConfig";
import {ACCESS_KEY, CSS_COLOR_NAMES, USER_KEY} from "../../constants/index";
import {useMsal} from "@azure/msal-react";
import {toast} from "react-toastify";

// @ts-ignore
const Elearning = (props: any) => {
    const {
        fetchFormSettingsList,
        settingList, settingListErrors, settingListLoading, cleanFormSettingListData
    } = props;

    const {instance, accounts, logger, inProgress} = useMsal();
    const [tdmItemCollection, setTdmItemCollection] = useState([]) as any;

    const {pathname} = useLocation();


    if (pathname === "/moreInformation") {
        document.body.classList.add('moreInformation-page')
    }

    useEffect(() => {
            const request = {
                ...loginRequest,
                account: accounts[0]
            };

            instance.acquireTokenSilent(request).then((response: any) => {
                fetchFormSettingsList().then((responseFromSettingCollections: any) => {
                    if (responseFromSettingCollections?.payload) {
                        let responseFromApi = responseFromSettingCollections?.payload?.map((item: any) => {
                            return {
                                ...item,
                                seeMore: false
                            }
                        })
                        if (responseFromApi?.length > 0) {
                            setTdmItemCollection(responseFromApi)
                        }

                    }

                    if (responseFromSettingCollections?.error?.message) {
                        toast.error(`${responseFromSettingCollections?.payload}`, {});
                        if (responseFromSettingCollections.payload === 'Request failed with status code 401') {
                            localStorage.removeItem(USER_KEY)
                            localStorage.removeItem(ACCESS_KEY)
                            const request = {
                                ...loginRequest,
                                account: accounts[0]
                            };

                            let currentRequest = {
                                ...loginRequest,
                                account: accounts[0]
                            };

                            instance.acquireTokenRedirect(currentRequest).then((response) => {
                                fetchFormSettingsList()
                            })
                        }
                    }

                })
            });


            return () => {
                cleanFormSettingListData()
            };
        }
        , [])

    useLayoutEffect(() => {
        if (pathname === "/Learning") {
            // fetchFormSettingsList();

            document.body.classList.add('moreInformation-page')
        } else {
            document.body.classList.remove('moreInformation-page')
        }


    }, [pathname])

    const toggleMore = (id: any) => {
        let shallowCopyiTEM = [...tdmItemCollection]
        shallowCopyiTEM.forEach((item: any) => {
            if (item.id === id) {
                if (item.seeMore == false) {
                    item.seeMore = true
                } else {
                    item.seeMore = false
                }
            } else {
                item.seeMore = false
            }
        });
        setTdmItemCollection(shallowCopyiTEM)
    }

    return (
        <Fragment>
            <main className='container-sm container-md container-xxl moreInformation-container'>
                <Fragment>
                    {(settingListLoading) && (
                        <div className="body_loading" role="status">
                            <div className="spinner"></div>
                        </div>
                    )}
                    {/* <div className="row row-cols-2"> */}
                    {settingListLoading === false && settingList?.length > 0 && (
                        <Fragment>
                            {tdmItemCollection?.length > 0 && tdmItemCollection?.map((dataItem: any, index: any) => {
                                return (
                                    <Fragment key={index}>
                                        <div className="row row-cols-2 pb-3 d-flex d-flex justify-content-between"
                                             style={{marginBottom: dataItem?.id === "0" ? "40px" : ""}}>
                                            <div
                                                className={`card justify-content-center pt-5 pb-5 bg-transparent shadow-none ${dataItem?.imageAlignment?.toLowerCase() === "left" ? "order-2 ps-3" : "order-1 ps-5"}`}
                                                style={{width: "25 rem"}}>
                                                <div>
                                                    {dataItem?.id === "0" ? <span className="fs-2">Your TDM</span> : ""}
                                                    <h2 className={`dropdown-header p-0 fs-2  ${dataItem?.id === "0" ? "pb-4" : "pb-3"}`}>{dataItem?.header}</h2>
                                                </div>
                                                {dataItem?.subHeader && (
                                                    <h6 className={`dropdown-header p-0 fs-5 text-dark ${dataItem?.id === "0" ? "" : "pb-3"}`}>{dataItem?.subHeader}</h6>
                                                )}
                                                {dataItem?.paragraph && (
                                                    <div
                                                        className={`${dataItem?.seeMore ? "no-wrap" : "text-wrapper"}`}>
                                                        {dataItem?.paragraph?.split('\n')?.map((paragraphItem: any, paragraphIndex: number) => {
                                                            return <p>{paragraphItem}</p>
                                                        })
                                                        }
                                                    </div>

                                                )}

                                                {dataItem?.paragraph?.length > 145 ? <span className='see-more' onClick={(evt)=> toggleMore(dataItem?.id)}>{dataItem?.seeMore ? "See less" : "See more"}</span> : ""}

                                                {dataItem?.buttonName && (
                                                    <button onClick={() => {
                                                        if ((dataItem?.urlButton).includes("https")) {
                                                            window.location.href = `${dataItem?.urlButton}`
                                                        } else {
                                                            window.location.href = `https://${dataItem?.urlButton}`
                                                        }
                                                    }}
                                                            className={`btn btn-success_custom ${dataItem?.id === "0" ? "mt-4 mb-5" : "mt-5 mb-1"}`}>{dataItem?.buttonName}</button>
                                                )}
                                            </div>
                                            <div
                                                className={`col mb-2 pb-3 pt-3 ${dataItem?.seeMore ? "d-flex align-items-center " : ""} ${dataItem?.imageAlignment?.toLowerCase() === "left" ? "order-1 ps-5" : "order-2 pe-5 ps-2"}`}>
                                                <div className={'moreInformation-imgContainer'}>
                                                    <img src={dataItem?.imageUrl}
                                                         className="img-fluid moreInformation-image"
                                                         alt="no image" width={200} height={100}/>
                                                </div>
                                            </div>
                                        </div>
                                    </Fragment>
                                )
                            })}
                        </Fragment>
                    )}
                </Fragment>

            </main>


        </Fragment>
    )
}

export default Elearning;
