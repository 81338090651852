import {graphConfig, imageConfig} from "./authConfig";

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken
 */
export async function callMsGraph(accessToken:any) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return fetch(graphConfig.graphMeEndpoint, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}
/**
 * Attaches a given photo token to a MS Graph API call. Returns information about the user
 * @param accessToken
 */
export async function callMsGraphUserPhoto(accessToken:any) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);


    const optionsBlob = {
        method: "GET",
        headers: headers,
        responseType: 'blob'
    };

    return fetch(imageConfig.graphMeEndpoint, optionsBlob)
        .then(response => response)
        .catch(error => console.log(error));
}


export async function callMsGraphPhotoSharePoint(accessToken:any,url:any) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);

    const optionsBlob = {
        method: "GET",
        headers: headers,
    };

    return fetch(url, optionsBlob)
        .then(response => response)
        .catch(error => console.log(error));
}
