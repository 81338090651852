import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useLocation, useParams, Link, useHistory } from 'react-router-dom';
import { InboxOutlined } from '@ant-design/icons';
import {
  Form,
  Input,
  Radio,
  Row,
  Col,
  Upload,
  Breadcrumb,
  Button,
  Select,
  Alert,
  Space
} from 'antd';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
// @ts-ignore
import { getSecondPart, isEmpty } from '../../util/commonUtils';
import 'react-toastify/dist/ReactToastify.css';
import { useMsal } from '@azure/msal-react';
import { getLocalStorage } from '../../util/localStorage.util';
import { USER_KEY } from '../../constants';

const AdminAddComponent = (props: any) => {
  let match: any = useLocation();

  const checkIfAdd = () => {
    let defaultValue = false;
    const testParams = match?.pathname?.search('edit');
    if (testParams === -1) {
      defaultValue = true;
    } else {
      defaultValue = false;
    }
    return defaultValue;
  };

  const params: any = useParams();
  let history = useHistory();
  const {
    cleanFormSettingImageByID,
    cleanFormSettingData,
    formSettingsErrors,
    addFormSettings,
    fetchFormSettingsByID,
    formSettings,
    formSettingsLoading,
    fetchFormImageByID,
    formSettingImageLoading,
    formSettingImageErrors,
    formSettingImage
  } = props;
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [form] = Form.useForm();

  const { instance, accounts, logger, inProgress } = useMsal();

  const { Dragger } = Upload;
  const { Option } = Select;

  useEffect(() => {
    if (params?.id) {
      fetchFormSettingsByID(params?.id).then((res: any) => {
        if (res?.payload?.imageName) {
          fetchFormImageByID(params?.id).then((resFromID: any) => {
            function blobToFile(theBlob: any, fileName: any) {
              return new File([theBlob], fileName, {
                lastModified: new Date().getTime(),
                type: `image/${getSecondPart(`${fileName}`, '.')}`
              });
            }
            const imageFile: any = blobToFile(
              resFromID?.payload?.fileData,
              `${resFromID?.payload?.fileName}`
            );
            imageFile.url = `${res?.payload?.imageUrl}`;
            setFileList([imageFile]);
          });
        }
      });
    }
    return () => {
      cleanFormSettingImageByID();
      cleanFormSettingData();
    };
  }, [params.id]);

  const fileProps: UploadProps = {
    name: 'file',
    multiple: false,
    maxCount:1,
    accept: '.png, .jpg, .jpeg',
    onRemove: (file) => {
      setFileList([]);
    },
    beforeUpload: (file) => {
      console.log(file, 'file');
      const reader = new FileReader();
      const fileList: any = file;
      fileList.url = URL.createObjectURL(file);
      setFileList([fileList]);
      return false;
    },
    onDrop(file) {
      console.log(file, 'Dropped files', file.dataTransfer.files);
    },
    listType: 'picture-card',

    fileList
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 }
  };

  const onFinish = (values: any) => {
    if (fileList.length > 0) {
      const data = values;
      const img = fileList[0];
      const id = checkIfAdd() !== true ? formSettings?.id : '';
      addFormSettings({ data, img, id })
        .then((res: any) => {
          if (res?.error?.message.toLowerCase() === 'rejected') {
            console.log(res?.payload, ' payloaddddd');
          } else {
            cleanFormSettingImageByID();
            cleanFormSettingData();
            history.push('/Admin');
          }
        })
        .catch((error: any) => {
          console.log(error, 'error message 500');
        });
    }
  };

  return (
    <Fragment>
      <section
        className={`admin-ftform ${fileList.length === 0 ? 'image-upload' : 'admin-fileName '}`}>
        {(formSettingsLoading || (checkIfAdd() !== true && isEmpty(formSettings))) && (
          <div className="body_loading" role="status">
            <div className="spinner"></div>
          </div>
        )}
        {(!isEmpty(formSettings) || checkIfAdd() === true) && (
          <Fragment>
            <div className="container ps-0">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/Admin">{'Admin'}</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{checkIfAdd() ? 'New' : 'Edit'}</Breadcrumb.Item>
              </Breadcrumb>
            </div>

            <div className="container card">
              <Row>
                <Col span={16}>
                  <Fragment>
                    <h2 className="heading-section">{checkIfAdd() ? 'Add Form' : 'Edit Form'}</h2>
                    {!isEmpty(formSettingsErrors) && formSettingsLoading === false && (
                      <Space direction="vertical" style={{ width: '100%', marginBottom: '20px' }}>
                        <Alert message={`${formSettingsErrors}`} type="error" showIcon />
                      </Space>
                    )}
                  </Fragment>

                  <Form
                    {...layout}
                    layout={'vertical'}
                    form={form}
                    onFinish={onFinish}
                    initialValues={{
                      header: checkIfAdd() ? '' : formSettings?.header,
                      subHeader: checkIfAdd() ? '' : formSettings?.subHeader,
                      buttonName: checkIfAdd() ? '' : formSettings?.buttonName,
                      urlButton: checkIfAdd() ? '' : formSettings?.urlButton,
                      order: checkIfAdd() ? '' : formSettings?.order?.toString(),
                      imageAlignment: checkIfAdd() ? '' : formSettings?.imageAlignment,
                      paragraph: checkIfAdd() ? '' : formSettings?.paragraph
                    }}>
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label="Header"
                          name={'header'}
                          rules={[
                            { max: 24, message: 'Please input text lesser than 24 character!' }
                          ]}>
                          <Input placeholder="Header" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Sub Header"
                          name={'subHeader'}
                          rules={[
                            { max: 50, message: 'Please input text lesser than 24 character!' }
                          ]}>
                          <Input placeholder="Sub Header" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label="Button Name"
                          name={'buttonName'}
                          rules={[
                            { required: true, message: 'Please input button name!' },
                            { max: 24, message: 'Please input text lesser than 24 character!' }
                          ]}>
                          <Input placeholder="Button Name" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="URL"
                          name={'urlButton'}
                          rules={[{ required: true, message: 'Please input button URL!' }]}>
                          <Input placeholder="Button URL" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label="Order"
                          name={'order'}
                          rules={[
                            { required: true, message: 'Please input order!' },
                            { max: 24, message: 'Please input a number lesser than 24 digits!' },
                            {
                              message: 'please input number!',
                              validator: (_, value) => {
                                if (value.length > 0) {
                                  if (/^[0-9]+$/.test(value)) {
                                    return Promise.resolve();
                                  } else {
                                    return Promise.reject('please input number!');
                                  }
                                } else {
                                  return Promise.resolve();
                                }
                              }
                            }
                          ]}>
                          <Input placeholder="Order" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="ImageAlignment"
                          name={'imageAlignment'}
                          rules={[{ required: true, message: 'Please select Image alignment!' }]}>
                          <Select placeholder="Select Image Alignment" allowClear>
                            <Option value="left">Left</Option>
                            <Option value="right">Right</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label="Paragraph"
                          name={'paragraph'}
                          rules={[
                            { required: true, message: 'Please input paragraph!' },
                            { max: 1000, message: 'Please input text lesser than 1000 character!' }
                          ]}>
                          <Input.TextArea placeholder="Paragraph" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Button
                        htmlType="submit"
                        className="btn btn-success btn-success_custom me-2 d-flex align-items-center">
                        {'Submit'}
                      </Button>
                      <Button
                        onClick={() => history.push('/Admin')}
                        className="btn btn-danger me-2 d-flex align-items-center">
                        {'Cancel'}
                      </Button>
                    </Row>

                    {/*<Form.Item label="Order">*/}
                    {/*    <Input placeholder="input placeholder" />*/}
                    {/*</Form.Item>*/}
                  </Form>
                </Col>
                <Col span={8}>
                  <Dragger {...fileProps}>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p style={{ color: 'red' }}>Please Select the file</p>
                  </Dragger>
                </Col>
              </Row>
            </div>
          </Fragment>
        )}
        {/*)}*/}
      </section>
    </Fragment>
  );
};

export default AdminAddComponent;
