import React from 'react';
import SortableItem from './SortableItem';
import { SortableContainer } from 'react-sortable-hoc';

const SortableList = (props: any) => {
  // @ts-ignore
  return (
    <ul>
      {props?.items?.map((value: any, index: any) => {
        const valueName= value?.VarietyName ?  `[F.Nr: ${value?.FieldNumber}] ${value?.VarietyName}`: value?.traitName
        
        return (
          <SortableItem
            key={`item-${index}`}
            index={index}
            value={valueName}
            toggle={props?.toggle}
            metric={{ uoMCodeImperial: value?.uoMCodeImperial, uomCode: value?.uomCode }}
          />
        );
      })}
    </ul>
  );
};

export default SortableContainer(SortableList);
