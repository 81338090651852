import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {sharePointFetch, v2Fetch, v2Fetch3001, v2Post} from "../../util/httpUtil";
import config from "../../config/config";

const initialState: any = {
    payload:[],
    error: undefined,
    loading: false,
};
const shareENDURL= `${config.sharePointQuery}`;
export const fetchNews = createAsyncThunk(
    'news/get',
    (formData:any, { rejectWithValue }) => {
        return sharePointFetch(`${shareENDURL}`, {})
            .then(async (response: any) => {
                if (response.status === 200) {
                    return Promise.resolve(response?.data?.value);
                } else {
                    // TODO
                }
            })
            .catch((error) => rejectWithValue(error.response.data));
    }
);

export const newsSlice = createSlice({
    name: "news",
    initialState,
    reducers: {
        cleanTrialData(state) {
            state.loading = false;
            state.payload = [];
            state.error = {};
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchNews.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(fetchNews.fulfilled, (state, action) => {
            state.loading = false;
            state.payload = action.payload;
        });
        builder.addCase(fetchNews.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
        });
    },
});

export const fetchCropSubFromObservation = createAsyncThunk(
    'cropSubObserver/get',
    (formData:any, { rejectWithValue }) => {
        return v2Fetch(`/v1/getobservations?traitids=${formData.toString()}`, {})
            .then(async (response: any) => {
                if (response.status === 200) {
                    return Promise.resolve(response?.data?.map((cropData:any)=>{
                        return {
                            value: cropData.value,
                            label: cropData.value
                        };
                    }));
                } else {
                    // TODO
                }
            })
            .catch((error) => rejectWithValue(error.response.data));
    }
);
export const cropSubFromObserverSlice = createSlice({
    name: "cropSubObserver",
    initialState,
    reducers: {
        cleanTrialData(state) {
            state.loading = false;
            state.payload = [];
            state.error = {};
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCropSubFromObservation.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(fetchCropSubFromObservation.fulfilled, (state, action) => {
            state.loading = false;
            state.payload = action.payload;
        });
        builder.addCase(fetchCropSubFromObservation.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
        });
    },
});



export const fetchGrowerDataFromObservation = createAsyncThunk(
    '/v1/GrowerData/getobservations',
    (formData:any, { rejectWithValue }) => {
        return v2Fetch(`/v1/getobservations?traitids=${formData.toString()}`, {})
            .then((response:any) => {
                if (response.status === 200) {
                    return Promise.resolve(response?.data?.map((cropData:any)=>{
                        return {
                            value: cropData.value,
                            label: cropData.value,
                            propertyID: cropData?.propertyID,
                        };
                    }).sort((compareGrowerA:any, compareGrowerB:any) => compareGrowerA.label.localeCompare(compareGrowerB.label)));
                } else {
                    // TODO
                }
            })
            .catch((error) => rejectWithValue(error.response.data));
    }
);


export const growerDataFromObserverSlice = createSlice({
    name: "growerData",
    initialState,
    reducers: {
        cleanTrialData(state) {
            state.loading = false;
            state.payload = [];
            state.error = {};
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchGrowerDataFromObservation.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(fetchGrowerDataFromObservation.fulfilled, (state, action) => {
            state.loading = false;
            state.payload = action.payload;
        });
        builder.addCase(fetchGrowerDataFromObservation.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
        });
    },
});



export const fetchGrowerData = createAsyncThunk(
    'fetchGrowerData/fetch',
    (formData:any, { rejectWithValue }) => {
        return v2Fetch("v1/trials/properties", formData)
            .then((response:any) => {
                if (response.status === 200) {
                    return Promise.resolve(response?.data);
                } else {
                    // TODO
                }
            })
            .catch((error) => rejectWithValue(error.response.data));
    }
);

export const growerDataSlice = createSlice({
    name: "growerData",
    initialState,
    reducers: {
        cleanTrialData(state) {
            state.loading = false;
            state.payload = [];
            state.error = {};
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchGrowerData.pending, (state, action) => {
            state.loading = true;
        });


        builder.addCase(fetchGrowerData.fulfilled, (state, action) => {
            state.loading = false;
            state.payload = action.payload;
        });
        builder.addCase(fetchGrowerData.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
        });
    },
});



export const fetchTrialProperties = createAsyncThunk(
    'fetchTrial/get',
    ( formData:any,{ rejectWithValue }) => {
        return v2Fetch3001(`v1/trial/properties${formData}`,{})
            .then((response:any) => {
                if (response.status === 200) {
                    response.d +=true
                    return Promise.resolve(response?.data);
                } else {
                    // TODO
                }
            })
            .catch((error) => {
                const errorThrown = JSON.parse(error)
                return rejectWithValue(errorThrown?.message)
            });
    }
);

export const trialPropertiesSlice = createSlice({
    name: "trialProperties",
    initialState,
    reducers: {
        cleanTrialData(state) {
            state.loading = false;
            state.payload = [];
            state.error = {};
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchTrialProperties.pending, (state, action) => {
            state.loading = true;
            state.payload = [];
            state.error = [];
        });


        builder.addCase(fetchTrialProperties.fulfilled, (state, action) => {
            state.loading = false;
            state.payload = action.payload;
            state.error = [];
        });
        builder.addCase(fetchTrialProperties.rejected, (state, action) => {
            state.loading = false;
            state.payload = [];
            state.error = action.payload;
        });
    },
});




export const submitTrial = createAsyncThunk(
    'submitTrial/post',
    (formData:any, { rejectWithValue }) => {
        return v2Post("v1/trials/trialreport", formData)
            .then((response:any) => {
                if (response.status === 200) {
                    console.log(response,'response')
                    return Promise.resolve(response?.data);
                } else {
                    // TODO
                }
            })
            .catch((error) => {
                const errorThrown = JSON.parse(error)
                return rejectWithValue(errorThrown?.message)
            });
    }
);
export const trialSlice = createSlice({
    name: "trial",
    initialState,
    reducers: {
        cleanTrialData(state) {
            state.loading = false;
            state.payload = [];
            state.error = {};
        },
    },
    extraReducers: (builder) => {
        builder.addCase(submitTrial.pending, (state, action) => {
            state.loading = true;
        });


        builder.addCase(submitTrial.fulfilled, (state, action) => {
            state.loading = false;
            state.payload = action.payload;
        });
        builder.addCase(submitTrial.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
        });
    },
});

export const fetchDealer = createAsyncThunk(
    'fetchDealer/get',
    (formData:any, { rejectWithValue }) => {
        return v2Fetch("v1/dealers", formData)
            .then((response:any) => {
                if (response.status === 200) {
                    return Promise.resolve(response?.data);
                } else {
                    // TODO
                }
            })
            .catch((error) => rejectWithValue(error.response.data));
    }
);

export const dealerSlice = createSlice({
    name: "dealer",
    initialState,
    reducers: {
        cleanDealerData(state) {
            state.loading = false;
            state.payload = [];
            state.error = {};
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchDealer.pending, (state, action) => {
            state.loading = true;
        });


        builder.addCase(fetchDealer.fulfilled, (state, action) => {
            state.loading = false;
            state.payload = action.payload;
        });
        builder.addCase(fetchDealer.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
        });
    },
});


export const getCropData = createAsyncThunk(
    'cropSlice/fetch',
    (formData:any, { rejectWithValue }) => {
        return v2Fetch("v1/crops", formData)
            .then((response:any) => {
                if (response.status === 200) {
                    return Promise.resolve(response?.data);
                } else {
                    // TODO
                    console.log(response,'s')
                }
            })
            .catch((error) => {
                const errorThrown = JSON.parse(error)
                return rejectWithValue(errorThrown?.message)

            });
    }
);



export const cropSlice = createSlice({
    name: "cropSlice",
    initialState,
    reducers: {
        cleanLoginData(state) {
            state.loading = false;
            state.payload = [];
            state.error = {};
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCropData.pending, (state, action) => {
            state.loading = true;
            state.error = {};
            state.payload = [];
        });


        builder.addCase(getCropData.fulfilled, (state, action) => {
            state.loading = false;
            state.payload = action.payload;
        });
        builder.addCase(getCropData.rejected, (state, action) => {
            state.loading = false;
            state.payload = [];
            state.error = action.payload;
        });
    },
});




export const fetchAreas = createAsyncThunk(
    'fetchAreas/get',
    (formData:any, { rejectWithValue }) => {
        return v2Fetch("v1/areas", formData)
            .then((response:any) => {
                if (response.status === 200) {
                    return Promise.resolve(response?.data);
                } else {
                    // TODO
                }
            })
            .catch((error) => {
                const errorThrown = JSON.parse(error)
                return rejectWithValue(errorThrown?.message)

            });
    }
);

export const areasSlice = createSlice({
    name: "area",
    initialState,
    reducers: {
        cleanAreaData(state) {
            state.loading = false;
            state.payload = [];
            state.error = {};
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAreas.pending, (state, action) => {
            state.loading = true;
            state.error = {};
            state.payload = [];
        });


        builder.addCase(fetchAreas.fulfilled, (state, action) => {
            state.loading = false;
            state.payload = action.payload;
            state.error = {};
        });
        builder.addCase(fetchAreas.rejected, (state, action) => {
            state.loading = false;
            state.payload = [];
            state.error = action.payload;
        });
    },
});

export const fetchCountries = createAsyncThunk(
    'fetchCountries/get',
    (formData:any, { rejectWithValue }) => {
        return v2Fetch("v1/countries", formData)
            .then((response:any) => {
                if (response.status === 200) {
                    return Promise.resolve(response?.data);
                } else {
                    // TODO
                }
            })
            .catch((error) => {
                const errorThrown = JSON.parse(error)
                return rejectWithValue(errorThrown?.message)
            });
    }
);

export const countriesSlice = createSlice({
    name: "country",
    initialState,
    reducers: {
        cleanCountryData(state) {
            state.loading = false;
            state.payload = [];
            state.error = {};
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCountries.pending, (state, action) => {
            state.loading = true;
            state.error =[]
            state.payload = [];
        });


        builder.addCase(fetchCountries.fulfilled, (state, action) => {
            state.loading = false;
            state.error =[]
            state.payload = action.payload;
        });
        builder.addCase(fetchCountries.rejected, (state, action) => {
            state.loading = false;
            state.payload = [];
            state.error = action.payload;
        });
    },
});






export const fetchCropSubSegment = createAsyncThunk(
    'fetchCropSubSegment/get',
    (formData:any, { rejectWithValue }) => {
        return v2Fetch("v1/assortments", formData)
            .then((response:any) => {
                if (response.status === 200) {
                    return Promise.resolve(response?.data);
                } else {
                    // TODO
                }
            })
            .catch((error) => rejectWithValue(error.response.data));
    }
);



export const cropSubSegmentSlice = createSlice({
    name: "assortment",
    initialState,
    reducers: {
        cleanAssortmentData(state) {
            state.loading = false;
            state.payload = [];
            state.error = {};
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCropSubSegment.pending, (state, action) => {
            state.loading = true;
        });


        builder.addCase(fetchCropSubSegment.fulfilled, (state, action) => {
            state.loading = false;
            state.payload = action.payload;
        });
        builder.addCase(fetchCropSubSegment.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
        });
    },
});

export const { cleanLoginData } = cropSlice.actions;
export const { cleanTrialData } = trialSlice.actions;
export const { cleanCountryData } = countriesSlice.actions;
export const { cleanAreaData } = areasSlice.actions;
export const { cleanDealerData } = dealerSlice.actions;





