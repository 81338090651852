import React, { Fragment, useEffect, useState, useRef } from 'react';
import { CloseIcon, CheckIcon, InfoIcon } from '../assets/svgs';
import { loginRequest } from '../authConfig';
import { useMsal } from '@azure/msal-react';
import { ACCESS_KEY, USER_KEY } from '../constants';
import { isEmpty } from '../util/commonUtils';
import { usePrevious } from '../util/CustomHooks';

export const BulkTrialSelectionModal = (props: any) => {
  const { instance, accounts } = useMsal();
  const impactRef12: any = useRef();
  const {
    show,
    setShow,
    setSelectedTrialsMain,
    SetCropsState,
    SetCallApi,
    callApi,
    growerValue,
    label,
    className,
    traitListDataLoading,
    closeModal,
    id,
    header,
    cropListData,
    setValue,
    value,
    getTrialList,
    trialListData,
    trailListDataLoading,
    getTraitList,
    getVarieties,
    setReDraw,
    jRef,
    cleanTraitListData,
    selectedTrialsMain,
    selectedBulkCropsMain,
    varietyLoading,
    setOrder,
    setTraitStateSelectedMain,
    setSelectedTraits,
    setVarietiesOrder,
    setInitialValueOfOrder,
    setInitialValueOfOrder1,
    trialMenu,
    setOrderOfTrait,
    ifErrorinModal,
    currentStep,
    setCurrentStep,
    userTrialNameChecked,
    GetTrialName
  } = props;
  const [query, setQuery]: any = useState(cropListData);
  const [queryTrial, setQueryTrial]: any = useState(trialListData);
  const [cropSearchName, setCropSearchName] = useState('');
  const [selected, setSelected]: any = useState({});
  const [selectedTrail, setSelectedTrail]: any = useState({});
  let bulkTraitsOptionsFiltered: any[] = [];
  let bulkCropOptionsFiltered: any[] = [];
  const prevCrop: any = usePrevious(selectedBulkCropsMain);
  const prevTrail: any = usePrevious(selectedTrialsMain);

  useEffect(() => {
    setCurrentStep(1);
    setCropSearchName('');
    setQuery(cropListData);
    setQueryTrial(trialListData);
    setSelectedTrail(selectedTrialsMain);
    setSelected(selectedBulkCropsMain);
    setCurrentStep(trialMenu);
  }, [show == true]);

  const selectedChanges = (data: any, type: any) => {
    if (type === 'trail') {
      setSelectedTrail(data);
      setSelectedTrialsMain(data);
    } else {
      setValue(data);
      setSelected(data);
    }
  };

  const checkText = (items: any, type: any) => {
    if (type === 'trails') {
      const testValue = items?.EZID === selectedTrail?.EZID ? true : false;
      return testValue;
    } else {
      const testValue = items?.cropCode === selected?.cropCode ? true : false;
      return testValue;
    }
  };

  const onSearch = (event: any, type: string) => {
    event.preventDefault();
    if (type === 'crops') {
      const searchString = event.currentTarget.value.trim().toLowerCase();

      if (searchString.length > 0) {
        // We are searching. Filter the results.
        const optionsFiltered = cropListData.filter((e: any) =>
          e.cropName.toLowerCase().match(searchString)
        );
        if (optionsFiltered.length > 0) {
          bulkTraitsOptionsFiltered = optionsFiltered;
          setQuery(bulkTraitsOptionsFiltered);
        } else {
          // if not found were sending empty
          bulkTraitsOptionsFiltered = [];
          setQuery(bulkTraitsOptionsFiltered);
        }
      } else {
        // if not found search key were reseting it
        bulkTraitsOptionsFiltered = cropListData;
        setQuery(bulkTraitsOptionsFiltered);
      }
    } else {
      const searchString = event.currentTarget.value.trim().toLowerCase();
      if (searchString.length > 0) {
        // We are searching. Filter the results.
        const optionsFiltered = trialListData.filter(
          (e: any) =>
            e.TrialName.toLowerCase().match(searchString) ||
            (e?.UserTrialName && e.UserTrialName.toLowerCase().match(searchString))
        );
        if (optionsFiltered.length > 0) {
          bulkTraitsOptionsFiltered = optionsFiltered;
          setQueryTrial(bulkTraitsOptionsFiltered);
        } else {
          // if not found were sending empty
          bulkTraitsOptionsFiltered = [];
          setQueryTrial(bulkTraitsOptionsFiltered);
        }
      } else {
        // if not found search key were reseting it
        bulkTraitsOptionsFiltered = trialListData;
        setQueryTrial(bulkTraitsOptionsFiltered);
      }
    }
  };

  const handleNext = (currentPage: any, id: any, setShow: any) => {
    if (currentPage === 1) {
      const request = {
        ...loginRequest,
        account: accounts[0]
      };
      instance.acquireTokenSilent(request).then((response: any) => {
        let token = response.accessToken;
        localStorage.setItem(USER_KEY, token);
        localStorage.setItem(ACCESS_KEY, response.accessToken);
        getTrialList(selected).then((responseFromTrialList: any) => {
          if (responseFromTrialList?.error?.message) {
            if (responseFromTrialList.payload === 'Request failed with status code 401') {
              let currentRequest = {
                ...loginRequest,
                account: accounts[0]
              };

              instance.acquireTokenRedirect(currentRequest).then((response) => {});
            }
          } else {
            if (prevCrop?.cropCode !== undefined) {
              if (prevCrop?.cropCode !== selected?.cropCode) {
                if (jRef?.current?.jexcel) {
                  jRef.current.jexcel.destroy();
                }
                setOrderOfTrait([]);
                setVarietiesOrder([]);
                setInitialValueOfOrder1([]);
                setInitialValueOfOrder([]);
                cleanTraitListData();
                setSelectedTrail({});
                setSelectedTrialsMain({});
                setOrder([]);
                setTraitStateSelectedMain([]);
                setSelectedTraits([]);
              }
            }

            setCropSearchName('');

            bulkTraitsOptionsFiltered = trialListData;
            setQueryTrial(bulkTraitsOptionsFiltered);
            setQueryTrial(responseFromTrialList?.payload);
            setCurrentStep(currentPage + 1);
          }
        });
      });
    } else {
      const formData = { ezid: selectedTrail?.EZID, cropCode: selected?.cropCode };
      const request = {
        ...loginRequest,
        account: accounts[0]
      };
      instance.acquireTokenSilent(request).then((response: any) => {
        getVarieties(formData)
          .then((responseFromVarieties: any) => {
            if (responseFromVarieties?.error?.message) {
              if (responseFromVarieties.payload === 'Request failed with status code 401') {
                let currentRequest = {
                  ...loginRequest,
                  account: accounts[0]
                };

                instance.acquireTokenRedirect(currentRequest).then((response) => {});
              }
            } else if (
              responseFromVarieties?.payload?.Varieties?.length === 0 ||
              responseFromVarieties?.payload?.Varieties === undefined
            ) {
              ifErrorinModal('No Varieties in the Selected Trial');
            } else {
              const formDataForTrait: any = {
                DefaultTraitSetID: responseFromVarieties?.payload?.DefaultTraitSetID,
                cropCode: selected?.cropCode
              };
              if (prevTrail?.EZID) {
                if (prevTrail?.EZID !== selectedTrail?.EZID) {
                  if (jRef?.current?.jexcel) {
                    jRef.current.jexcel.destroy();
                  }
                  setOrderOfTrait([]);
                  setInitialValueOfOrder1([]);
                  setInitialValueOfOrder([]);
                  setVarietiesOrder([]);
                  setOrder([]);
                  setSelectedTraits([]);
                  setTraitStateSelectedMain([]);
                  cleanTraitListData();
                }
              }
              getTraitList(formDataForTrait).then((resFromGetTrial: any) => {
                if (
                  resFromGetTrial?.payload?.length === 0 ||
                  resFromGetTrial?.payload === undefined
                ) {
                  ifErrorinModal('No Traits in the Selected Trial');
                }
                setCropSearchName('');
                closeModal(id, setShow);
              });
              // setReDraw(true)
            }
          })
          .catch((err: any) => {
            closeModal(id, setShow);
          });
      });
    }
  };

  const handlePrevious = () => {
    setCropSearchName('');
    bulkTraitsOptionsFiltered = cropListData;
    setQuery(bulkTraitsOptionsFiltered);
    setCurrentStep(1);
  };

  return (
    <div className="modal overlay" id={id} ref={impactRef12}>
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          {trailListDataLoading || traitListDataLoading || varietyLoading ? (
            <div className="inner_body_loading" role="status">
              <div className="spinner"></div>
            </div>
          ) : (
            <Fragment>
              <div className="modal-header">
                <h5 className="modal-title">
                  {currentStep === 1
                    ? `Select Crop`
                    : currentStep === 2
                    ? `Select Trial`
                    : `Edit Trial`}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={(evt) => {
                    if (isEmpty(prevCrop)) {
                      setValue({});
                      setSelected({});
                    } else {
                      // setSelectedTrail(selectedTrialsMain)
                      setValue(prevCrop);
                      setSelected(prevCrop);
                    }
                    if (isEmpty(prevTrail)) {
                      setSelectedTrialsMain({});
                      setSelectedTrail({});
                    } else {
                      setSelectedTrialsMain(prevTrail);
                      setSelectedTrail(prevTrail);
                    }
                    // setSelected(prevCrop?.cropCode)
                    closeModal(id, setShow);
                  }}></button>
              </div>
              <div className="modal-body">
                <Fragment>
                  {currentStep !== 0 && (
                    <Fragment>
                      <div className="col-md-6 offset-md-3">
                        <div className="steps step-one">
                          <div className="step-item">
                            <button
                              className="step-button text-center"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseOne"
                              aria-expanded="true"
                              aria-controls="collapseOne">
                              {currentStep === 1 ? `1` : <CheckIcon />}
                            </button>
                            <div className="step-title">{`Select Crop`}</div>
                          </div>
                          <div className="step-item">
                            <button
                              className="step-button text-center collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo">
                              2
                            </button>
                            <div className="step-title">Select Trial</div>
                          </div>
                          {selected?.cropName && (
                            <div className="step-name step-name_one">{selected?.cropName}</div>
                          )}
                          {selectedTrail?.TrialName && (
                            <div className="step-name step-name_two">
                              {GetTrialName(selectedTrail)}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-8 offset-md-2 mt-2 mb-2">
                          <form className="nosubmit">
                            <input
                              name="search"
                              placeholder={
                                currentStep === 1 ? `Search Crops...` : `Search Trials...`
                              }
                              onKeyUp={(evt) => {
                                const typeOfSearch = currentStep === 1 ? 'crops' : `trails`;
                                if (evt?.code !== 'Enter') {
                                  onSearch(evt, typeOfSearch);
                                }
                              }}
                              value={cropSearchName}
                              onInput={(event) => {
                                const element = event.currentTarget as HTMLInputElement;
                                setCropSearchName(element.value);
                              }}
                            />
                          </form>
                        </div>
                      </div>
                    </Fragment>
                  )}
                  {currentStep === 0 && (
                    <Fragment>
                      <div className={currentStep === 0 ? 'steps step-two' : 'steps step-one'}>
                        <div className={currentStep === 0 ? 'step-item-edit' : ''}>
                          <div className="step-title title-second">{`Selected Crop`}</div>
                          {selected?.cropName && (
                            <input
                              type="text"
                              className="step-name step-name-edit_one"
                              value={selected?.cropName}
                              disabled
                            />
                          )}
                        </div>
                        <div className="step-item">
                          <div className="step-title">{`Selected Trial`}</div>
                          {selectedTrail?.TrialName && (
                            <input
                              type="text"
                              className="step-name step-name-edit_two"
                              value={GetTrialName(selectedTrail)}
                              disabled
                            />
                          )}
                        </div>
                      </div>
                    </Fragment>
                  )}
                </Fragment>

                {currentStep === 1 ? (
                  <Fragment>
                    <div className="row">
                      <Fragment>
                        {Array.isArray(query) &&
                          query.length > 0 &&
                          query?.map((data: any, index: any) => {
                            return (
                              <Fragment key={index}>
                                <div key={data.cropCode} className="col-md-4 crop-list-item">
                                  <div
                                    className="form-check"
                                    onClick={() => selectedChanges(data, 'crop')}
                                    style={
                                      checkText(data, 'crops')
                                        ? {
                                            listStyleType: 'none',
                                            backgroundColor: '#6AA742',
                                            color: '#ffffff'
                                          }
                                        : { listStyleType: 'none' }
                                    }>
                                    <label
                                      className="form-check-label"
                                      htmlFor="flexCheckCheckedDisabled">
                                      {data?.cropName}
                                    </label>
                                  </div>
                                </div>
                              </Fragment>
                            );
                          })}
                      </Fragment>
                    </div>
                    {/* <div className="modal-footer">
                                        <button type="button" className="btn btn-primary"
                                                onClick={(e) => handleNext(1)}
                                                disabled={isEmpty(selected) ? true : false}>Next
                                        </button>
                                    </div> */}
                  </Fragment>
                ) : currentStep === 2 ? (
                  <Fragment>
                    <div className="row">
                      {Array.isArray(queryTrial) && queryTrial.length > 0 ? (
                        queryTrial?.map((data: any, indexQuery: any) => {
                          return (
                            <Fragment key={indexQuery}>
                              <div key={data.EZID} className="col-md-4 crop-list-item">
                                <div
                                  className="form-check"
                                  onClick={() => selectedChanges(data, 'trail')}
                                  style={
                                    checkText(data, 'trails')
                                      ? {
                                          listStyleType: 'none',
                                          backgroundColor: '#6AA742',
                                          color: '#ffffff'
                                        }
                                      : { listStyleType: 'none' }
                                  }>
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexCheckCheckedDisabled">
                                    {GetTrialName(data)}
                                  </label>
                                  {userTrialNameChecked && data?.UserTrialName && (
                                    <span
                                      className="p-2"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title={data?.TrialName}>
                                      <InfoIcon color={checkText(data, 'trails') ? '#ffff' : ''} />
                                    </span>
                                  )}
                                </div>
                              </div>
                            </Fragment>
                          );
                        })
                      ) : (
                        <div className="form-check">{'No Data'}</div>
                      )}
                    </div>
                  </Fragment>
                ) : (
                  <Fragment>
                    <div className="edit-btns">
                      <button
                        onClick={(e) => setCurrentStep(1)}
                        className="btn btn-edit-trial btn-edit-trial_one">
                        Change Crop
                      </button>
                      <button
                        onClick={(e) => setCurrentStep(2)}
                        className="btn btn-edit-trial btn-edit-trial_two">
                        Change Trial
                      </button>
                    </div>
                  </Fragment>
                )}
              </div>
              <div className="modal-footer">
                {currentStep === 2 && (
                  <button
                    type="button"
                    className="btn btn-success btn-back btn-success_custom btn-outline"
                    onClick={(e) => handlePrevious()}>
                    Back
                  </button>
                )}
                {currentStep !== 0 && (
                  <button
                    type="button"
                    className="btn btn-success btn-success_custom float-end"
                    onClick={(e) => handleNext(currentStep, id, setShow)}
                    disabled={
                      isEmpty(selected) && currentStep !== 2
                        ? true
                        : isEmpty(selectedTrail) && currentStep !== 1
                        ? true
                        : false
                    }>
                    {currentStep === 1 ? `Next` : `Submit`}
                  </button>
                )}
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default BulkTrialSelectionModal;
