import React, {Fragment} from "react";
import {
    AreaIcon,
    CountryIcon,
    CropIcon,
    CropSegementIcon,
    CropSubSegementIcon,
     GrowerIcon2
} from "../assets/svgs";


export const MenuSelection =(props: any) => {
    const {showModal,setCropShow,cropsState,setCropSegmentShow,cropsSegmentSate,setCropSubSegmentShow,cropSubSegmentData,setCountryShow
        ,countryValue,cropArea,setAreaShow,setShow,growerValue} = props
    return (
        <Fragment>
            <div className="row">
                <div className="p-2 col-sm-6 col-md-6 col-xl-2">
                    <div className="w-auto card widget-flat" onClick={() => showModal('CropModal', setCropShow)}>
                        <div className="card-body">
                            <div className='row align-items-center'>
                                <div className='col-9 pe-1'>
                                    <h5 className='fw-normal mt-0 text-truncate text-uppercase'>Crop
                                        {cropsState.length > 0 && (
                                            <span className='numb-count'>{cropsState.length}</span>
                                        )}
                                    </h5>
                                    <div className='text-wrapper'
                                    >
                                        <Fragment>
                                            {cropsState.length > 0 ? (
                                                <Fragment>
                                                    <label className='text-truncate'>
                                                        {cropsState.map((cropsStateData: any, cropsSegmentSateIndex: any) => {
                                                                return (
                                                                    <Fragment key={cropsSegmentSateIndex}>
                                                                        {cropsSegmentSateIndex > 0 && ', '}
                                                                        {cropsStateData?.cropCode}
                                                                    </Fragment>
                                                                )
                                                            }
                                                        )}
                                                    </label>
                                                </Fragment>
                                            ) : (
                                                <div className='text-wrapper' style={{cursor: "pointer"}}>
                                                    <label style={{cursor: "pointer"}}>
                                                        No Selection
                                                    </label>
                                                </div>
                                            )}
                                        </Fragment>
                                    </div>
                                </div>
                                <div className="col-3 ps-0">
                                    <CropIcon/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-2 col-sm-6 col-md-6 col-xl-2">
                    <div className="w-auto card widget-flat"
                         onClick={() => showModal('CropSegmentModal', setCropSegmentShow)}>

                        <div className="card-body">
                            <div className='row align-items-center'>
                                <div className='col-9 pe-1'>
                                    <h5 className='fw-normal mt-0 text-truncate text-uppercase'>Crop
                                        Segment {cropsSegmentSate.length > 0 && (
                                            <span className='numb-count'>{cropsSegmentSate.length}</span>)}</h5>

                                    <div className='text-wrapper'
                                    >
                                        <Fragment>
                                            {cropsSegmentSate.length > 0 ? (
                                                <label className='text-truncate'>
                                                    {cropsSegmentSate.map((cropsSegmentSateData: any, cropsSegmentSateIndex: any) => {
                                                            return (
                                                                <Fragment key={cropsSegmentSateIndex}>
                                                                    {cropsSegmentSateIndex > 0 && ', '}
                                                                    {cropsSegmentSateData?.value}
                                                                </Fragment>
                                                            )
                                                        }
                                                    )}
                                                </label>

                                            ) : (
                                                <div className='text-wrapper' style={{cursor: "pointer"}}>
                                                    <label style={{cursor: "pointer"}}>
                                                        No Selection
                                                    </label>
                                                </div>
                                            )}
                                        </Fragment>
                                    </div>

                                </div>
                                <div className="col-3 ps-0">
                                    <CropSegementIcon/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-2 col-sm-6 col-md-6 col-xl-2">
                    <div className="w-auto card widget-flat"
                         onClick={() => showModal('CropSubSegmentModal', setCropSubSegmentShow)}>
                        <div className="card-body">
                            <div className='row align-items-center'>
                                <div className='col-9 pe-1'>
                                    <h5 className='fw-normal mt-0 text-truncate text-uppercase'>Crop Sub Segment
                                        {cropSubSegmentData.length > 0 && (
                                            <span className='numb-count'>{cropSubSegmentData.length}</span>)}
                                    </h5>
                                    <div className='text-wrapper'
                                    >
                                        <Fragment>
                                            {cropSubSegmentData.length > 0 ? (
                                                <Fragment>
                                                    <label className='text-truncate'>
                                                        {cropSubSegmentData.map((cropSubSegmentData1: any, cropsSegmentSateIndex: any) => {
                                                                return (
                                                                    cropSubSegmentData1?.value
                                                                )
                                                            }
                                                        ).join(', ')}
                                                    </label>
                                                </Fragment>
                                            ) : (
                                                <div className='text-wrapper' style={{cursor: "pointer"}}>
                                                    <label style={{cursor: "pointer"}}>
                                                        No Selection
                                                    </label>
                                                </div>
                                            )}
                                        </Fragment>
                                    </div>
                                </div>
                                <div className="col-3 ps-0">
                                    <CropSubSegementIcon/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-2 col-sm-6 col-md-6 col-xl-2">
                    <div className="w-auto card widget-flat"
                         onClick={() => showModal('countryModal', setCountryShow)}>
                        <div className="card-body">
                            <div className='row align-items-center'>
                                <div className='col-9 pe-1'>
                                    <h5 className='fw-normal mt-0 text-truncate text-uppercase'>Country {countryValue.length > 0 && (
                                        <span className='numb-count'>{countryValue.length}</span>)}</h5>
                                    <div className='text-wrapper'
                                    >
                                        <Fragment>
                                            {countryValue.length > 0 ? (
                                                <Fragment>
                                                    <label className='text-truncate'>
                                                        {countryValue.map((countriesData: any, countryIndex: any) => {
                                                                return (
                                                                    countriesData?.value.toUpperCase()
                                                                )
                                                            }
                                                        ).join(', ')}
                                                    </label>

                                                </Fragment>
                                            ) : (
                                                <div className='text-wrapper'>
                                                    <label style={{cursor: "pointer"}}>
                                                        No Selection
                                                    </label>
                                                </div>
                                            )}
                                        </Fragment>
                                    </div>
                                </div>
                                <div className="col-3 ps-0">
                                    <CountryIcon/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-2 col-sm-6 col-md-6 col-xl-2">
                    <div className="w-auto card widget-flat" onClick={() => showModal('areaModal', setAreaShow)}>
                        <div className="card-body">
                            <div className='row align-items-center'>
                                <div className='col-9 pe-1'>
                                    <h5 className='fw-normal mt-0 text-truncate text-uppercase'>Trial Area {cropArea.length > 0 && (
                                        <span className='numb-count'>{cropArea.length}</span>)}</h5>
                                    <div className='text-wrapper'
                                    >
                                        <Fragment>
                                            {cropArea.length > 0 ? (
                                                <Fragment>
                                                    <label className='text-truncate'>
                                                        {cropArea.map((cropAreaData: any, countryIndex: any) => {
                                                                return (
                                                                    cropAreaData?.displayName
                                                                )
                                                            }
                                                        ).join(', ')}
                                                    </label>
                                                </Fragment>
                                            ) : (
                                                <div className='text-wrapper' style={{cursor: "pointer"}}>
                                                    <label style={{cursor: "pointer"}}>
                                                        No Selection
                                                    </label>
                                                </div>
                                            )}
                                        </Fragment>
                                    </div>
                                </div>
                                <div className="col-3 ps-0">
                                    <AreaIcon/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-2 col-sm-6 col-md-6 col-xl-2">
                    <div className="w-auto card widget-flat" onClick={() => showModal('growerModal', setShow)}>
                        <div className="card-body">
                            <div className='row align-items-center'>
                                <div className='col-9 pe-1'>
                                    <h5 className='fw-normal mt-0 text-truncate text-uppercase'>Grower {growerValue.length > 0 && (
                                        <span className='numb-count'>{growerValue.length}</span>)}</h5>
                                    <div className='text-wrapper'>
                                        <Fragment>
                                            {growerValue.length > 0 ? (
                                                <Fragment>
                                                    <label className='text-truncate'>
                                                        {growerValue.map((growerValueData: any, growerIndex: any) => {
                                                                return (
                                                                    growerValueData?.value
                                                                )
                                                            }
                                                        ).join(', ')}
                                                    </label>
                                                </Fragment>
                                            ) : (
                                                <div className='text-wrapper' style={{cursor: "pointer"}}>
                                                    <label style={{cursor: "pointer"}}>
                                                        No Selection
                                                    </label>
                                                </div>
                                            )}
                                        </Fragment>
                                    </div>

                                </div>
                                <div className="col-3 ps-0">
                                    <GrowerIcon2/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default MenuSelection;
