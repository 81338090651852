import React, {Fragment, useState,useEffect,Suspense} from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { PageLayout } from "../components/PageLayout";
import { Route, Switch } from "react-router-dom";
import MainLayout from "../components/mainLayout";
import "../styles/App.css";
import {AccountInfo, InteractionStatus, IPublicClientApplication, Logger} from "@azure/msal-browser";
import { RouteProps,Redirect } from "react-router-dom";
import {USER_KEY} from "../constants";

const MainContent = () => {
    return (
        <Fragment >
            <AuthenticatedTemplate>
                <PageLayout>
                    <Route component={MainLayout} />
                </PageLayout>
                {/*<MainLayout/>*/}
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <h5 className="card-title">Please sign-in to see your profile information.</h5>
            </UnauthenticatedTemplate>
        </Fragment>
    );
};

const Routes=()=> {
    const { instance,inProgress,accounts,logger } = useMsal();
    const [checkUserReLogin, setCheckUserReLogin]: any = useState(false);
    useEffect(() => {
        if ((inProgress === InteractionStatus.None)) {
            if(accounts.length === 0 ) {

                instance.loginRedirect(loginRequest).then((res)=>{

                }).catch(e => {
                    console.log(e,'testing');
                })
                setCheckUserReLogin(false)
            }else{
                setCheckUserReLogin(true)
            }


        }
    }, [instance, accounts, inProgress]);
    return (
        <Suspense fallback={<div className="loader-container">Error! Please refresh the page</div>}>
            <Fragment>
                {checkUserReLogin &&(
                    <div className="App-main">

                        <MainContent />
                        {/*</PageLayout>*/}
                    </div>

                )}
            </Fragment>
        </Suspense>
    );
}


export default Routes;
