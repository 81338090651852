import React, {Fragment} from "react";
import config from "../config/config";
import {ArrowRightIcon, DateIcon} from "../assets/svgs";
import moment from "moment";
import {graphloginRequest, loginRequest} from "../authConfig";
import {useMsal} from "@azure/msal-react";
import {ACCESS_KEY, USER_KEY} from "../constants";
import {callMsGraphPhotoSharePoint} from "../graph";
import {getLocalStorage} from "../util/localStorage.util";
import { AuthenticationResult } from "@azure/msal-browser";

export const NewsFeed = (props: any) => {
    const {news} = props


    const {instance, accounts, logger, inProgress} = useMsal();
    const getImage = async (url: any, indexOfNews: any) => {
        {/*/sites/3eae7ea9-3220-4553-85ab-01e5c66c4023/lists/9ab637aa-f170-454c-8e0b-8c67a87a50b0/items/${data?.id}/driveItem/thumbnails/0/small/content*/
        }
        let urlFromPipeLine = url?.trim()?.replace('{id}', `${indexOfNews}`);

        const request = {
            ...graphloginRequest,
            account: accounts[0]
        };
        const graphTokenResponse:AuthenticationResult = await instance.acquireTokenSilent(request);
        
        // instance.acquireTokenSilent(request).then((response:any) => {
        //let token = `${getLocalStorage(ACCESS_KEY)}`;
        
        callMsGraphPhotoSharePoint(graphTokenResponse.accessToken, urlFromPipeLine).then(async (response: any) => {
                // setGraphData(response));
                // return await response
                if (response?.status === 200) {
                    const imageFromUrl = await response?.json()
                    const imagesInNews: any = document.getElementById(`${indexOfNews}`);
                    imagesInNews.src = `${imageFromUrl?.url}`
                }


            }
        )

    }


    return (
        <Fragment>
            <div className="p-2 col-xl-3">
                <div className="w-auto card h-100 widget-news">
                    <div className="card-body">
                        <div>
                            <div className="row align-items-center">
                                <div className="col-6">
                                    <h4 className='header-title'>News Feed</h4>
                                </div>
                                <div className="col-6">
                                    <a href={`${config.sharePointTDMUrl}`} target="_blank">
                                        <button className="btn btn-primary btn-sm float-end">
                                            See All <ArrowRightIcon/>
                                        </button>
                                    </a>
                                </div>
                            </div>
                            <div className="mt-4">
                                <div id="outerCard">
                                    {news.length > 0 && news.map((data: any, indexOfNews: any) =>
                                        <Fragment key={indexOfNews}>
                                            <a className="d-inline-flex align-items-top mb-3"
                                               href={`${data?.webUrl}`} target="_blank">
                                                <div className="card-image float-start me-3">
                                                    <img
                                                        src={`${getImage(`${config?.sharePointBaseUrl}${config.sharePointImageUrl}`, data?.id)}`}
                                                        id={data?.id}
                                                        className=""
                                                        alt="..."/>
                                                </div>
                                                <div className="ms-n2">
                                                    <h5 className="card-title mb-1">{data?.fields?.Title ? data?.fields?.Title : 'No Title'}</h5>

                                                    <div className='d-flex d-flex'>
                                                        <div className='meta-date me-3'>
                                                            <DateIcon/>
                                                            <span
                                                                className="card-text">{moment(data.lastModifiedDateTime).format('D MMMM')}</span>
                                                        </div>
                                                        <span
                                                            className="meta-author">{` By ${data?.createdBy?.user?.displayName}`}</span>
                                                    </div>
                                                </div>

                                            </a>
                                        </Fragment>
                                    ).splice(0, 3)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default NewsFeed;
