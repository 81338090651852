const config = {
    AdminRole: '#{AdminRole}',
    sharePointTDMUrl: '#{sharePointTDMUrl}',
    sharePointQuery: '#{sharePointQuery}',
    sharePointBaseUrl: '#{sharePointBaseUrl}',
    sharePointImageUrl: '#{sharePointImageUrl}',
    globalTraitServiceUrl: '#{globalTraitServiceUrl}',
    planningServiceURL: '#{planningServiceURL}',
    AZURE_API_KEY: '#{AZURE_API_KEY}',
    desktopProtocol: '#{trialappdesktop}',
    androidPlayStore: '#{androidPlayStore}',
    androidProtocol: '#{androidProtocol}',
    iosAppProtocol: '#{iosAppProtocol}',
    iosPlayStore: '#{iosPlayStore}',
    REDIRECT_URL: '#{REDIRECT_URL}',
    trialPrepUrl: '#{trialPrepUrl}',
    helpDeskUrl: '#{helpDeskUrl}',
    globalTraitCropUrl: '#{globalTraitCropUrl}',
    trialPrepTrialUrl: '#{trialPrepTrialUrl}',
    powerBiUrl: '#{powerBiUrl}',
    LearningUrl: '#{learningUrl}',
    ApplicationUrl: '#{applicationUrl}',
    globalTraitUrl: '#{globalTraitUrl}',
    authority: '#{authority}',
    BulkInsertUrlRoute: '#{BulkInsertUrlRoute}',
    DYNAMIC_PAGE_BASE_URL: '#{DYNAMIC_PAGE_BASE_URL}',
    BaseName: process.env.PUBLIC_URL === '' ? '/' : process.env.PUBLIC_URL,
    trialAppUrl: '#{trialAppUrl}'
  };

  export default config;

// const config = {
//     AdminRole: 'Admin',
//     sharePointTDMUrl: 'asdf',
//     sharePointQuery:
//       'sites/3eae7ea9-3220-4553-85ab-01e5c66c4023/lists/9ab637aa-f170-454c-8e0b-8c67a87a50b0/items?expand=fields(select=Title,Description,Author,BannerImageUrl,Created) &top=4&orderby=fields/Created desc',
//     sharePointBaseUrl: 'https://graph.microsoft.com/v1.0',
//     sharePointImageUrl:
//       'sites/3eae7ea9-3220-4553-85ab-01e5c66c4023/lists/9ab637aa-f170-454c-8e0b-8c67a87a50b0/items/{id}/driveItem/thumbnails/0/small/content',
//     globalTraitServiceUrl: 'https://tdm.javra.com/globaltrait/',
//     planningServiceURL: 'https://localhost:58517/',
//     AZURE_API_KEY: '3027aeaf-a348-4ef3-bc76-828511b00122',
//     desktopProtocol: '',
//     androidPlayStore: '',
//     androidProtocol: '',
//     iosAppProtocol: '',
//     iosPlayStore: '',
//     REDIRECT_URL: 'http://localhost:3000',
//     trialPrepUrl: 'https://tdm.javra.com/',
//     helpDeskUrl: '',
//     globalTraitCropUrl: '',
//     trialPrepTrialUrl: '',
//     powerBiUrl: '',
//     LearningUrl: '',
//     ApplicationUrl: '',
//     globalTraitUrl: '',
//     authority: 'https://login.microsoftonline.com/872e34c0-01f1-4641-9cf1-e623ef3c49b0',
//     BulkInsertUrlRoute: 'bulkinsert',
//     DYNAMIC_PAGE_BASE_URL: 'https://func-ci-tdmcms-test-001.azurewebsites.net/api',
//     BaseName: '',
//     trialAppUrl: '/trialapp'
//   };
  
  